<template>

    <div class="firstTimeLoginStep">
        <h1>{{ $t("login.hello") }} {{ currentUser.fullName }}!</h1>
        <h3>{{ $t("login.intro1") }}.</h3>
        <p>{{ $t("login.intro2") }}.</p>
        <b-btn @click="nextStep" variant="outline-primary">Start</b-btn>
    </div>

</template>

<script>
export default {
  name: 'FirstTimeLoginStep1',
  data () {
    return {}
  },
  methods: {
    nextStep () {
      this.$emit('stepper', 1)
    }
  }
}
</script>
