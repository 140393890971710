<template>
    <div class="v-wrapper">
<!--        <div class="debug-info">-->
<!--            DEBUG: {{ DataTableID }} - TILES-->
<!--        </div>-->
        <div v-if="standardText" v-html="standardText.content"></div>
      <b-form-checkbox v-model="preferOwnSubscriptionsYN" name="check-button" class="mb-3" switch>
        {{ $t('common.assigned_to_me') }}
      </b-form-checkbox>
        <b-card-group deck v-if="!loading">

            <b-row no-gutters class="w-100">
              <b-col cols="12" v-if="items.length < 1">
                <b-card bg-variant="dark" text-variant="white" title="Geen resultaten" class="mb-4">
                  <b-card-text>
                    Geen inschrijvingen gevonden
                  </b-card-text>
                </b-card>
              </b-col>
                <b-col cols="12" xl="6" v-for="(license, index) in items" :key="index">
                    <b-card border-variant="primary" class="mb-4 license-card">
                        <b-row no-gutters class="mb-3">
                            <b-col cols="5" md="4" class="p-3 mx-auto">
                                <b-avatar size="7em" variant="light">
                                    <b-row>
                                        <b-col sm="12">
                                            <font-awesome-icon :icon="['fas', 'id-badge']" class="text-dark fa-2x display-block mb-1 license-icon" />
                                        </b-col>
                                        <b-col sm="12">
                                            <small class="text-muted">#{{ license.ProjectID }}</small>
                                        </b-col>
                                    </b-row>
                                </b-avatar>
                                <b-row>
                                    <b-col>
                                        <div class="avatar--badge mt-2">
                                            <h5><b-badge variant="secondary" class="mx-auto">{{ license.Name2 }}</b-badge></h5>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="8">
                                <b-card-title class="pt-3">{{ license.Activity.Name }} </b-card-title>
                                <b-card-text>{{ license.StartDate }} {{ $t("common.till") }} {{ license.EndDate }}</b-card-text>
                                <b-row no-gutters>
                                    <b-col cols="4" v-if="license.Activity.HasPersonYN"><h1 class="mb-0">{{ license.StudentCount }}</h1><small>{{ $tc("menu.student", license.StudentCount) }}</small></b-col>
                                    <b-col cols="4"><h1 class="mb-0">{{ license.FreeLicenseCount }}</h1><small>{{ $tc("registration.free_licence", license.FreeLicenseCount) }}</small></b-col>
                                    <b-col cols="4" v-if="license.Activity.HasActivityGroupYN"><h1 class="mb-0">{{ license.CompanyCount }}</h1><small>{{ $tc("common.company", license.CompanyCount) }}</small></b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <div class="card--label text-uppercase" v-if="license.Customer">{{ license.Customer.Name }}</div>
<!--                        <div class="text-white text-center text-uppercase bg-tertiary" v-if="license.Employee.EmployeeID != null"><font-awesome-icon :icon="['fas', 'chalkboard-teacher']" class="mr-2" />{{ license.Employee.Name }} {{ license.Employee.Name2 }}</div>-->
                        <router-link :to="{ name: DataTableID, params: {ID: license[keyFieldID]}}" class="stretched-link"></router-link>
                    </b-card>
                </b-col>
            </b-row>
        </b-card-group>

        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" size="md" class="float-right"></b-pagination>

        <div v-if="error">
            <b-jumbotron bg-variant="info" text-variant="white" border-variant="dark">
                <template v-slot:header>{{ $t("error.oops") }}</template>

                <template v-slot:lead>
                    {{ $t("error.datatable_retrieve_error") }}.
                </template>

            </b-jumbotron>
        </div>

        <b-container>
            <b-row>
                <b-col class="text-danger text-center my-3" v-if="noResults">
                    {{ $t("common.no_result") }}
                </b-col>
            </b-row>
        </b-container>

        <div class="text-center" v-if="loading">
            <b-spinner variant="primary" label="Large Spinner" type="grow"></b-spinner>
        </div>

    </div>
</template>

<script>

export default {
  name: 'DataTable',
  props: ['DataTableID'],
  data () {
    return {
      loading: true,
      items: [],
      error: false,
      noResults: false,
      keyFieldID: null,
      standardText: null,
      currentPage: 1,
      perPage: 10,
      preferOwnSubscriptionsYN: false,
      totalRows: 0
    }
  },
  computed: {
    rows () {
      return this.items.length
    }
  },
  methods: {
    getData (ctx = {}) {
      // const dataTableID = document.querySelector('#vue-wrapper').dataset.datatableid
      const data = {
        DataTableID: this.DataTableID,
        currentPage: this.currentPage,
        perPage: this.perPage,
        preferOwnActivityGroupsYN: this.preferOwnActivityGroupsYN
      }

      return this.$http.post('/php/xhr/ajax.php', data, { params: { function: 'getDataTable' } })
    },
    myProvider (ctx) {
      const promise = this.getData(ctx)
      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          this.keyFieldID = response.data.keyFieldID
          this.loading = false
          return response.data.items || []
        }
      })
    },
    loadData () {
      this.$root.$emit('bv::refresh::table', 'vue-data-table')
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          this.items = response.data.items || []
          this.keyFieldID = response.data.keyFieldID
          this.standardText = response.data.standardText
          this.totalRows = response.data.construct.total
          this.loading = false
        }
      })
    }
  },
  watch: {
    currentPage: function () {
      this.loadData()
    },
    preferOwnSubscriptionsYN: function () {
      this.loadData()
    }
  },
  beforeMount () {
    this.loadData()
  },
  created () {
    this.$nextTick(() => {
      this.$eventHub.$on('reloadData', this.loadData
      )
    })
  },
  beforeDestroy () {
    this.$eventHub.$off('reloadData')
  }
}
</script>
