<template>
  <div>
    <b-modal
      id="import-persons-modal"
      ref="modal"
      size="lg"
      :title=modalData.StandardText.Title
      v-model="showModal"
      @show="initModal"
      @hidden="resetModal"
      @ok="handleSubmit"
      @close="emitClose"
      @cancel="emitClose"
      :ok-title="$t('common.save')"
      :cancel-title="$t('common.cancel')"
      :ok-disabled="processing || loading"
    >
      <div v-html="modalData.StandardText.Content"></div>

      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <hr>
        <a :href="modalData.StandardText.UserDefinedField01" target="_blank"
           class="btn btn-secondary">{{ $t('person.download_import_person') }}</a>
        <b-form-text id="import-help-block">
          {{ $t('person.import_help_text') }}
        </b-form-text>
        <hr>
        <b-form-group
          id="input-group-1"
          :label="$t('person.add_to_school')"
          label-for="input-1"
          :state="this.form.CustomerRecord.value !== null"
        >

          <b-dropdown
            size="sm"
            :variant="this.form.CustomerRecord.value !== null ? 'outline-secondary' : 'outline-danger'"
            block
            menu-class="w-100"
            ref="dropdown"
          >
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'school']"/>
              {{ form.CustomerRecord.name }}
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                label-for="tag-search-input"
                label="Zoek scholen"
                label-cols-md="auto"
                class="mb-0"
                label-size="sm"
                :description="searchDesc"
              >
                <b-form-input
                  v-model="search"
                  id="tag-search-input"
                  type="search"
                  size="sm"
                  autocomplete="off"
                  debounce="500"
                  class="w-75 d-inline-block"
                ></b-form-input>
                <b-button @click="$refs.dropdown.hide(true)" variant="outline-secondary"
                          class="d-inline-block float-right"><i class="fas fa-check"></i></b-button>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>

            <p class="px-3 mb-0 text-info">
              <small>{{ $t("modal.common.max_result_helper") }}</small>
            </p>

            <b-list-group class="p-3 overflow-auto form-tags--data-list">
              <b-list-group-item
                v-for="(option, index) in availableOptions"
                :key="index"
                @click="onOptionClick({ option })" href="#"
              >
                {{ option.Name }} <span v-if="option.Name2Formatted" class="text-info">- {{ option.Name2Formatted }}</span>
              </b-list-group-item>
            </b-list-group>

            <b-dropdown-text v-if="availableOptions.length === 0">
              {{ $t('customer.no_result') }}
            </b-dropdown-text>
          </b-dropdown>
        </b-form-group>
        <hr>
        <b-form-group
          id="input-group-2"
          label="Upload:"
          label-for="input-2"
        >
          <b-form-file
            v-model="form.UploadFile"
            :state="validateState('UploadFile')"
            :placeholder="$t('person.choose_xlsx2')"
            :drop-placeholder="$t('person.drag_xlsx')"
            :browse-text="$t('person.choose_xlsx')"
            accept=".xlsx"
            class="mb-3"
          ></b-form-file>
          <b-form-invalid-feedback
            id="input-2-live-feedback"
          >{{ $t('person.valid_xlsx') }}
          </b-form-invalid-feedback>
        </b-form-group>

      </b-form>

      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        no-wrap
      >
      </b-overlay>

      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3"/>

            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{
                errorMsg
              }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
            </div>
          </div>
        </template>

      </b-overlay>

    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['showImportPersonsModal'],
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      search: '',
      options: [],
      form: {
        CustomerRecord: {
          name: this.$t('customer.choose'),
          value: null
        },
        UploadFile: null
      },
      modalData: {
        StandardText: {
          Title: '',
          Content: ''
        },
        CustomerRecords: [],
        TemplateFile: null
      }
    }
  },
  validations: {
    form: {
      CustomerRecord: {
        required
      },
      UploadFile: {
        required
      }
    }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    initModal () {
      this.resetModal()
      this.loadData()
    },
    resetModal () {
      this.form = {
        Name: null,
        Name2: null,
        Email: null,
        options: [],
        CustomerRecord: {
          name: this.$t('customer.choose'),
          value: null
        },
        Education: null
      }
      this.processing = false
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    emitClose () {
      this.$emit('close')
    },
    resetAndClose () {
      this.resetModal()
      this.emitClose()
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          const socketParams = [
            response.data.socket.connect,
            response.data.socket.subscribeData
          ]
          this.$eventHub.$emit('createSocket', socketParams)
          this.$nextTick(() => {
            this.$bvModal.hide('import-persons-modal')
          })
        }
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    getData (params) {
      return this.$http.get('/php/xhr/ajax.php', {
        params
      })
    },
    loadData () {
      this.getData({
        function: 'getModal',
        modal: 'importPerson'
      }).then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const construct = response.data
          this.modalData.StandardText = construct.StandardText
          this.modalData.CustomerRecords = construct.CustomerRecords

          const CustomerRecordsArr = construct.CustomerRecords.map(record => {
            const CustomerRecordObj = {}
            CustomerRecordObj.text = record['Customer.Name']
            CustomerRecordObj.value = record['Customer.CustomerID']
            return CustomerRecordObj
          })

          this.modalData.CustomerRecords = CustomerRecordsArr

          if (this.modalData.CustomerRecords.length === 1) {
            this.form.CustomerRecord = this.modalData.CustomerRecords[0].value
          }
          this.loadCustomers()

          this.loading = false
        }
      })
    },
    loadCustomers () {
      this.getData({
        function: 'getCustomers',
        filter: this.criteria
      }).then(response => {
        const dataObj = response.data
        this.options = dataObj.CustomerShortList.items
        this.loading = false
      })
    },
    submitData () {
      const formData = new FormData()
      formData.append('CustomerID', this.form.CustomerRecord.value)
      formData.append('File', this.form.UploadFile)
      return this.$http.post('/php/xhr/ajax.php?function=importPerson', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    },
    onOptionClick ({ option }) {
      this.form.CustomerRecord = {
        name: option.Name,
        value: option.CustomerID
      }
      this.search = ''
      this.$refs.dropdown.hide(true)
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showImportPersonsModal
      },
      set () {
        this.$emit('close')
      }
    },
    criteria () {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions () {
      const options = this.options.filter(opt => this.form.CustomerRecord.value !== opt.CustomerID)
      // Show all options available
      return options
    },
    searchDesc () {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'Er zijn geen resultaten die aan uw zoekopdracht voldoen.'
      }
      return ''
    }
  },
  watch: {
    search: 'loadCustomers'
  }
}
</script>
