<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div v-if="currentItem.Activity">
    <div class="text-center my-5 py-4" v-if="loading">
      <b-spinner variant="primary" label="Large Spinner" type="grow"></b-spinner>
    </div>
    <div class="row" v-if="!loading">
      <div class="col-12">
        <strong>{{ $tc('tables.Project', 1) }} {{ currentItem.ProjectID }} - {{ currentItem.Customer.Name }}</strong>
        <hr>
        {{ currentItem.Name }}<br>
        {{ currentItem.ProjectShortList['Project.StudentCount'] }}
        <span v-if="currentItem.HasPersonYN">{{ $tc('menu.student', currentItem.ProjectShortList['Project.StudentCount']) }}<br>
        {{ currentItem.ProjectShortList['Project.FreeLicenseCount'] }}</span>
        {{ $tc('registration.free_licence', currentItem.ProjectShortList['Project.FreeLicenseCount']) }}<br>
        <span v-if="currentItem.HasActivityGroupYN">{{ currentItem.ProjectShortList['Project.CompanyCount'] }}
          {{ $tc('common.company', currentItem.ProjectShortList['Project.CompanyCount']) }}<br></span>
        <strong>{{ $t('common.duration') }}</strong><br>
        {{ currentItem.ProjectShortList['Project.StartDate'] }} {{ $t('common.till') }}
        {{ currentItem.ProjectShortList['Project.EndDate'] }}
      </div>
    </div>
    <hr>
    <div class="action-bar d-md-flex justify-content-end flex-row py-3">
      <div class="action-bar--actions mb-1" v-if="currentItem.ProjectShortList">
        <b-dropdown id="dropdown-right" right :text="$t('datatable.actions')" variant="primary"
                    class="action-bar--item pl-md-2 d-inline-block mx-1">
          <b-dropdown-item @click="showAssignEmployeeModal = !showAssignEmployeeModal" v-if="currentUser.ProjectInsertionAllowedYN"
          >{{ $t('project.assign_employee') }}
          </b-dropdown-item>
          <b-dropdown-item @click="showAddSubscriptionsModal = !showAddSubscriptionsModal" v-if="currentUser.ProjectInsertionAllowedYN && currentItem.Part.ActiveYN"
          >
            <span class="m-0" v-if="!currentItem.Activity.ExternalYN">{{ $t('project.add_subscriptions') }}</span>
            <span class="m-0" v-else>{{ $t('project.add_activitygroup') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showSubscribePersonsModal = !showSubscribePersonsModal" v-if="currentUser.PersonInsertionAllowedYN && currentItem.HasPersonYN"
                           :disabled="currentItem.ProjectShortList['Project.FreeLicenseCount'] < 1"
                           :title="currentItem.ProjectShortList['Project.FreeLicenseCount'] < 1 ? $t('project.link_not_available') : $t('common.link') + ' ' + $tc('menu.student', 2)"
          >{{ $tc('menu.student', 2) }} {{ $t('common.subscribe') }}
          </b-dropdown-item>
          <b-dropdown-item @click="showCreditModal = !showCreditModal" v-if="currentItem.CreditableYN"
                           :disabled="currentItem.ProjectShortList['Project.FreeLicenseCount'] === 0"
                           :title="$t('project.credit')"
          >{{ $t('project.credit') }}
          </b-dropdown-item>
          <b-dropdown-item @click="showAddCompanyModal = !showAddCompanyModal" v-if="currentUser.ActivityGroupInsertionAllowedYN && !currentItem.Activity.ExternalYN && currentItem.HasActivityGroupYN"
                           :disabled="currentItem.ProjectShortList['Project.StudentCount'] < 1"
                           :title="currentItem.ProjectShortList['Project.StudentCount'] < 1 ? $t('project.add_company_not_available') : $t('company.add')"
          >{{ $t('company.add') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <b-card no-body class="overflow-hidden mb-3">
      <b-row no-gutters>
        <b-col md="12">
          <b-card no-body>
            <b-tabs pills card>
              <b-tab :title="$tc('menu.student', 2)" class="p-0 p-md-3" v-if="currentItem.HasPersonYN">
                <b-card-text class="small-scroll-area">
                  <b-table striped borderless hover fixed :fields="personFields"
                           :items="personItems" class="mt-md-3 table-sm-no-border-radius">

                    <template v-slot:cell(edit)="row">
                      <router-link :to="{name: 'Person', params: {ID: row.item.ID}}">
                        <font-awesome-icon :icon="['fas', 'level-up']"/>
                      </router-link>
                    </template>

                    <template v-slot:cell(name)="row">
                      <router-link :to="{name: 'Person', params: {ID: row.item.ID}}" class="text-decoration-none">
                        {{ row.item.name }}
                      </router-link>
                    </template>

                  </b-table>
                </b-card-text>
              </b-tab>
              <b-tab :title="$tc('menu.company', 2)" class="p-0 p-md-3" v-if="currentItem.HasActivityGroupYN">
                <b-card-text class="small-scroll-area">
                  <b-table striped borderless hover fixed :fields="activityGroupFields"
                           :items="activityGroupItems" class="mt-md-3 table-sm-no-border-radius"
                           table-class="b-table-dark">

                    <template v-slot:cell(icon)="row">
                      <router-link :to="{name: 'ActivityGroup', params: {ID: row.item.ID}}">
                        <font-awesome-icon :icon="['fas', 'level-up']"/>
                      </router-link>
                    </template>

                    <template v-slot:cell(name)="row">
                      <router-link :to="{name: 'ActivityGroup', params: {ID: row.item.ID}}" class="text-decoration-none">
                        {{ row.item.name }}
                      </router-link>
                    </template>

                  </b-table>
                </b-card-text>
              </b-tab>
              <b-tab :title="$tc('menu.employee', 2)" class="p-0 p-md-3">
                <b-card-text class="small-scroll-area">
                  <b-table striped borderless hover fixed :fields="employeeFields"
                           :items="employeeItems" class="mt-md-3 table-sm-no-border-radius">

                    <template v-slot:cell(edit)="row">
                      <b-btn variant="link" class="p-0" @click="showDetails(row.item, 'Employee')">
                        <font-awesome-icon :icon="['fas', 'pencil']"/>
                      </b-btn>
                    </template>

                    <template v-slot:cell(name)="row">
                      <router-link :to="{name: 'Employee', params: {ID: row.item.ID}}" class="text-decoration-none">
                        {{ row.item.name }}
                      </router-link>
                    </template>

                  </b-table>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay :show="error" no-wrap>
      <template v-slot:overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label-1"
          class="p-3"
        >

          <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x"
                             class="text-danger mb-3"/>

          <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label-1" class="text-danger">
            {{ $t('error.error_retrieving_data') }}
          </strong></p>
        </div>
      </template>
    </b-overlay>

    <add-company :showAddCompanyModal="showAddCompanyModal" :currentProjectID="currentItem.ProjectID"
                 v-on:reload="loadData" v-on:close="showAddCompanyModal = false"></add-company>

    <add-subscriptions :showAddSubscriptionsModal="showAddSubscriptionsModal" :currentProject="currentItem"
                       v-on:reload="loadData" v-on:close="showAddSubscriptionsModal = false"></add-subscriptions>
    <credit :showCreditModal="showCreditModal" :currentProject="currentItem"
                       v-on:reload="loadData" v-on:close="showCreditModal = false"></credit>
    <subscribe-persons :showSubscribePersonsModal="showSubscribePersonsModal" :currentProjectID="currentItem.ProjectID"
                       :freeLicenseCount="currentItem.ProjectShortList['Project.FreeLicenseCount']"
                       v-on:reload="loadData" v-on:close="showSubscribePersonsModal = false"></subscribe-persons>
    <assign-employee :showAssignEmployeeModal="showAssignEmployeeModal" :currentProjectID="currentItem.ProjectID"
                     v-on:reload="loadData" v-on:close="showAssignEmployeeModal = false"></assign-employee>

    <b-modal id="detail-modal" title="Info" ref="detailModal" size="lg" ok-only :ok-title="$t('common.close')"
             @hidden="resetDetailModal">
      <div :style="{ backgroundImage: 'url(' + currentDetailItem.Image + ')' }"
           class="account-profile--img mb-3"></div>
      <h5 class="text-center">{{ currentDetailItem.name }}
        <b-badge variant="tertiary text-white">{{ currentDetailItem.UserGroup.Name }}</b-badge>
      </h5>
      <hr>
      <h6>{{ $t('common.view') }}</h6>
      <p>{{ $t('employee.navigate_to') }}</p>
      <b-btn variant="dark" :to="{name: this.currentDetailItem.DataTableID, params: {ID: currentDetailItem.ID}}">
        <font-awesome-icon :icon="['fas', 'level-up']"/>
        Naar detail pagina
      </b-btn>
      <div>
        <hr>
        <h6>{{ $t('modal.activitygroup.unlink') }}</h6>
        <p>Door te ontkoppelen verwijdert u de connectie met dit bedrijf</p>
        <b-btn variant="danger" @click="unlink(currentDetailItem)">
          <font-awesome-icon :icon="['fas', 'trash']"/>
          {{ $t('modal.activitygroup.unlink') }}
        </b-btn>
      </div>

      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label-2"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3"/>

            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label-2" class="text-danger">Error: {{
                errorMsg
              }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import AddCompany from '../ActivityGroup/AddActivityGroup'
import AddSubscriptions from './Actions/UpdateProject'
import Credit from './Actions/Credit'
import AssignEmployee from './Actions/AssignEmployee'
import SubscribePersons from '../Person/SubscribePersons'
import { mapGetters } from 'vuex'

export default {
  components: {
    AddSubscriptions,
    Credit,
    AddCompany,
    SubscribePersons,
    AssignEmployee
  },
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      currentDetailItem: {
        UserGroup: {}
      },
      showAddCompanyModal: false,
      showAddSubscriptionsModal: false,
      showSubscribePersonsModal: false,
      showCreditModal: false,
      showAssignEmployeeModal: false,
      currentItem: {
        ProjectShortList: [],
        Part: []
      },
      personFields: [
        { key: 'edit', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Studenten' },
        { key: 'SubscriptionID', label: 'Licentie #' }
      ],
      personItems: [],
      activityGroupFields: [
        { key: 'icon', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Bedrijven' },
        { key: 'StudentCount', label: 'Studenten' }
      ],
      activityGroupItems: [],
      employeeFields: [
        { key: 'edit', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Docenten' },
        { key: 'EmployeeID', label: 'Docent #' }
      ],
      employeeItems: []
    }
  },
  methods: {
    getData () {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getProject',
          ProjectID: this.currentItemID
        }
      })
    },
    resetDetailModal () {
      this.currentDetailItem = {
        UserGroup: {}
      }
    },
    loadData () {
      if (!this.currentItemID || this.$route.name !== 'Project') {
        return
      }
      this.loading = true
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const item = response.data
          this.currentItem = item

          if (item.PersonShortList) {
            const personItems = []
            item.PersonShortList.items.forEach(item => {
              personItems.push({
                ID: item.PersonID,
                name: item.Name + ' ' + item.Name2,
                SubscriptionID: item.Subscription.SubscriptionID
              })
            })
            this.personItems = personItems
          }

          if (item.ActivityGroupShortList) {
            const activityGroupItems = []
            item.ActivityGroupShortList.items.forEach(item => {
              activityGroupItems.push({
                ID: item.ActivityGroupID,
                name: item.Location.Name,
                StudentCount: item.StudentCount
              })
            })
            this.activityGroupItems = activityGroupItems
          }

          if (item.EmployeeShortList) {
            const employeeItems = []
            item.EmployeeShortList.items.forEach(item => {
              employeeItems.push({
                ID: item.EmployeeID,
                name: item.Name + ' ' + item.Name2,
                EmployeeID: item.EmployeeID,
                UserGroup: item.UserGroup
              })
            })
            this.employeeItems = employeeItems
          }

          this.loading = false
        }
      })
    },
    showDetails (item, DataTableID) {
      this.currentDetailItem = item
      this.currentDetailItem.DataTableID = DataTableID
      this.$refs.detailModal.show()
    },
    unlink (item) {
      const formData = new FormData()

      const xhr = 'unAssignEmployeeFromProject'
      formData.append('ProjectID', this.currentItem.ProjectID)
      formData.append('EmployeeID', this.currentDetailItem.ID)

      return this.$http.post('/php/xhr/ajax.php?function=' + xhr, formData).then(response => {
        if (response.data.status !== 'success') {
          this.makeToast('danger', 'Error', this.$t('toasts.general_error'))
        } else {
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.unlinked'))
          this.loadData()
          this.$refs.detailModal.hide()
        }
      })
    }
  },
  computed: {
    ...mapGetters('modal', ['currentItemID'])
  },
  watch: {
    $route: 'loadData'
  },
  created () {
    this.$nextTick(() => {
      this.$eventHub.$on('reloadModalData', this.loadData)
    })
  },
  beforeDestroy () {
    this.$eventHub.$off('reloadModalData')
  },
  mounted () {
    this.loadData()
  }
}
</script>
