<template>
    <div>
        <b-modal
                id="add-investor-modal"
                ref="modal"
                size="lg"
                :title="this.changeItem === null ? $t('investor.add') : $t('investor.change')"
                v-model="showModal"
                @show="initModal"
                @hidden="resetModal"
                @ok="handleSubmit"
                :ok-title="$t('common.save')"
                :cancel-title="$t('common.close')"
                :ok-disabled="processing"
        >

          <div v-if="changeItem !== null">
            <h6>{{ $t('investor.remove') }}</h6>
            <p>{{ $t('investor.remove_desc') }}</p>
            <b-btn variant="danger" @click="removeStockandClose()">
              <font-awesome-icon :icon="['fas', 'trash']"/>
              {{ $t('investor.remove') }}
            </b-btn>
            <hr>
          </div>

          <h6>{{ $t('investor.change') }}</h6>
          <p>{{ $t('investor.change_desc') }}</p>

          <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group id="input-group-1" :label="$t('common.firstname')" label-for="input-1">
                    <b-form-input
                            id="input-1"
                            name="input-1"
                            v-model="$v.form.Name.$model"
                            :state="validateState('Name')"
                            aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-1-live-feedback"
                    >{{ $t("modal.common.required_2_letters") }}
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-2" :label="$t('common.lastname')" label-for="input-2">
                    <b-form-input
                            id="input-2"
                            name="input-2"
                            v-model="$v.form.Name2.$model"
                            :state="validateState('Name2')"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-2-live-feedback"
                    >{{ $t("modal.common.required_2_letters") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-3" :label="$t('common.email')" label-for="input-3">
                    <b-form-input
                            id="input-3"
                            name="input-3"
                            v-model="$v.form.Email.$model"
                            :state="validateState('Email')"
                            aria-describedby="input-3-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-1-live-feedback"
                    >{{ $t("modal.common.valid_email") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-4" :label="$t('common.phone')" label-for="input-4">
                    <b-form-input
                            id="input-4"
                            name="input-4"
                            v-model="$v.form.Phone.$model"
                            :state="validateState('Phone')"
                            aria-describedby="input-4-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-1-live-feedback"
                    >{{ $t("modal.common.valid_phone") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-5" :label="$t('investor.IBAN')" label-for="input-5">
                    <b-form-input
                            id="input-5"
                            name="input-5"
                            v-model="$v.form.Iban.$model"
                            @input="$v.form.Iban.$model = ibanPrintFormat($event)"
                            :state="validateState('Iban')"
                            aria-describedby="input-5-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-5-live-feedback"
                    >{{ $t("modal.common.valid_iban") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-7" :label="$t('qty_modal.quantity')" label-for="input-7">
                    <b-form-input
                            id="input-7"
                            name="input-7"
                            type="number"
                            min="1"
                            v-model="$v.form.Amount.$model"
                            :state="validateState('Amount')"
                            aria-describedby="input-7-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-7-live-feedback"
                    >{{ $t("modal.common.valid_amount") }}</b-form-invalid-feedback>
                </b-form-group>

            </form>

            <b-overlay
                    :show="loading"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    no-wrap
            >
            </b-overlay>

            <b-overlay :show="processing" no-wrap>
                <template v-slot:overlay v-if="apiError">
                    <div
                            ref="dialog"
                            tabindex="-1"
                            role="dialog"
                            aria-modal="false"
                            aria-labelledby="form-confirm-label"
                            class="p-3"
                    >

                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

                        <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
                        <div class="d-flex">
                            <b-button variant="outline-danger" @click="cancelProcessing">{{ $t("common.back") }}</b-button>
                        </div>
                    </div>
                </template>
            </b-overlay>

        </b-modal>
    </div>
</template>

<script>
import { required, minLength, email, numeric } from 'vuelidate/lib/validators'
import { isValid as ibanValid, countries as ibanCountries, printFormat as ibanPrintFormat } from 'iban'
import { mask } from 'vue-the-mask'

export default {
  props: ['showAddInvestorModal', 'currentCompany', 'changeItem'],
  directives: { mask },
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      form: {
        Name: null,
        Name2: null,
        Email: null,
        Phone: null,
        Iban: null,
        Amount: 1
      },
      modalData: {
        StandardText: {
          Title: '',
          Content: ''
        },
        CustomerRecords: []
      }
    }
  },
  validations: {
    form: {
      Name: {
        required,
        minLength: minLength(2)
      },
      Name2: {
        required,
        minLength: minLength(2)
      },
      Email: {
        required,
        email
      },
      Phone: {
        required,
        numeric
      },
      Iban: {
        required,
        minLength: val => {
          if (val.length < 2) return minLength(2) // Handle length === 0 consistently
          const country = ibanCountries[val.toUpperCase().substring(0, 2)] || ibanCountries.NL
          return minLength(country.length)
        },
        ibanValid: val => {
          if (!val) {
            return true
          }
          return ibanValid(val)
        }
      },
      Amount: {
        required
      }
    }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    initModal () {
      this.resetModal()
      this.loadData()
    },
    resetModal () {
      this.form = {
        Name: null,
        Name2: null,
        Email: null,
        Phone: null,
        Iban: null,
        Amount: 1
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    emitClose () {
      this.$emit('close')
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$emit('reload')
          // Hide the modal manually
          this.$nextTick(() => {
            this.emitClose()
          })
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.investor_added'))
        }
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    getData () {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getModal',
          modal: 'addStockToActivityGroup'
        }
      })
    },
    ibanPrintFormat,
    loadData () {
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const construct = response.data
          this.modalData.StandardText = construct.StandardText
          this.modalData.CustomerRecords = construct.CustomerRecords
          if (this.changeItem !== null) {
            const data = this.changeItem.fullItem
            this.form.Name = data.Supplier.Name
            this.form.Name2 = data.Supplier.Name2
            this.form.Email = data.Supplier.Email
            this.form.Phone = data.Supplier.Phone
            this.form.Iban = data.Supplier.BankAccount
            this.form.Amount = parseFloat(data.FreeQuantity)
          }
          this.loading = false
        }
      })
    },
    submitData () {
      const formData = new FormData()
      formData.append('ActivityGroupID', this.currentCompany.ActivityGroupID)
      formData.append('Name', this.form.Name)
      formData.append('Name2', this.form.Name2)
      formData.append('Email', this.form.Email)
      formData.append('Phone', this.form.Phone)
      formData.append('BankAccount', this.form.Iban)
      formData.append('Quantity', this.form.Amount)

      if (this.changeItem === null) {
        return this.$http.post('/php/xhr/ajax.php?function=addStock', formData)
      } else {
        formData.append('StockID', this.changeItem.fullItem.StockID)
        return this.$http.post('/php/xhr/ajax.php?function=updateStock', formData)
      }
    },
    removeStockandClose () {
      this.deleteCurrentStock().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$emit('reload')
          // Hide the modal manually
          this.$nextTick(() => {
            this.emitClose()
          })
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.investor_updated'))
        }
      })
    },
    deleteCurrentStock () {
      const formData = new FormData()
      formData.append('StockID', this.changeItem.fullItem.StockID)
      formData.append('ActivityGroupID', this.currentCompany.ActivityGroupID)
      return this.$http.post('/php/xhr/ajax.php?function=removeStock', formData)
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showAddInvestorModal
      },
      set () {
        this.$emit('close')
      }
    }
  }
}
</script>
