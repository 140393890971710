<template>
  <div id="vue-page-wrapper">

    <div class="container-fluid" v-if="noCompanyException">
      <b-jumbotron class="mt-3" bg-variant="secondary" text-variant="white" border-variant="dark">
        <template v-slot:header>{{ $t('error.noCompanyException') }}</template>

        <template v-slot:lead>
          {{ $t('error.noCompanyException_long') }}.
        </template>

      </b-jumbotron>
    </div>

    <div class="text-center m-5" v-if="loading">
      <b-spinner variant="primary" label="Large Spinner" type="grow"></b-spinner>
    </div>

    <div id="company-detail" v-if="!loading && !noCompanyException">

      <div :style="{ backgroundImage: 'url(' + currentItem.BackgroundImage + ')' }"
           class="company--hero-wrapper w-100"></div>

      <div class="container" id="dashboard">
        <div class="row">
          <div class="col-12 col-lg-3">
            <div class="profile-slip">
              <div class="profile-slip--header bg-tertiary text-white p-3">
                <div :style="{ backgroundImage: 'url(' + currentItem.MainImage + ')' }"
                     class="account-profile--img img--xl mb-3"></div>
                <h1>{{ currentItem.Name }}</h1>
                <p>#{{ currentItem.ActivityGroupID }}</p>
              </div>
              <div class="profile-slip--body bg-light p-3">
                <p v-if="currentItem.BankAccount"
                      class="text-secondary">{{ currentItem.BankAccount }}</p>
                <h6 class="text-uppercase">{{ $t('common.about') }}</h6>
                <p v-if="this.currentItem.Content" class="text-break">{{ currentItem.Content }}</p>
                <small v-else class="mb-3 text-muted d-block">{{ $t('activitygroup.empty_description') }}</small>
                <h6 class="text-uppercase">{{ $t('common.duration') }}</h6>
                <p>{{ currentItem.StartDate }} t/m {{ currentItem.EndDate }}</p>
                <h6 class="text-uppercase">{{ $t('menu.program') }}</h6>
                <p>{{ currentItem.Project.Name }}</p>
                <div class="investment-block">
                  <p>{{ $tc('person.investor', 2) }}: <span class="float-right">{{
                      currentItem.InvestorQuantity
                    }}</span>
                  </p>
                  <p>{{ $tc('person.share', 2) }}: <span class="float-right">{{ currentItem.SharesQuantity }}</span>
                  </p>
                  <hr>
                  <h1 class="text-right mt-3">{{
                      getFormattedCurrency(currentItem.TotalInvestment)
                    }}</h1>
                </div>
              </div>
            </div>
            <b-table striped borderless hover :fields="documentFields" :items="documentItems"
                     v-if="documentItems.length !== 0" class="mt-3" table-class="b-table-primary">

              <template v-slot:cell(document)="{ item, value }">
                <b-link :href="item.url" target="_blank">{{ value }}</b-link>
              </template>

            </b-table>
            <component :is="currentModalComponent.modalComponent" :currentCompany="currentItem"
                       :modalText="currentModalComponent.description" v-on:reload="loadData" @unmount="currentModalComponent = {
                      modalComponent: null,
                      description: null
                      }"
            />
          </div>
          <div class="col-12 col-lg-9">
            <div class="action-bar d-md-flex flex-row py-3 justify-content-end">
              <div class="action-bar--actions">
                <!--                <div class="action-bar&#45;&#45;item d-inline-block">-->
                <!--                  <a href="#" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'plus']" /> <span class="text-uppercase">{{ $t("document.add") }}</span></a>-->
                <!--                </div>-->
                <div class="action-bar--item d-inline-block">
                  <b-btn @click="showChangeInfoModal = !showChangeInfoModal" variant="primary">
                    <font-awesome-icon :icon="['fas', 'pencil']"/>
                    <span class="text-uppercase">{{ $t('activitygroup.edit') }}</span></b-btn>
                  <b-btn @click="showAddInvestorModal = !showAddInvestorModal" variant="primary" class="ml-1">
                    <font-awesome-icon :icon="['fas', 'plus']"/>
                    <span class="text-uppercase">{{ $t('investor.add') }}</span></b-btn>
                </div>
              </div>
            </div>
            <div class="p-3 shadow-light bg-light mt-1">
              <h6 class="mb-0">{{ $t('common.progress') }}</h6>
              <div class="company-progress mt-3 text-center text-md-left">
                <h1 class="mb-0">{{ $t('common.progress') }}</h1>
                <small>{{ currentItem.Steps.Completed }}/{{ currentItem.Steps.Total }} stappen compleet</small>
                <div class="progress-btn-list mt-3">
                  <b-button v-for="(step, index) in steps" :key="index" :variant="getStyle(step.progresssion).variant"
                            :disabled="getStyle(step.progresssion).disabled" class="mb-1 mr-1 step-btn"
                            @click="currentModalComponent = step">
                    {{ step.name }}
                    <b-badge variant="light">
                      <font-awesome-icon :icon="['fas', getStyle(step.progresssion).icon]"/>
                    </b-badge>
                  </b-button>
                </div>
              </div>
            </div>
            <div class="p-3 mt-3 company--people">
              <h6>{{ $t('common.members') }}</h6>
              <div class="company-person" v-for="(student, index) in personItems"
                   :key="index">
                <div class="row">
                  <div class="col-12 col-lg-2">
                    <div class="drop-after">
                      <div :style="{ backgroundImage: 'url(' + student.Image + ')' }"
                           class="account-profile--img img--sm mb-3"></div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-10 mt-2">
                    <b-row>
                      <b-col cols="8">
                        <h6>{{ student.name }}</h6>
                      </b-col>
                      <b-col>
                        <b-btn variant="outline-secondary" class="float-right" @click="showDetails(student, 'Person')" v-if="currentItem.BaseModificationYN">
                          <font-awesome-icon :icon="['fas', 'pencil']"/>
                          {{ $t('activitygroup.change_role') }}
                        </b-btn>
                      </b-col>
                    </b-row>
                    <h5>
                      <div class="person-icons text-primary d-inline-block">
                        <a :href="'mailto:' + student.Email">
                          <font-awesome-icon :icon="['fas', 'envelope']" class="mr-2"/>
                        </a>
                        <a :href="'tel:' + student.Phone">
                          <font-awesome-icon :icon="['fas', 'phone-square-alt']"
                                             class="mr-2"/>
                        </a>
                      </div>
                      <b-badge>{{ student.rol }}</b-badge>
                    </h5>

                    <hr>

                  </div>
                </div>
              </div>
              <h6>{{ $t('common.accompaniment') }}</h6>
              <div class="employee-shortlist" v-if="employeeItems.length > 0">
                <div class="company-person" v-for="(employee, index) in employeeItems"
                     :key="index">
                  <div class="row">
                    <div class="col-12 col-lg-2">
                      <div class="drop-after">
                        <div :style="{ backgroundImage: 'url(' + employee.Image + ')' }"
                             class="account-profile--img img--sm mb-3"></div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-10 mt-2">
                      <h6>{{ employee.name }}</h6>
                      <h5>
                        <div class="person-icons text-primary d-inline-block">
                          <a :href="'mailto:' + employee.Email">
                            <font-awesome-icon :icon="['fas', 'envelope']" class="mr-2"/>
                          </a>
                          <!--                                    <a :href="'tel:' + employee.Phone"><font-awesome-icon :icon="['fas', 'phone-square-alt']" class="mr-2"/></a>-->
                        </div>
                        <b-badge>{{ employee.rol }}</b-badge>
                      </h5>

                      <hr>

                    </div>
                  </div>
                </div>
              </div>

              <div v-else>Er zijn nog geen begeleiders toegewezen</div>

              <b-table striped borderless hover fixed :fields="investorFields" :items="investorItems" class="mt-3"
                       table-class="b-table-dark" :empty-text="$t('activitygroup.no_investors')" show-empty>
                <template #empty="scope">
                  <h6>{{ scope.emptyText }}</h6>
                </template>
                <template v-slot:cell(edit)="row">
                  <b-btn variant="link" class="p-0" @click="showChangeInvestorModal(row.item, 'Person')">
                    <font-awesome-icon :icon="['fas', 'pencil']"/>
                  </b-btn>
                </template>
              </b-table>

            </div>

          </div>
        </div>

      </div>

    </div>

    <change-info :showChangeInfoModal="showChangeInfoModal" :currentCompany="currentItem"
                 v-on:close="showChangeInfoModal = false" @reload="loadData"></change-info>

    <add-investor :showAddInvestorModal="showAddInvestorModal" :currentCompany="currentItem" :changeItem="changeItem" v-on:reload="loadData" v-on:close="resetAddInvestorModal()"></add-investor>

    <b-modal id="detail-modal" title="Info" ref="detailModal" size="lg" :ok-title="$t('common.save')"
             :cancel-title="$t('common.close')" @hidden="resetDetailModal" @ok="handleSubmit">
      <div :style="{ backgroundImage: 'url(' + currentDetailItem.Image + ')' }"
           class="account-profile--img mb-3"></div>
      <h5 class="text-center">{{ currentDetailItem.name }}
        <b-badge variant="tertiary text-white">{{ currentDetailItem.Education }}</b-badge>
      </h5>
      <hr>
      <h6>{{ $t('activitygroup.change_role') }}</h6>
      <p>{{ $t('activitygroup.change_role_desc') }}</p>
      <b-form-row>
        <b-col cols="4">
          <b-form-select v-model="form.UserGroupID" :options="currentAvailableRoles"></b-form-select>
        </b-col>
      </b-form-row>

<!--      <hr>-->
<!--      <h6>Ontkoppelen</h6>-->
<!--      <p>Door te ontkoppelen verwijdert u de connectie met dit bedrijf</p>-->
<!--      <b-btn variant="danger" @click="unlink(currentDetailItem)">-->
<!--        <font-awesome-icon :icon="['fas', 'trash']"/>-->
<!--        Ontkoppelen-->
<!--      </b-btn>-->
      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3"/>

            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{
                errorMsg
              }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">Terug</b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>

import { formatCurrency } from '../../../js/helpers'
import ChangeInfo from './Actions/ChangeInfo'
import updateCompanyName from '@/vue/components/modals/Steps/updateCompanyName'
import updateBankAccount from '@/vue/components/modals/Steps/updateBankAccount'
import requestBankAccount from '@/vue/components/modals/Steps/requestBankAccount'
import businessPlanIsAdded from '@/vue/components/modals/Steps/businessPlanIsAdded'
import closeCompany from '@/vue/components/modals/Steps/closeCompany'
import { required } from 'vuelidate/lib/validators'
import AddInvestor from '@/vue/components/modals/ActivityGroup/Actions/AddInvestor'

export default {
  name: 'ActivityGroupDetails',
  components: {
    ChangeInfo,
    AddInvestor,
    updateCompanyName,
    updateBankAccount,
    requestBankAccount,
    businessPlanIsAdded,
    closeCompany
  },
  data () {
    return {
      currentModalComponent: {
        modalComponent: null,
        description: null
      },
      noCompanyException: false,
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      showChangeInfoModal: false,
      showAddInvestorModal: false,
      currentItem: {},
      changeItem: null,
      form: {
        UserGroupID: null
      },
      currentDetailItem: [],
      currentAvailableRoles: [],
      employeeItems: [],
      personItems: [],
      documentFields: [
        { key: 'document', label: 'Documenten' }
      ],
      documentItems: [],
      investorFields: [
        { key: 'edit', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Investeerders' },
        { key: 'quantity', label: 'Aandelen' },
        { key: 'iban', label: 'Bankrekening' }
      ],
      investorItems: [],
      steps: []
    }
  },
  validations: {
    form: {
      UserGroupID: {
        required
      }
    }
  },
  methods: {
    getData () {
      if (this.$route.params.ID) {
        // Impersonating a student
        return this.$http.get('/php/xhr/ajax.php', {
          params: {
            function: 'getActivityGroup',
            ActivityGroupID: this.$route.params.ID
          }
        })
      } else {
        return this.$http.get('/php/xhr/ajax.php', {
          params: {
            function: 'getActivityGroup',
            ActivityGroupID: this.currentUser.ActivityGroupID
          }
        })
      }
    },
    resetAddInvestorModal () {
      this.changeItem = null
      this.showAddInvestorModal = false
    },
    loadData () {
      this.loading = true
      if (!this.$route.params.ID && !this.currentUser.ActivityGroupID) {
        this.noCompanyException = true
        this.loading = false
      } else {
        this.getData().then(response => {
          if (response.data.status !== 'success') {
            this.error = true
          } else {
            const item = response.data
            this.currentItem = item

            if (item.Students.items) {
              const personItems = []
              item.Students.items.forEach(item => {
                personItems.push({
                  ID: item.PersonID,
                  name: item.Name + ' ' + item.Name2,
                  rol: item.Person_ActivityGroup.Name,
                  availableRoles: item.UserGroupRecords.items,
                  UserGroupID: item.Person_ActivityGroup.UserGroupID,
                  Education: item.Education,
                  Image: item.Image,
                  Phone: item.Phone,
                  Email: item.Email
                })
              })
              this.personItems = personItems
            }
            if (item.Teachers) {
              const employeeItems = []
              item.Teachers.items.forEach(item => {
                employeeItems.push({
                  ID: item.EmployeeID,
                  name: item.Name + ' ' + item.Name2,
                  rol: item.Employee_ActivityGroup.Name,
                  availableRoles: item.UserGroupRecords.items,
                  UserGroupID: item.Employee_ActivityGroup.UserGroupID,
                  Education: item.Education,
                  Image: item.Image,
                  Phone: item.Phone,
                  Email: item.Email
                })
              })
              this.employeeItems = employeeItems
            }
            if (item.Investors) {
              const investorItems = []
              item.Investors.items.forEach(item => {
                investorItems.push({
                  name: item.Supplier.Name + ' ' + item.Supplier.Name2,
                  quantity: parseFloat(item.FreeQuantity),
                  iban: item.Supplier.BankAccount,
                  fullItem: item
                })
              })
              this.investorItems = investorItems
            }
            if (item.Steps) {
              const Steps = []
              item.Steps.ShortList.items.forEach(item => {
                const itemProgression = (item.Skill.FinishedYN ? 2 : (item.Skill.inProgressYN ? 1 : 0))
                Steps.push({
                  name: item.Skill.Name,
                  progresssion: itemProgression,
                  modalComponent: item.Skill.UserDefinedField01,
                  description: item.Skill.Description
                })
              })
              this.steps = Steps
            }

            this.loading = false
          }
        })
      }
    },
    getFormattedCurrency (amount) {
      return formatCurrency(amount)
    },
    showDetails (item, DataTableID) {
      this.currentDetailItem = item
      item.availableRoles.forEach(role => {
        this.currentAvailableRoles.push({
          text: role.Name,
          value: role.UserGroupID
        })
      })
      this.form.UserGroupID = item.UserGroupID
      this.currentDetailItem.DataTableID = DataTableID
      this.$refs.detailModal.show()
    },
    showChangeInvestorModal (item) {
      this.changeItem = item
      this.showAddInvestorModal = true
    },
    resetDetailModal () {
      this.currentDetailItem = []
      this.currentAvailableRoles = []
      this.form = {
        UserGroupID: null
      }
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.data_changed'))
          this.loadData()
          this.$refs.detailModal.hide()
        }
      })
    },
    submitData () {
      const xhr = this.currentDetailItem.DataTableID === 'Employee' ? 'modifyEmployeeActivityGroup' : 'modifyPersonActivityGroup'
      const formData = new FormData()

      formData.append('method', 'update')
      formData.append('ActivityGroupID', this.currentItem.ActivityGroupID)
      formData.append(this.currentDetailItem.DataTableID === 'Employee' ? 'EmployeeRecords[' + this.currentDetailItem.ID + '][EmployeeID]' : 'PersonRecords[' + this.currentDetailItem.ID + '][PersonID]', this.currentDetailItem.ID)
      formData.append(this.currentDetailItem.DataTableID === 'Employee' ? 'EmployeeRecords[' + this.currentDetailItem.ID + '][UserGroupID]' : 'PersonRecords[' + this.currentDetailItem.ID + '][UserGroupID]', this.form.UserGroupID)
      return this.$http.post('/php/xhr/ajax.php?function=' + xhr, formData)
    },
    getStyle (progression) {
      const styles = {
        0: {
          variant: 'outline-primary',
          disabled: false,
          icon: 'exclamation'
        },
        1: {
          variant: 'outline-warning',
          disabled: true,
          icon: 'hourglass'
        },
        2: {
          variant: 'outline-success',
          disabled: true,
          icon: 'check'
        }
      }
      return styles[progression]
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="scss">

</style>
