export default {
  namespaced: true,
  state: () => ({
    visible: false,
    dataTableID: null,
    currentItemID: ''
  }),
  mutations: {
    setVisibility (state, visible) {
      state.visible = visible
    },
    setDataTableID (state, dataTableID) {
      state.dataTableID = dataTableID
    },
    setCurrentItemID (state, ID) {
      state.currentItemID = ID
    }
  },
  actions: {
    show (context, data) {
      context.commit('setVisibility', true)
      context.commit('setDataTableID', data.dataTableID)
      context.commit('setCurrentItemID', data.ID)
    },
    hide (context) {
      context.commit('setVisibility', false)
      context.commit('setDataTableID', null)
      context.commit('setCurrentItemID', null)
    }
  },
  getters: {
    currentItemID: state => state.currentItemID
  }
}
