<template>
    <div>
        <b-modal
                id="change-info-modal"
                ref="modal"
                size="lg"
                :title="$t('activitygroup.company_info')"
                v-model="showModal"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleSubmit"
                :ok-title="$t('common.save')"
                :cancel-title="$t('common.close')"
                :ok-disabled="processing"
        >
            <b-container>

                <b-form ref="form" @submit.stop.prevent="handleSubmit">

                    <b-img :src="currentCompany.MainImage" center class="account-profile--img mb-3"
                           rounded="circle" alt="Circle image"></b-img>
                    {{ $t("modal.activitygroup.profile_image") }}:
                    <b-form-file
                            v-model="changeCompanyForm.MainImage"
                            :state="validateState('MainImage')"
                            :placeholder="$t('modal.common.choose_file_or_drop')"
                            :drop-placeholder="$t('modal.common.drop_here')"
                            :browse-text="$t('modal.common.choose_file')"
                            accept="image/jpeg, image/png"
                            class="mb-3"
                    ></b-form-file>
                    <b-form-text id="form-file-help-block">
                        {{ $t("modal.common.max_file_size_5mb") }}
                    </b-form-text>
                    <b-badge v-if="imageError" variant="danger">{{ imageError }}</b-badge>

                    <hr>
                    <strong>{{ $t("modal.common.company_data") }}</strong>
                    <hr>

                    <b-form-row>
                        <b-col>
                            <b-form-group id="input-group-1" :label="$t('company.name')" label-for="input-1">
                                <b-form-input
                                        id="input-1"
                                        name="input-1"
                                        :placeholder="$t('company.name_instruction')"
                                        v-model="$v.changeCompanyForm.Name.$model"
                                        :state="validateState('Name')"
                                        aria-describedby="input-1-live-feedback"
                                ></b-form-input>

                                <b-form-invalid-feedback
                                        id="input-1-live-feedback"
                                >{{ $t("modal.common.required_2_letters") }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col>
                            <b-form-group id="input-group-2" :label="$t('company.description')" label-for="input-1">
                                <b-form-textarea
                                        id="input-2"
                                        name="input-2"
                                        :placeholder="$t('company.enter_description')"
                                        v-model="$v.changeCompanyForm.Content.$model"
                                        :state="validateState('Content')"
                                        aria-describedby="input-2-live-feedback"
                                ></b-form-textarea>

                                <b-form-invalid-feedback
                                        id="input-2-live-feedback"
                                >{{ $t("modal.common.min_10_max_400_letters") }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-overlay :show="processing" no-wrap>
                        <template v-slot:overlay v-if="apiError">
                            <div
                                    ref="dialog"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-modal="false"
                                    aria-labelledby="form-confirm-label"
                                    class="p-3"
                            >

                                <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

                                <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
                                <div class="d-flex">
                                    <b-button variant="outline-danger" @click="cancelProcessing">Terug</b-button>
                                </div>
                            </div>
                        </template>
                    </b-overlay>

                </b-form>

            </b-container>

        </b-modal>
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  props: ['showChangeInfoModal', 'currentCompany'],
  data () {
    return {
      imageError: null,
      error: false,
      processing: false,
      apiError: false,
      errorMsg: null,
      showConfirmOverlay: false,
      changeCompanyForm: {
        MainImage: null,
        Name: null,
        Content: null
      }
    }
  },
  validations: {
    changeCompanyForm: {
      MainImage: {},
      Name: {
        required,
        minLength: minLength(2)
      },
      Content: {
        minLength: minLength(10),
        maxLength: maxLength(400)
      }
    }
  },
  methods: {
    resetModal () {
      this.changeCompanyForm = {
        MainImage: null,
        Name: this.currentCompany.Name,
        Content: this.currentCompany.Content
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.changeCompanyForm[name]
      return $dirty ? !$error : null
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.changeCompanyForm.$touch()
      if (this.$v.changeCompanyForm.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$emit('reload')
          // Hide the modal manually
          this.$nextTick(() => {
            this.$emit('close')
          })
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.data_changed'))
        }
      })
    },
    submitData () {
      const xhr = 'updateActivityGroup'
      const formData = new FormData()

      formData.append('ActivityGroupID', this.currentCompany.ActivityGroupID)
      if (this.changeCompanyForm.MainImage && this.imageError === null) {
        formData.append('MainImage', this.changeCompanyForm.MainImage)
      }
      formData.append('Name', this.changeCompanyForm.Name)
      if (this.changeCompanyForm.Content) {
        formData.append('Content', this.changeCompanyForm.Content)
      }

      return this.$http.post('/php/xhr/ajax.php?function=' + xhr, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    checkImageSize () {
      if (this.changeCompanyForm.MainImage === null) {
        this.imageError = null
        return
      }
      this.processing = true
      this.imageError = null

      const maxFileSize = 5 * 1000000 // bytes

      if (this.changeCompanyForm.MainImage.size > maxFileSize) {
        this.imageError = 'FOUT: bestand is groter dan 5MB en zal niet worden geupload'
      }
      this.processing = false
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showChangeInfoModal
      },
      set () {
        this.$emit('close')
      }
    }
  },
  watch: {
    'changeCompanyForm.Image': 'checkImageSize'
  }
}
</script>
