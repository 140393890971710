const getAddressAPIMixin = {
  data () {
    return {}
  },
  methods: {
    getAddress (zipcode, houseNr) {
      const formData = new FormData()

      formData.append('zipcode', zipcode)
      formData.append('houseNr', houseNr)

      return this.$http.post('/php/xhr/ajax.php?function=getAddress', formData)
    }
  }
}

export default getAddressAPIMixin
