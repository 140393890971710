<template>
  <div>
    <b-modal
      id="add-project-modal"
      ref="modal"
      size="lg"
      :title=modalData.StandardText.Title
      v-model="showModal"
      @show="initModal"
      @hidden="resetModal"
      @ok="handleOk"
      @close="emitClose"
      @cancel="emitClose"
      :ok-title="$t('common.save')"
      :cancel-title="$t('common.cancel')"
      :ok-disabled="processing || loading"
    >
      <div v-html="modalData.StandardText.Content"></div>

      <b-form ref="form" @submit.stop.prevent="handleOk">

        <b-form-group
          id="input-group-1"
          :label="$t('customer.choose')"
          label-for="input-1"
          :state="this.form.CustomerRecord.value !== null"
        >

          <b-dropdown
            size="sm"
            :variant="this.form.CustomerRecord.value !== null ? 'outline-secondary' : 'outline-danger'"
            block
            menu-class="w-100"
            ref="dropdown"
          >
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'school']"/>
              {{ form.CustomerRecord.name }}
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                label-for="tag-search-input"
                label="Zoek scholen"
                label-cols-md="auto"
                class="mb-0"
                label-size="sm"
                :description="searchDesc"
              >
                <b-form-input
                  v-model="search"
                  id="tag-search-input"
                  type="search"
                  size="sm"
                  autocomplete="off"
                  debounce="500"
                  class="w-75 d-inline-block"
                ></b-form-input>
                <b-button @click="$refs.dropdown.hide(true)" variant="outline-secondary"
                          class="d-inline-block float-right"><i class="fas fa-check"></i></b-button>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>

            <p class="px-3 mb-0 text-info">
              <small>{{ $t('modal.common.max_result_helper') }}</small>
            </p>

            <b-list-group class="p-3 overflow-auto form-tags--data-list">
              <b-list-group-item
                v-for="(option, index) in availableOptions"
                :key="index"
                @click="onOptionClick({ option })" href="#"
              >
                {{ option.Name }} <span v-if="option.Name2Formatted" class="text-info">- {{ option.Name2Formatted }}</span>
              </b-list-group-item>
            </b-list-group>

            <b-dropdown-text v-if="availableOptions.length === 0">
              {{ $t('customer.no_result') }}
            </b-dropdown-text>
          </b-dropdown>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Programma:"
          label-for="input-2"
        >
          <b-form-select
            id="input-2"
            v-model="form.ActivityRecord"
            :options="modalData.ActivityRecords"
            :state="validateState('ActivityRecord')"
            aria-describedby="input-2-live-feedback"
          ></b-form-select>
          <b-form-invalid-feedback
            id="input-2-live-feedback"
          >{{ $t('project.choose_program') }}.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Start datum:"
          label-for="input-3"
        >
          <b-form-datepicker
            v-model="form.date"
            :min="min"
            :max="max"
            show-decade-nav
            no-flip
            :locale="currentLocale.ID"
            :label-help="$t('modal.common.datepicker_label_help')"
            :state="validateState('date')"
            aria-describedby="input-3-live-feedback"
            :placeholder="$t('modal.common.enter_date')"
          ></b-form-datepicker>
          <b-form-invalid-feedback
            id="input-3-live-feedback"
          >{{ $t('modal.common.valid_date') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          label="Tarief:"
          label-for="input-4"
        >
          <b-form-select
            id="input-4"
            v-model="form.ActivityPartRecord"
            :options="selectedActivityRecordParts"
            :state="validateState('ActivityPartRecord')"
            aria-describedby="input-4-live-feedback"
          ></b-form-select>
          <b-form-invalid-feedback
            id="input-4-live-feedback"
          >Kies een tarief
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="$t('project.company_quantity')"
          label-for="input-5"
          v-if="form.ActivityRecord['Activity.ExternalYN'] === '1'"
        >
          <b-form-input
            id="input-5"
            v-model="form.CompanyQuantity"
            type="number"
            :state="validateState('CompanyQuantity')"
            aria-describedby="input-5-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-5-live-feedback"
          >{{ $t('common.choose_quantity') }}.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-6"
          :label="$t('project.license_amount')"
          label-for="input-6"
        >
          <b-form-input
            id="input-6"
            v-model="form.Quantity"
            type="number"
            :state="validateState('Quantity')"
            aria-describedby="input-6-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-6-live-feedback"
          >{{ $t('common.choose_quantity') }}.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-7"
          :label="$t('project.own_reference')"
          label-for="input-7"
        >
          <b-form-input
            id="input-7"
            v-model="form.Reference"
            :state="validateState('Reference')"
            aria-describedby="input-7-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-7-live-feedback"
          >{{ $t('common.max_50_characters') }}.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="input-group-8">
          <b-form-checkbox-group
            v-model="form.termsAgreed"
            id="input-8"
            :state="validateState('termsAgreed')"
            aria-describedby="input-8-live-feedback"
          >
            <b-form-checkbox value="1">{{ $t('project.i_agree_to_the') }} <a :href="termsAndConditionsURL"
                                                                             target="_blank">{{
                $t('project.participation_terms')
              }}</a></b-form-checkbox>
          </b-form-checkbox-group>
          <b-form-invalid-feedback
            id="input-8-live-feedback"
          >{{ $t('project.agree_to_terms_required') }}.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>

      <b-overlay :show="showConfirmOverlay" no-wrap @shown="onShown" @hidden="onHidden">
        <template v-slot:overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >
            <p><strong id="form-confirm-label">{{ $t('project.confirm_pay_obligation') }}?</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" class="mr-3" @click="onCancel">
                {{ $t('common.cancel') }}
              </b-button>
              <b-button variant="outline-success" @click="handleSubmit">{{ $t('common.confirm') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        no-wrap
      >
      </b-overlay>

      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3"/>

            <p><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

    </b-modal>
  </div>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import { formatCurrency } from '../../../../js/helpers'
import moment from 'moment'

export default {
  props: ['showAddProjectModal'],
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 12)
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      search: '',
      options: [],
      min: today,
      max: maxDate,
      showConfirmOverlay: false,
      termsAndConditionsURL: '',
      form: {
        ActivityRecord: {
          'Activity.ExternalYN': '0'
        },
        CustomerRecord: {
          name: this.$t('customer.choose'),
          value: null
        },
        ActivityPartRecord: 0,
        Quantity: 1,
        CompanyQuantity: 1,
        Reference: null,
        termsAgreed: [],
        date: moment().format('YYYY-MM-DD')
      },
      modalData: {
        StandardText: {
          Title: '',
          Content: '',
          UserDefinedField01: ''
        },
        ActivityRecords: [],
        ActivityPartRecords: [],
        CustomerRecords: []
      }
    }
  },
  validations () {
    if (this.form.ActivityRecord['Activity.ExternalYN'] === '1') {
      return {
        form: {
          ActivityRecord: {
            required
          },
          CustomerRecord: {
            value: {
              required
            }
          },
          ActivityPartRecord: {
            required
          },
          Quantity: {
            required
          },
          CompanyQuantity: {
            required
          },
          Reference: {
            maxLength: maxLength(50)
          },
          termsAgreed: {
            required
          },
          date: {
            required
          }
        }
      }
    } else {
      return {
        form: {
          ActivityRecord: {
            required
          },
          CustomerRecord: {
            value: {
              required
            }
          },
          ActivityPartRecord: {
            required
          },
          Quantity: {
            required
          },
          Reference: {
            maxLength: maxLength(50)
          },
          termsAgreed: {
            required
          },
          date: {
            required
          }
        }
      }
    }
  },
  methods: {
    validateState (name, nestedIn = null) {
      if (nestedIn) {
        const { $dirty, $error } = this.$v.form[nestedIn][name]
        return $dirty ? !$error : null
      } else {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? !$error : null
      }
    },
    initModal () {
      this.resetModal()
      this.loadData()
    },
    resetModal () {
      this.showConfirmOverlay = false
      this.form = {
        ActivityRecord: {
          'Activity.ExternalYN': '0'
        },
        CustomerRecord: {
          name: this.$t('customer.choose'),
          value: null
        },
        ActivityPartRecord: null,
        Quantity: 1,
        CompanyQuantity: 1,
        Reference: null,
        termsAgreed: [],
        date: moment().format('YYYY-MM-DD')
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onCancel () {
      this.showConfirmOverlay = false
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.showConfirmOverlay = true
    },
    emitClose () {
      this.$emit('close')
    },
    handleSubmit () {
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          this.showConfirmOverlay = false
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.showConfirmOverlay = false
          this.processing = false
          const socketParams = [
            response.data.socket.connect,
            response.data.socket.subscribeData
          ]
          this.$eventHub.$emit('createSocket', socketParams)
          this.$nextTick(() => {
            this.$bvModal.hide('add-project-modal')
          })
        }
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    getData (params) {
      return this.$http.get('/php/xhr/ajax.php', {
        params
      })
    },
    loadCustomers () {
      this.getData({
        function: 'getCustomers',
        filter: this.criteria
      }).then(response => {
        const dataObj = response.data
        this.options = dataObj.CustomerShortList.items
        this.loading = false
      })
    },
    submitData () {
      const formData = new FormData()

      formData.append('PartID', this.form.ActivityPartRecord)
      formData.append('CustomerID', this.form.CustomerRecord.value)
      formData.append('StartDate', this.form.date)
      formData.append('Quantity', this.form.Quantity)

      if (this.form.Reference !== null) {
        formData.append('CustomerReference', this.form.Reference)
      }

      if (this.form.ActivityRecord['Activity.ExternalYN'] === '1') {
        formData.append('CompanyQuantity', this.form.CompanyQuantity)
      }

      return this.$http.post('/php/xhr/ajax.php?function=addProject', formData)
    },
    loadData: function () {
      this.getData({
        function: 'getModal',
        modal: 'addProject'
      }).then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const construct = response.data

          this.modalData.StandardText = construct.StandardText

          this.termsAndConditionsURL = construct.TermsAndConditionsUrl

          const unassignedArr = []

          const orderedActivityRecords = Object.values(construct.ActivityRecords.reduce((acc, el) => {
            if (el['Activity.Summary'] === '') {
              unassignedArr.push({
                text: el['Activity.Name'],
                value: el
              })
            } else {
              acc[el['Activity.Summary']] = acc[el['Activity.Summary']] || { text: el['Activity.Summary'], options: [] }
              acc[el['Activity.Summary']].options.push({
                text: el['Activity.Name'],
                value: el
              })
            }

            const optionList = {
              ...acc,
              ...unassignedArr
            }

            return optionList
          }, {}))

          const ActivityRecordsArr = orderedActivityRecords

          // const ActivityRecordsArr = construct.ActivityRecords.map(record => {
          //   const ActivityRecordObj = {}
          //   ActivityRecordObj.text = record['Activity.Name']
          //   ActivityRecordObj.value = record
          //   return ActivityRecordObj
          // })

          this.modalData.ActivityRecords = ActivityRecordsArr

          // The variations are nested inside the activityRecord. So we map them to a separate array with an reference to the parent record
          const ActivityPartRecords = construct.ActivityRecords.map(record => {
            const ActivityPartRecordObj = {}
            ActivityPartRecordObj.ActivityRecordID = record['Activity.ActivityID']
            ActivityPartRecordObj.ActivityPartRecords = record['Activity.PartRecords'].map(subRecord => {
              const subRecordObj = {}
              subRecordObj.text = subRecord['Part.Name'] + ' ' + formatCurrency(subRecord['Part.RetailPriceInclVAT'])
              subRecordObj.value = subRecord['Part.PartID']
              return subRecordObj
            })

            return ActivityPartRecordObj
          })

          this.modalData.ActivityPartRecords = ActivityPartRecords
          this.loadCustomers()
          this.loading = false
        }
      })
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    },
    onOptionClick ({ option }) {
      this.form.CustomerRecord = {
        name: option.Name,
        value: option.CustomerID
      }
      this.search = ''
      this.$refs.dropdown.hide(true)
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showAddProjectModal
      },
      set () {
        this.$emit('close')
      }
    },
    selectedActivityRecordParts () {
      const selected = this.modalData.ActivityPartRecords.filter(i => i.ActivityRecordID === this.form.ActivityRecord['Activity.ActivityID'])
      return (selected.length > 0 ? selected[0].ActivityPartRecords : [])
    },
    criteria () {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions () {
      const options = this.options.filter(opt => this.form.CustomerRecord.value !== opt.CustomerID)
      // Show all options available
      return options
    },
    searchDesc () {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'Er zijn geen resultaten die aan uw zoekopdracht voldoen.'
      }
      return ''
    }
  },
  watch: {
    search: 'loadCustomers'
  }
}
</script>
