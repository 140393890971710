<template>
    <div class="col-12 col-sm-6 mb-4">
        <div class="block-banner">
          <img :src="banner.ImageResized" :alt="banner.Name" class="w-100">
          <h5 class="text-white banner-title font-special">{{banner.Name}}</h5>
          <a :href="banner.ClickOutUrl" target="_blank" class="stretched-link"></a>
        </div>
    </div>
</template>

<script>

export default {
  name: 'banner',
  props: {
    banner: Object
  }
}
</script>

<style scoped>

</style>
