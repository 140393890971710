export default {
  namespaced: true,
  state: () => ({
    visible: true
  }),
  mutations: {
    setVisibility (state, visible) {
      state.visible = visible
    }
  },
  actions: {
    show (context, data) {
      context.commit('setVisibility', true)
    },
    hide (context) {
      context.commit('setVisibility', false)
    }
  }
}
