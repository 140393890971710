<template>
    <b-modal id="stepCloseCompany" ref="stepCloseCompany" :title="$t('steps.close_company')"
             @ok="handleOk"
             @hidden="destroy"
             size="lg"
             :ok-title="$t('common.save')"
             :ok-disabled="!this.currentCompany.FinancialModificationYN || showSuccessMessage"
             :cancel-title="$t('common.cancel')"
             :cancel-disabled="showSuccessMessage"
    >
      <div v-html="modalText"></div>
      <b-form ref="form" @submit.stop.prevent="handleOk">
        <h6>{{ $t("financial.Enter_VAT") }}</h6>
        <hr>
        <b-input-group
          id="input-group-x"
          class="mb-2"
          prepend="%"
          :append="$t('financial.VAT_long')"
          label-for="input-x"
        >
          <b-form-select
            id="input-x"
            @change="calcCompanyClose"
            v-model="form.Custom_VAT"
            :state="validateState('Custom_VAT')"
            aria-describedby="input-x-live-feedback"
          >
            <b-form-select-option :value="null">{{ $t("financial.Enter_VAT") }}</b-form-select-option>
            <b-form-select-option value="21">21%</b-form-select-option>
            <b-form-select-option value="9">9%</b-form-select-option>
          </b-form-select>

        </b-input-group>

        <b-form-invalid-feedback
          id="input-x-live-feedback" class="mb-2" :state="validateState('Custom_VAT')"
        >{{ $t("financial.incorrect_custom_vat") }}</b-form-invalid-feedback>

        <h6>{{ $t("financial.Calculate_profit") }}</h6>
        <hr>
        <b-input-group
          id="input-group-1"
          class="mb-2"
          prepend="€"
          :append="$t('financial.Revenue')"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            type="number"
            step="0.01"
            @change="calcCompanyClose"
            v-model="form.Revenue"
            :state="validateState('Revenue')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-1-live-feedback" class="mb-2" :state="validateState('Revenue')"
        >{{ $t("financial.incorrect_Revenue") }}</b-form-invalid-feedback>

        <b-input-group
          id="input-group-2"
          class="mb-3"
          prepend="€"
          :append="$t('financial.Expenses')"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            type="number"
            step="0.01"
            v-model="form.Expenses"
            :state="validateState('Expenses')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-2-live-feedback" class="mb-2" :state="validateState('Expenses')"
        >{{ $t("financial.incorrect_Expenses") }}</b-form-invalid-feedback>

        <h6>{{ $t("financial.Profit") }}:</h6>
        <b-input-group
          id="input-group-3"
          class="mb-3"
          prepend="€"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            type="number"
            readonly
            step="0.01"
            v-model="form.Profit"
            :state="validateState('Profit')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-2-live-feedback" class="mb-2" :state="validateState('Profit')"
        >{{ $t("financial.incorrect_Profit") }}</b-form-invalid-feedback>

        <h6>{{ $t("financial.Calculate_tax") }}</h6>
        <hr>
        <b-input-group
          id="input-group-4"
          class="mb-2"
          prepend="€"
          :append="$t('financial.LabourCost')"
          label-for="input-4"
        >
          <b-form-input
            id="input-4"
            type="number"
            step="0.01"
            v-model="form.LabourCost"
            :state="validateState('LabourCost')"
            aria-describedby="input-4-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-4-live-feedback" class="mb-2" :state="validateState('LabourCost')"
        >{{ $t("financial.incorrect_LabourCost") }}</b-form-invalid-feedback>

        <b-input-group
          id="input-group-5"
          class="mb-2"
          prepend="€"
          :append="$t('financial.RevenueVAT')"
          label-for="input-5"
        >
          <b-form-input
            id="input-5"
            type="number"
            step="0.01"
            v-model="form.RevenueVAT"
            :state="validateState('RevenueVAT')"
            aria-describedby="input-5-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-5-live-feedback" class="mb-2" :state="validateState('RevenueVAT')"
        >{{ $t("financial.incorrect_RevenueVAT") }}</b-form-invalid-feedback>

        <b-input-group
          id="input-group-6"
          class="mb-3"
          prepend="€"
          :append="$t('financial.ExpensesVAT')"
          label-for="input-6"
        >
          <b-form-input
            id="input-6"
            type="number"
            step="0.01"
            v-model="form.ExpensesVAT"
            :state="validateState('ExpensesVAT')"
            aria-describedby="input-6-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-6-live-feedback" class="mb-2" :state="validateState('ExpensesVAT')"
        >{{ $t("financial.incorrect_ExpensesVAT") }}</b-form-invalid-feedback>

        <h6>{{ $t("financial.TotalVAT") }}:</h6>

        <b-input-group
          id="input-group-7"
          class="mb-3"
          prepend="€"
          label-for="input-7"
        >
          <b-form-input
            id="input-7"
            type="number"
            readonly
            step="0.01"
            v-model="form.TotalVAT"
            :state="validateState('TotalVAT')"
            aria-describedby="input-7-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-7-live-feedback" class="mb-2" :state="validateState('TotalVAT')"
        >{{ $t("financial.incorrect_TotalVAT") }}</b-form-invalid-feedback>

        <h6>{{ $t("financial.ProfitTax") }}:</h6>

        <b-input-group
          id="input-group-8"
          class="mb-3"
          prepend="€"
          label-for="input-8"
        >
          <b-form-input
            id="input-8"
            type="number"
            readonly
            step="0.01"
            v-model="form.ProfitTax"
            :state="validateState('ProfitTax')"
            aria-describedby="input-8-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-8-live-feedback" class="mb-2" :state="validateState('ProfitTax')"
        >{{ $t("financial.incorrect_ProfitTax") }}</b-form-invalid-feedback>

        <h6>{{ $t("financial.WageTax") }}:</h6>

        <b-input-group
          id="input-group-9"
          class="mb-3"
          prepend="€"
          label-for="input-9"
        >
          <b-form-input
            id="input-9"
            type="number"
            readonly
            step="0.01"
            v-model="form.WageTax"
            :state="validateState('WageTax')"
            aria-describedby="input-9-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-9-live-feedback" class="mb-2" :state="validateState('WageTax')"
        >{{ $t("financial.incorrect_WageTax") }}</b-form-invalid-feedback>

        <h6>{{ $t("financial.TotalTax") }}:</h6>

        <b-input-group
          id="input-group-10"
          class="mb-3"
          prepend="€"
          label-for="input-10"
        >
          <b-form-input
            id="input-10"
            type="number"
            readonly
            step="0.01"
            v-model="form.TotalTax"
            :state="validateState('TotalTax')"
            aria-describedby="input-10-live-feedback"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback
          id="input-10-live-feedback" class="mb-2" :state="validateState('TotalTax')"
        >{{ $t("financial.incorrect_TotalTax") }}</b-form-invalid-feedback>

        <h6>{{ $t("financial.BankStatement") }}:</h6>
        <p>{{ $t('financial.BankStatement_AdditionalInfo') }}</p>
        <b-form-file
          v-model="form.Image"
          ref="file-input"
          :state="validateState('Image')"
          :placeholder="$t('modal.common.choose_file_or_drop')"
          :drop-placeholder="$t('modal.common.drop_here')"
          :browse-text="$t('modal.common.choose_file')"
          accept="image/*, application/pdf"
          class="mb-3"
        ></b-form-file>
        <b-form-text id="form-file-help-block">
          {{ $t('modal.common.max_file_size_5mb') }}
        </b-form-text>
        <b-badge v-if="imageError" variant="danger">{{ imageError }}</b-badge>

      </b-form>

      <b-overlay :show="!calcDataAvailable" no-wrap>
        <template v-slot:overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ $t('financial.financial_closure_not_available') }}.</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

      <b-overlay :show="!this.currentCompany.FinancialModificationYN" no-wrap>
        <template v-slot:overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">{{ $t("activitygroup.financial_student_only") }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="$emit('unmount')">{{ $t("common.back") }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

      <b-overlay :show="showConfirmOverlay" no-wrap @shown="onShown" @hidden="onHidden">
        <template v-slot:overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >
            <div v-if="!showSuccessMessage">
              <p><strong id="form-confirm-label">{{ $t('modal.steps.data_cant_be_changed') }}?</strong></p>
              <div class="d-flex">
                <b-button variant="outline-danger" class="mr-3" @click="onCancel">
                  {{ $t('common.cancel') }}
                </b-button>
                <b-button variant="outline-success" @click="handleSubmit">{{ $t('common.confirm') }}</b-button>
              </div>
            </div>
            <div v-else>
              <p><strong id="form-confirm-label">{{ $t('modal.steps.financial_close_submitted') }}</strong></p>
              <div class="d-flex">
                <b-button variant="outline-success" @click="destroy">{{ $t('common.close') }}</b-button>
              </div>
            </div>
          </div>
        </template>
      </b-overlay>

      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

            <p><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

    </b-modal>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators'
import { amountToCents } from '@/js/helpers'

export default {
  name: 'closeCompany',
  props: ['currentCompany', 'modalText'],

  data () {
    return {
      imageError: null,
      showConfirmOverlay: false,
      showSuccessMessage: false,
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      form: {
        Revenue: null,
        Expenses: null,
        Profit: null,
        LabourCost: null,
        RevenueVAT: null,
        ExpensesVAT: null,
        TotalVAT: null,
        ProfitTax: null,
        WageTax: null,
        TotalTax: null,
        Image: null,
        Custom_VAT: null
      }
    }
  },
  validations: {
    form: {
      Revenue: {
        required,
        decimal
      },
      Expenses: {
        required,
        decimal
      },
      Profit: {
        required,
        decimal
      },
      LabourCost: {
        required,
        decimal
      },
      RevenueVAT: {
        required,
        decimal
      },
      ExpensesVAT: {
        required,
        decimal
      },
      TotalVAT: {
        required,
        decimal
      },
      ProfitTax: {
        required,
        decimal
      },
      WageTax: {
        required,
        decimal
      },
      TotalTax: {
        required,
        decimal
      },
      Image: {
        required
      },
      Custom_VAT: {
        required
      }
    }
  },
  methods: {
    showModal () {
      this.$refs.stepCloseCompany.show()
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    },
    resetModal () {
      this.imageError = null
      this.showConfirmOverlay = false
      this.showSuccessMessage = false
      this.form = {
        Revenue: null,
        Expenses: null,
        Profit: null,
        LabourCost: null,
        RevenueVAT: null,
        ExpensesVAT: null,
        TotalVAT: null,
        ProfitTax: null,
        WageTax: null,
        TotalTax: null,
        Image: null,
        Custom_VAT: null
      }
      this.$v.$reset()
      this.$emit('unmount')
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    onCancel () {
      this.showConfirmOverlay = false
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.showConfirmOverlay = true
    },
    submitData () {
      const formData = new FormData()

      formData.append('ActivityGroupID', this.currentCompany.ActivityGroupID)
      formData.append('Revenue', amountToCents(this.form.Revenue))
      formData.append('Expenses', amountToCents(this.form.Expenses))
      formData.append('Profit', amountToCents(this.form.Profit))
      formData.append('LabourCost', amountToCents(this.form.LabourCost))
      formData.append('RevenueVAT', amountToCents(this.form.RevenueVAT))
      formData.append('ExpensesVAT', amountToCents(this.form.ExpensesVAT))
      formData.append('TotalVAT', amountToCents(this.form.TotalVAT))
      formData.append('ProfitTax', amountToCents(this.form.ProfitTax))
      formData.append('WageTax', amountToCents(this.form.WageTax))
      formData.append('TotalTax', amountToCents(this.form.TotalTax))
      formData.append('Image', this.form.Image)

      return this.$http.post('/php/xhr/ajax.php?function=closeActivityGroup', formData)
    },
    handleSubmit () {
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          this.showConfirmOverlay = false
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.showSuccessMessage = true
          this.processing = false
        }
      })
    },
    destroy () {
      this.showConfirmOverlay = false
      this.showSuccessMessage = false
      this.$emit('unmount')
      this.$emit('reload')
      this.$nextTick(() => {
        this.resetModal()
      })
    },
    calcProfit () {
      const clean = this.clean

      if (this.form.Revenue && this.form.Expenses) {
        const Profit = this.form.Revenue - clean(this.form.Expenses)
        this.form.Profit = clean(Profit) || 0
      } else {
        this.form.Profit = ''
      }
    },
    calcTax () {
      const clean = this.clean
      const TotalVAT = (this.form.RevenueVAT && this.form.ExpensesVAT) ? clean((this.form.RevenueVAT - this.form.ExpensesVAT) * (this.companyVAT / 100)) : null
      const ProfitTax = (this.form.Profit) ? clean(this.form.Profit * (this.companyVBP / 100)) : null
      const WageTax = (this.form.LabourCost) ? clean(this.form.LabourCost * (this.companyLH / 100)) : null
      this.form.TotalVAT = TotalVAT
      this.form.ProfitTax = ProfitTax
      this.form.WageTax = WageTax

      let TotalTax = null

      if (TotalVAT && ProfitTax && WageTax) {
        TotalTax = +TotalVAT + +ProfitTax + +WageTax
      }

      this.form.TotalTax = this.clean(TotalTax)
    },
    calcCompanyClose () {
      this.calcProfit()
      this.calcTax()
    },
    clean (number) {
      return !isNaN(number) ? parseFloat(number).toFixed(2) : null
    },
    checkImageSize () {
      if (this.form.Image === null) {
        return
      }

      const maxFileSize = 5 * 1000000 // bytes

      if (this.form.Image.size > maxFileSize) {
        console.log('file too large')
        this.imageError = this.$t('financial.incorrect_BankStatement_FileTooLarge')
        this.$refs['file-input'].reset()
      } else {
        this.imageError = null
      }
    }
  },
  mounted () {
    this.showModal()
  },
  watch: {
    form: {
      handler: function (v) {
        return this.calcCompanyClose()
      },
      deep: true
    },
    'form.Image': 'checkImageSize'
  },
  computed: {
    calcDataAvailable: function () {
      return (this.companyLH && this.companyVAT && this.companyVBP)
    },
    companyLH: function () {
      // Loonheffing?
      return parseFloat(this.currentCompany.Steps.CompanyLH).toFixed(2)
    },
    companyVAT: function () {
      // Btw percentage
      // Temporarily this is custom set in the form instead taken from DB
      // return parseFloat(this.currentCompany.Steps.CompanyVAT).toFixed(2)
      return parseFloat(this.form.Custom_VAT).toFixed(2)
    },
    companyVBP: function () {
      // Vennootschapsbelasting?
      return parseFloat(this.currentCompany.Steps.CompanyVBP).toFixed(2)
    }
  }
}
</script>

<style scoped>

</style>
