<template>
  <div>
    <b-modal
      id="change-info-modal"
      ref="change-info-modal"
      size="xl"
      :title="$t('person.add_change_info')"
      v-model="showModal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleSubmit"
      :ok-title="$t('common.save')"
      :cancel-title="$t('common.cancel')"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">

        <div :style="{ backgroundImage: 'url(' + currentPerson.Image + ')' }"
             class="account-profile--img mb-3"></div>
        {{ $t('modal.account.profile_image') }}:
        <b-form-file
          v-model="changePersonForm.Image"
          :state="validateState('Image')"
          :placeholder="$t('modal.common.choose_file_or_drop')"
          :drop-placeholder="$t('modal.common.drop_here')"
          :browse-text="$t('modal.common.choose_file')"
          accept="image/jpeg, image/png"
          class="mb-3"
        ></b-form-file>
        <b-form-text id="form-file-help-block">
          {{ $t('modal.common.max_file_size_5mb') }}
        </b-form-text>
        <b-badge v-if="imageError" variant="danger">{{ imageError }}</b-badge>

        <hr>
        <strong>{{ $t('modal.common.person_data') }}</strong>
        <hr>

        <b-form-row>
          <b-col lg="3">
            <b-form-group id="input-group-1" :label="$t('common.firstname')" label-for="input-1">
              <b-form-input
                id="input-1"
                name="input-1"
                v-model="$v.changePersonForm.Name.$model"
                :state="validateState('Name')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('modal.common.required_2_letters') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group id="input-group-2" :label="$t('common.lastname')" label-for="input-2">
              <b-form-input
                id="input-2"
                name="input-2"
                v-model="$v.changePersonForm.Name2.$model"
                :state="validateState('Name2')"
                aria-describedby="input-2-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-2-live-feedback"
              >{{ $t('modal.common.required_2_letters') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group id="input-group-3" :label="$t('common.email')" label-for="input-3">
              <b-form-input
                id="input-3"
                name="input-3"
                v-model="$v.changePersonForm.Email.$model"
                :state="validateState('Email')"
                aria-describedby="input-3-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-3-live-feedback"
              >{{ $t('modal.common.valid_email') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group id="input-group-4" :label="$t('common.phone')" label-for="input-4">
              <b-form-input
                id="input-4"
                name="input-4"
                v-model="$v.changePersonForm.Phone.$model"
                :state="validateState('Phone')"
                aria-describedby="input-4-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-4-live-feedback"
              >{{ $t('modal.common.valid_phone') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              id="input-group-5"
              :label="$t('common.dateofbirth')"
              label-for="input-5"
            >
              <b-form-input
                v-model="$v.changePersonForm.DateOfBirth.$model"
                placeholder="dd-mm-jjjj"
                v-mask="'##-##-####'"
                :state="validateState('DateOfBirth')"
                aria-describedby="input-5-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                id="input-5-live-feedback"
              >{{ $t('modal.common.valid_date_of_birth') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

        <hr>
        <strong>{{ $t('modal.common.address_data') }}</strong>
        <hr>

        <b-form-row>
          <b-col lg="2">
            <b-form-group id="input-group-7" :label="$t('common.zipcode')" label-for="input-7">
              <b-form-input
                id="input-7"
                name="input-7"
                v-model="$v.changePersonForm.Address2.$model"
                :state="validateState('Address2')"
                aria-describedby="input-7-live-feedback"
                debounce="500"
                @change="prefillAddress()"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-7-live-feedback"
              >{{ $t('modal.common.valid_zipcode') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="2">
            <b-form-group id="input-group-6" :label="$t('common.housenumber')" label-for="input-6">
              <b-form-input
                id="input-6"
                name="input-6"
                v-model="$v.changePersonForm.Address3.$model"
                :state="validateState('Address3')"
                aria-describedby="input-6-live-feedback"
                debounce="500"
                @change="prefillAddress()"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-6-live-feedback"
              >{{ $t('modal.common.valid_house_number') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group id="input-group-5" :label="$t('common.street')" label-for="input-5">
              <b-form-input
                id="input-5"
                name="input-5"
                v-model="$v.changePersonForm.Address1.$model"
                :state="validateState('Address1')"
                aria-describedby="input-5-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-5-live-feedback"
              >{{ $t('modal.common.valid_street') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group id="input-group-8" :label="$t('common.city')" label-for="input-8">
              <b-form-input
                id="input-8"
                name="input-8"
                v-model="$v.changePersonForm.Address4.$model"
                :state="validateState('Address4')"
                aria-describedby="input-8-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-8-live-feedback"
              >{{ $t('modal.common.valid_city') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

      </b-form>

      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        no-wrap
      >
      </b-overlay>

      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x"
                               class="text-danger mb-3"/>

            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error:
              {{ errorMsg }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

    </b-modal>
  </div>
</template>

<script>
import { required, minLength, email, numeric, maxLength } from 'vuelidate/lib/validators'
import getAddressAPIMixin from '../../../mixins/InternalAPIHelpers'
import { customDateRangeSelect } from '../../../../../js/helpers'
import { mask } from 'vue-the-mask'
import moment from 'moment'

export default {
  props: ['showChangePersonModal', 'currentPerson'],
  directives: { mask },
  mixins: [
    getAddressAPIMixin
  ],
  data () {
    return {
      imageError: null,
      error: false,
      loading: false,
      processing: false,
      apiError: false,
      errorMsg: null,
      showConfirmOverlay: false,
      changePersonForm: {
        Image: null,
        Name: null,
        Name2: null,
        Email: null,
        Phone: null,
        DateOfBirth: null,
        Address1: null,
        Address2: null,
        Address3: null,
        Address4: null
      }
    }
  },
  validations: {
    changePersonForm: {
      Image: {},
      Base64: {},
      Name: {
        required,
        minLength: minLength(2)
      },
      Name2: {
        required,
        minLength: minLength(2)
      },
      DateOfBirth: {
        required,
        customDateRangeSelect
      },
      Email: {
        required,
        email
      },
      Phone: {
        numeric
      },
      Address1: {
        required
      },
      Address2: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      Address3: {
        required
      },
      Address4: {
        required
      }
    }
  },
  methods: {
    resetModal () {
      this.changePersonForm = {
        Image: null,
        Name: this.currentPerson.Name,
        Name2: this.currentPerson.Name2,
        DateOfBirth: moment(this.currentPerson.DateOfBirth, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        Email: this.currentPerson.Email,
        Phone: this.currentPerson.Phone,
        Address1: this.currentPerson.Address1,
        Address2: this.currentPerson.Address2,
        Address3: this.currentPerson.Address3,
        Address4: this.currentPerson.Address4
      }
      this.processing = false

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.changePersonForm[name]
      return $dirty ? !$error : null
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.changePersonForm.$touch()
      if (this.$v.changePersonForm.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$eventHub.$emit('reloadData')
          // Hide the modal manually
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.data_changed'))
          this.$emit('reload')
          this.$emit('close')
        }
      })
    },
    submitData () {
      const formData = new FormData()

      formData.append('PersonID', this.currentPerson.PersonID)
      if (this.changePersonForm.Image && this.imageError === null) {
        formData.append('Image', this.changePersonForm.Image)
      }
      formData.append('Name', this.changePersonForm.Name)
      formData.append('Name2', this.changePersonForm.Name2)
      formData.append('DateOfBirth', moment(this.changePersonForm.DateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD'))
      formData.append('Email', this.changePersonForm.Email)
      formData.append('Phone', this.changePersonForm.Phone)
      formData.append('Address1', this.changePersonForm.Address1)
      formData.append('Address2', this.changePersonForm.Address2)
      formData.append('Address3', this.changePersonForm.Address3)
      formData.append('Address4', this.changePersonForm.Address4)
      return this.$http.post('/php/xhr/ajax.php?function=updatePerson', formData)
    },
    checkImageSize () {
      if (this.changePersonForm.Image === null) {
        this.imageError = null
        return
      }
      this.processing = true
      this.imageError = null

      const maxFileSize = 5 * 1000000 // bytes

      if (this.changePersonForm.Image.size > maxFileSize) {
        this.imageError = 'FOUT: bestand is groter dan 5MB en zal niet worden geupload'
      }
      this.processing = false
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    },
    prefillAddress () {
      if (this.changePersonForm.Address2 && this.changePersonForm.Address3) {
        this.getAddress(this.changePersonForm.Address2, this.changePersonForm.Address3).then(response => {
          if (response.data.status === 'success') {
            this.changePersonForm.Address1 = response.data.street
            this.changePersonForm.Address4 = response.data.city
          }
        })
      }
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showChangePersonModal
      },
      set () {
        this.$emit('close')
      }
    }
  },
  watch: {
    'changePersonForm.Image': 'checkImageSize'
  }
}
</script>
