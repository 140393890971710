<template>
  <div class="v-wrapper">
    <!--        <div class="debug-info">-->
    <!--            DEBUG: {{ DataTableID }}-->
    <!--        </div>-->
    <b-form-checkbox v-if="DataTableID === 'ActivityGroup'" v-model="preferOwnActivityGroupsYN"
                     name="check-button" class="mb-3" switch>
      {{ $t('common.assigned_to_me') }}
    </b-form-checkbox>
    <b-row>
      <b-col lg="6">
        <b-input-group size="sm">
          <b-form-input
            v-model="filter.search"
            type="search"
            id="filterInput"
            :placeholder="$t('datatable.type_to_search')"
            debounce="500"
          ></b-form-input>
            <b-button size="sm" text="Button" class="ml-2" variant="dark" v-b-modal.modal-1 v-if="Object.keys(this.advancedFilters).length > 0"><font-awesome-icon :icon="['fas', 'filter']" class="mr-2"/>{{ $t('common.add_filters') }}</b-button>
        </b-input-group>
      </b-col>
    </b-row>

      <b-row class="mt-2" v-if="activeAdvancedFilters">
          <b-col>
              <h5>
                  <div v-for="filter in activeAdvancedFilters" :key="filter.FieldID" class="d-inline-block">
                      <b-badge class="mr-2">
                          <b-badge variant="light">{{ filter.Name }}</b-badge>
                          <b-badge>
                              <span v-for="(item, key) in filter.Value" :key="key">
                                  <span v-if="item != null">{{ $t(`dynamic.${key}`) }} {{ formatDateToDutch(item) }} </span>
                              </span>
                              <span @click="removeFilter(filter.FieldID)" style="cursor: pointer;">
                                <font-awesome-icon :icon="['fas', 'times']" class="ml-2" style="opacity: 0.5;" />
                              </span>
                          </b-badge>
                      </b-badge>
                  </div>

                  <div v-if="Object.keys(this.activeAdvancedFilters).length" class="d-inline-block">
                          <b-badge variant="danger" @click="clearFilters" class="c-badge-button">
                              <b-badge variant="danger">Wis filters</b-badge>
                          </b-badge>
                  </div>

              </h5>
          </b-col>
      </b-row>

    <b-modal id="modal-1" title="Filters" :ok-title="$t('common.add_filters')" :cancel-title="$t('common.close')" @hide="resetAdvancedFilters" @ok="commitFilters" size="lg">
      <div v-for="filter in advancedFilters" :key="filter.FieldID">
        <h5>{{ filter.Name }}</h5>
        <b-card bg-variant="light" class="mb-3">
          <b-form-row>
            <b-col class="col-12 col-lg-6" v-for="(value, key, index) in filter.Value" :key="key">
              <b-form-group :label="$t(`dynamic.${key}`)">
              <b-form-datepicker v-if="filter.FieldDataTypeID === 'DATE_RANGE'" :id="`${key}-${filter.FieldID}`"
                                 v-model="unCommitedAdvancedFilters[filter.FieldID][key]"
                                 :max="(Object.keys(filter.Value).length === 2 && index === 0) ? unCommitedAdvancedFilters[filter.FieldID][Object.keys(filter.Value)[1]] : null"
                                 :min="(Object.keys(filter.Value).length === 2 && index === 1) ? unCommitedAdvancedFilters[filter.FieldID][Object.keys(filter.Value)[0]] : null"
                                 :placeholder="$t('modal.common.enter_date')" :locale="currentLocale.ID"
                                 reset-button :label-help="$t('modal.common.datepicker_label_help')"
                                 :label-no-date-selected="$t('modal.common.no_date_selected')"/>
              <b-form-input v-else :id="`${key}-${filter.FieldID}`"
                            v-model="unCommitedAdvancedFilters[filter.FieldID][key]"/>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-card>
      </div>
    </b-modal>

    <div v-if="error">
      <b-jumbotron bg-variant="info" text-variant="white" border-variant="dark">
        <template v-slot:header>{{ $t("error.oops") }}</template>

        <template v-slot:lead>
          {{ $t("error.datatable_retrieve_error") }}.
        </template>

      </b-jumbotron>
    </div>

    <b-table
      striped
      hover
      borderless
      responsive
      id="vue-data-table"
      ref="selectableTable"
      :items="myProvider"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :per-page="perPage"
      :current-page="currentPage"
      @row-selected="onRowSelected"
      :filter="filter"
      :filterIncludedFields="filterOn"
      selected-variant="primary"
      class="position-relative mt-2"
      table-class="text-nowrap"
      v-if="!error"
    >

      <template v-slot:cell(Actions)="row">
        <b-button :to="{ name: DataTableID, params: {ID: row.item[keyFieldID]}}">Open</b-button>
      </template>

    </b-table>

    <b-container>
      <b-row>
        <b-col class="text-danger text-center my-3" v-if="noResults">
          {{ $t("common.no_result") }}
        </b-col>
      </b-row>
    </b-container>

    <div class="text-center" v-if="loading">
      <b-spinner variant="primary" label="Large Spinner" type="grow"></b-spinner>
    </div>

    <b-row>
      <b-col lg="6">
        <div class="perpage-wrapper float-right float-lg-left mb-3">
          <span class="p-2 text-uppercase">{{ $t("datatable.per_page") }}</span>
          <b-select v-model="perPage" :options="pageSizes" class="perpage"></b-select>
        </div>
      </b-col>
      <b-col lg="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          class="float-right"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'DataTable',
  props: ['DataTableID'],
  data () {
    return {
      perPage: 10,
      pageSizes: [1, 10, 50, 100, 200],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      selectMode: 'single',
      loading: true,
      fields: [],
      items: [],
      filter: {
        search: null,
        advancedFilterObjects: {},
        forceReload: 1 // By changing the filter object the dataTable forces a refresh
      },
      advancedFilters: [],
      unCommitedAdvancedFilters: {},
      filterOn: [],
      filterFields: [],
      geselecteerd: [],
      error: false,
      noResults: false,
      totalRows: 0,
      keyFieldID: null,
      currentRoute: null,
      preferOwnActivityGroupsYN: false,
      lastContextObj: {}
    }
  },
  computed: {
    rows () {
      return this.items.length
    },
    activeAdvancedFilters () {
      return this.advancedFilters.filter((filter) => {
        if (filter.Value && typeof filter.Value === 'object') {
          return Object.values(filter.Value).some((value) => value !== null)
        }
        return false
      })
    }
  },
  methods: {
    onRowSelected (items) {
      this.geselecteerd = items
    },
    removeFilter (fieldID) {
      // Set all the values in the filter's Value object to null
      const filterToUpdate = this.filter.advancedFilterObjects[fieldID]
      for (const key in filterToUpdate) {
        filterToUpdate[key] = null
      }
    },
    clearFilters () {
      for (const filter in this.filter.advancedFilterObjects) {
        for (const key in this.filter.advancedFilterObjects[filter]) {
          this.filter.advancedFilterObjects[filter][key] = null
        }
      }
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected () {
      this.$refs.selectableTable.clearSelected()
      this.filter.search = null
    },
    toggleFilterModal () {
      this.$root.$emit('bv::show::modal', this.filterModal.id)
    },
    getData (ctx = {}) {
      this.loading = true
      const data = {
        ...ctx,
        DataTableID: this.DataTableID,
        currentPage: this.currentPage,
        perPage: this.perPage,
        filter: this.filter.search,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        preferOwnActivityGroupsYN: this.preferOwnActivityGroupsYN
      }

      if (Object.keys(this.filter.advancedFilterObjects).length > 0) {
        data.filterBy = (function cleanEmptyStrings (obj) {
          const cleanedObj = {}
          for (const [key, value] of Object.entries(obj)) {
            if (value !== null && typeof value === 'object') {
              cleanedObj[key] = cleanEmptyStrings(value) // Recursively clean nested objects
            } else {
              cleanedObj[key] = value === '' ? null : value
            }
          }
          return cleanedObj
        })(this.filter.advancedFilterObjects)
      }

      return this.$http.post('/php/xhr/ajax.php', data, { params: { function: 'getDataTable' } })
    },
    initializeAdvancedFilters () {
      this.advancedFilters.forEach((filter) => {
        if (filter.Value && typeof filter.Value === 'object') {
          const advancedFilterValues = {}
          const unCommitedAdvancedFilterValues = {}

          // Loop through the keys in the "Value" object and set them in advancedFilterValues
          for (const key in filter.Value) {
            advancedFilterValues[key] = filter.Value[key]
            unCommitedAdvancedFilterValues[key] = filter.Value[key]
          }
          this.$set(this.unCommitedAdvancedFilters, filter.FieldID, unCommitedAdvancedFilterValues)
        }
      })
    },
    emptyAdvancedFilters () {
      this.advancedFilters.forEach((filter) => {
        if (filter.Value && typeof filter.Value === 'object') {
          const unCommitedAdvancedFilterValues = {}
          for (const key in filter.Value) {
            unCommitedAdvancedFilterValues[key] = null
          }
          this.$set(this.unCommitedAdvancedFilters, filter.FieldID, unCommitedAdvancedFilterValues)
        }
      })
    },
    resetAdvancedFilters () {
      if (Object.keys(this.filter.advancedFilterObjects).length === 0) {
        this.emptyAdvancedFilters()
      } else {
        this.unCommitedAdvancedFilters = JSON.parse(JSON.stringify(this.filter.advancedFilterObjects))
      }
    },
    commitFilters () {
      this.filter.advancedFilterObjects = JSON.parse(JSON.stringify(this.unCommitedAdvancedFilters))
    },
    formatDateToDutch (inputDate) {
      const formattedDate = moment(inputDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
      return formattedDate
    },
    myProvider (ctx) {
      if (JSON.stringify(ctx) === this.lastContextObj) {
        return // context the same, don't refresh
      }
      this.lastContextObj = JSON.stringify(ctx)
      if (this.$route.path !== this.currentRoute.path) {
        // Navigating to another page triggers myProvider. This blocks redundant xhrs
        return
      }
      const promise = this.getData(ctx)
      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        if (response.data.status !== 'success') {
          this.loading = false
          this.error = true
        } else {
          // Must return an array of items or an empty array if an error occurred
          this.totalRows = response.data.construct.total
          this.loading = false
          this.advancedFilters = response.data.filters
          return response.data.items || []
        }
      })
    },
    forceRefresh () {
      this.$root.$emit('bv::refresh::table', 'vue-data-table')
    },
    forceReload () {
      this.filter.forceReload += 1
    },
    loadData () {
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const construct = response.data.construct
          this.noResults = construct.total === 0
          this.totalRows = construct.total
          this.pageSizes = construct.pageSizes
          this.filterFields = construct.filterFields
          this.advancedFilters = response.data.filters
          this.fields = response.data.fields || []
          this.keyFieldID = response.data.keyFieldID
          this.initializeAdvancedFilters()

          // Must return an array of items or an empty array if an error occurred
          this.loading = false
        }
      })
    }
  },
  watch: {
    preferOwnActivityGroupsYN: function () {
      this.forceRefresh()
    }
  },
  beforeMount () {
    this.loadData()
  },
  created () {
    this.currentRoute = this.$route
    this.$nextTick(() => {
      this.$eventHub.$on('reloadData', this.forceRefresh)
      this.$eventHub.$on('reloadFull', this.forceReload)
    })
  },
  beforeDestroy () {
    this.$eventHub.$off('reloadData')
    this.$eventHub.$off('reloadFull')
  }
}
</script>
