export default {
  namespaced: true,
  state: () => ({
    ID: null,
    ActivityGroupID: null,
    Name: null,
    Name2: null,
    DateOfBirth: null,
    Image: null,
    Email: null,
    Phone: null,
    Address1: null,
    Address2: null,
    Address3: null,
    Address4: null,
    UserGroup: null,
    UserGroupID: null,
    AvailableYN: null,
    DataTableID: null,
    ProjectInsertionAllowedYN: null,
    ActivityGroupInsertionAllowedYN: null,
    EmployeeInsertionAllowedYN: null,
    PersonInsertionAllowedYN: null,
    FirstTimeLogin: null,
    isSwitchedUser: null,
    hasImpersonationScope: null
  }),
  mutations: {
    setID (state, ID) {
      state.ID = ID
    },
    setActivityGroupID (state, ActivityGroupID) {
      state.ActivityGroupID = ActivityGroupID
    },
    setName (state, Name) {
      state.Name = Name
    },
    setName2 (state, Name2) {
      state.Name2 = Name2
    },
    setDateOfBirth (state, DateOfBirth) {
      state.DateOfBirth = DateOfBirth
    },
    setImage (state, Image) {
      state.Image = Image
    },
    setEmail (state, Email) {
      state.Email = Email
    },
    setPhone (state, Phone) {
      state.Phone = Phone
    },
    setAddress1 (state, Address1) {
      state.Address1 = Address1
    },
    setAddress2 (state, Address2) {
      state.Address2 = Address2
    },
    setAddress3 (state, Address3) {
      state.Address3 = Address3
    },
    setAddress4 (state, Address4) {
      state.Address4 = Address4
    },
    setUserGroup (state, UserGroup) {
      state.UserGroup = UserGroup
    },
    setUserGroupID (state, UserGroupID) {
      state.UserGroupID = UserGroupID
    },
    setAvailableYN (state, AvailableYN) {
      state.AvailableYN = AvailableYN
    },
    setDataTableID (state, DataTableID) {
      state.DataTableID = DataTableID
    },
    setProjectInsertionAllowedYN (state, ProjectInsertionAllowedYN) {
      state.ProjectInsertionAllowedYN = ProjectInsertionAllowedYN
    },
    setActivityGroupInsertionAllowedYN (state, ActivityGroupInsertionAllowedYN) {
      state.ActivityGroupInsertionAllowedYN = ActivityGroupInsertionAllowedYN
    },
    setEmployeeInsertionAllowedYN (state, EmployeeInsertionAllowedYN) {
      state.EmployeeInsertionAllowedYN = EmployeeInsertionAllowedYN
    },
    setPersonInsertionAllowedYN (state, PersonInsertionAllowedYN) {
      state.PersonInsertionAllowedYN = PersonInsertionAllowedYN
    },
    setFirstTimeLogin (state, FirstTimeLogin) {
      state.FirstTimeLogin = FirstTimeLogin
    },
    setIsSwitchedUser (state, isSwitchedUser) {
      state.isSwitchedUser = isSwitchedUser
    },
    setHasImpersonationScope (state, hasImpersonationScope) {
      state.hasImpersonationScope = hasImpersonationScope
    }
  },
  actions: {
    setUser (context, data) {
      context.commit('setID', data.UserID)
      context.commit('setName', data.Name)
      context.commit('setName2', data.Name2)
      context.commit('setDateOfBirth', data.DateOfBirth)
      context.commit('setImage', data.Image)
      context.commit('setEmail', data.Email)
      context.commit('setPhone', data.Phone)
      context.commit('setAddress1', data.Address1)
      context.commit('setAddress2', data.Address2)
      context.commit('setAddress3', data.Address3)
      context.commit('setAddress4', data.Address4)
      context.commit('setUserGroup', data.UserGroup)
      context.commit('setUserGroupID', data.UserGroup.UserGroupID)
      context.commit('setAvailableYN', data.AvailableYN)
      context.commit('setDataTableID', data.DataTableID)
      context.commit('setFirstTimeLogin', data.FirstTimeLogin)
      context.commit('setProjectInsertionAllowedYN', data.ProjectInsertionAllowedYN)
      context.commit('setActivityGroupInsertionAllowedYN', data.ActivityGroupInsertionAllowedYN)
      context.commit('setEmployeeInsertionAllowedYN', data.EmployeeInsertionAllowedYN)
      context.commit('setPersonInsertionAllowedYN', data.PersonInsertionAllowedYN)
      context.commit('setIsSwitchedUser', data.isSwitchedUser)
      context.commit('setHasImpersonationScope', data.hasImpersonationScope)
      if (data.ActivityGroup) {
        context.commit('setActivityGroupID', data.ActivityGroup.ActivityGroupID)
      }
    },
    setFirstTimeLogin (context, data) {
      context.commit('setFirstTimeLogin', data)
    }
  },
  getters: {
    currentUser: state => {
      return Object.assign({}, state, { fullName: state.Name + ' ' + state.Name2 })
    }
  }
}
