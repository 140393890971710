import VueI18n from 'vue-i18n'
import Vue from 'vue'
import messages from './locales'

Vue.use(VueI18n)

export default new VueI18n({
  messages,
  locale: 'nl_NL',
  fallbackLocale: 'en_US'
})
