<template>
  <b-modal id="stepUpdateBankAccount" ref="stepUpdateBankAccount" :title="$t('steps.enter_account_number')"
           @hidden="resetModal"
           @ok="handleOk"
           size="lg"
           :ok-title="$t('common.save')"
           :ok-disabled="!this.currentCompany.FinancialModificationYN"
           :cancel-title="$t('common.cancel')"
  >
    <b-container>
      <div v-html="modalText"></div>
      <b-form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-row>
          <b-col>
            <b-form-group id="input-group-1" :label="$t('modal.activitygroup.bankAccount')" label-for="input-1">
              <b-form-input
                id="input-1"
                name="input-1"
                :placeholder="$t('modal.activitygroup.bankAccount')"
                v-model="$v.changeCompanyForm.BankAccount.$model"
                @input="$v.changeCompanyForm.BankAccount.$model = ibanPrintFormat($event)"
                :state="validateState('BankAccount')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('modal.activitygroup.valid_BankAccount') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-overlay :show="showConfirmOverlay" no-wrap @shown="onShown" @hidden="onHidden">
          <template v-slot:overlay>
            <div
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="p-3"
            >
              <p><strong id="form-confirm-label">{{ $t('modal.steps.data_cant_be_changed') }}?</strong></p>
              <div class="d-flex">
                <b-button variant="outline-danger" class="mr-3" @click="onCancel">
                  {{ $t('common.back') }}
                </b-button>
                <b-button variant="outline-success" @click="handleSubmit">{{ $t('common.confirm') }}</b-button>
              </div>
            </div>
          </template>
        </b-overlay>

        <b-overlay :show="!this.currentCompany.FinancialModificationYN" no-wrap>
          <template v-slot:overlay>
            <div
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="p-3"
            >

              <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

              <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">{{ $t("activitygroup.financial_student_only") }}</strong></p>
              <div class="d-flex">
                <b-button variant="outline-danger" @click="$emit('unmount')">{{ $t('common.back') }}</b-button>
              </div>
            </div>
          </template>
        </b-overlay>

        <b-overlay :show="processing" no-wrap>
          <template v-slot:overlay v-if="apiError">
            <div
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="p-3"
            >

              <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3"/>

              <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{
                  errorMsg
                }}</strong></p>
              <div class="d-flex">
                <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
              </div>
            </div>
          </template>
        </b-overlay>

      </b-form>

    </b-container>
  </b-modal>
</template>

<script>

import { required, minLength } from 'vuelidate/lib/validators'
import { isValid as ibanValid, countries as ibanCountries, printFormat as ibanPrintFormat } from 'iban'
import { mask } from 'vue-the-mask'

export default {
  name: 'updateBankAccount',
  props: ['currentCompany', 'modalText'],
  directives: { mask },
  data () {
    return {
      showConfirmOverlay: false,
      error: false,
      processing: false,
      apiError: false,
      errorMsg: null,
      changeCompanyForm: {
        BankAccount: null
      },
      modalData: {
        PersonRecords: null
      }
    }
  },
  validations: {
    changeCompanyForm: {
      BankAccount: {
        required,
        minLength: val => {
          if (val.length < 2) return minLength(2) // Handle length === 0 consistently
          const country = ibanCountries[val.toUpperCase().substring(0, 2)] || ibanCountries.NL
          return minLength(country.length)
        },
        ibanValid: val => {
          if (!val) {
            return true
          }
          return ibanValid(val)
        }
      }
    }
  },
  methods: {
    showModal () {
      this.$refs.stepUpdateBankAccount.show()
    },
    resetModal () {
      this.showConfirmOverlay = false
      this.changeCompanyForm = {
        BankAccount: this.currentCompany.BankAccount
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$emit('unmount')
        this.$v.$reset()
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    onCancel () {
      this.showConfirmOverlay = false
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.changeCompanyForm[name]
      return $dirty ? !$error : null
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.changeCompanyForm.$touch()
      if (this.$v.changeCompanyForm.$anyError) {
        return
      }
      this.showConfirmOverlay = true
    },
    handleSubmit (bvModalEvt) {
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$emit('reload')
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.data_changed'))
          this.$emit('unmount', true)
        }
      })
    },
    submitData () {
      const xhr = 'updateActivityGroup'
      const formData = new FormData()

      formData.append('ActivityGroupID', this.currentCompany.ActivityGroupID)
      formData.append('BankAccount', this.changeCompanyForm.BankAccount)

      return this.$http.post('/php/xhr/ajax.php?function=' + xhr, formData)
    },
    ibanPrintFormat
  },
  mounted () {
    const PersonRecordsArr = this.currentCompany.Students.items.map(record => {
      const PersonRecordObj = {}
      PersonRecordObj.text = record.Name
      PersonRecordObj.value = record.PersonID
      return PersonRecordObj
    })

    this.modalData.PersonRecords = PersonRecordsArr

    this.changeCompanyForm.BankAccount = this.currentCompany.BankAccount
    this.showModal()
  }
}
</script>

<style scoped>

</style>
