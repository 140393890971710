<template>
  <div id="vue-page-wrapper">

    <div class="container-fluid" id="dashboard">
      <div class="action-bar d-md-flex flex-row py-3">
        <div class="action-bar--title flex-grow-1">
          <h4 class="text-uppercase">{{ $tc("menu.company", 2) }}</h4>
        </div>
        <div class="action-bar--actions">
          <div class="action-bar--item pl-md-2 d-inline-block" v-if="currentUser.ActivityGroupInsertionAllowedYN">
            <b-button :to="{name: 'Project'}" variant="dark"><font-awesome-icon :icon="['fas', 'level-up']" /> <span class="text-uppercase">{{ $t("company.add") }}</span></b-button>
          </div>
        </div>
      </div>

      <data-table v-bind:DataTableID="'ActivityGroup'"/>

    </div>

  </div>
</template>

<script>
import DataTable from '../components/DataTable'
import RouteModalMixin from '../components/mixins/RouteModalMixin'

export default {
  name: 'ActivityGroups',
  components: {
    DataTable
  },
  mixins: [
    RouteModalMixin
  ],
  data () {
    return {
      DataTableID: 'ActivityGroup'
    }
  },
  beforeMount () {
    // this.$store.dispatch('setContextDataTableID', DataTable)
  }
}
</script>

<style lang="scss">

</style>
