<template>
  <div id="vue-page-wrapper">

    <div class="container-fluid" id="dashboard">
      <div class="action-bar d-md-flex flex-row py-3">
        <div class="action-bar--title flex-grow-1">
          <h4>LICENTIES</h4>
        </div>
        <div class="action-bar--actions">
          <div class="action-bar--item pl-md-2 d-inline-block">
            <b-button @click="showAddProjectModal = !showAddProjectModal" variant="primary"><font-awesome-icon :icon="['fas', 'plus']" /> <span>INSCHRIJVING TOEVOEGEN</span></b-button>
          </div>
        </div>
      </div>

      <data-table :DataTableID="'Project'"/>

    </div>

    <add-project :showAddProjectModal="showAddProjectModal" v-on:close="closeAndRefresh()"></add-project>
  </div>
</template>

<script>
import DataTable from '../components/DataTable'
import AddProject from '../components/modals/Project/AddProject'

export default {
  name: 'App',
  components: {
    DataTable,
    AddProject: AddProject
  },
  data () {
    return {
      showAddProjectModal: false
    }
  },
  methods: {
    showModal (action) {
      this.$store.dispatch('modal/show', { dataTableID: this.DataTableID })
    },
    closeAndRefresh () {
      this.showAddProjectModal = false
    }
  }
}
</script>

<style lang="scss">

</style>
