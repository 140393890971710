<template>
    <b-modal id="stepCheckCompanyNameModal" ref="stepCheckCompanyNameModal" title="Bedrijfsnaam bevestigen"
             @hidden="resetModal"
             @ok="handleSubmit"
             :ok-title="$t('common.save')"
             :ok-disabled="!this.currentCompany.BaseModificationYN"
             :cancel-title="$t('common.cancel')"
    >
        <b-container>
          <div v-html="modalText"></div>
            <b-form ref="form" @submit.stop.prevent="handleSubmit">

                <b-form-row>
                    <b-col>
                        <b-form-group id="input-group-1" :label="$t('company.name')" label-for="input-1">
                            <b-form-input
                                    id="input-1"
                                    name="input-1"
                                    :placeholder="$t('company.name')"
                                    v-model="$v.changeCompanyForm.Name.$model"
                                    :state="validateState('Name')"
                                    aria-describedby="input-1-live-feedback"
                            ></b-form-input>

                            <b-form-invalid-feedback
                                    id="input-1-live-feedback"
                            >{{ $t("modal.common.required_2_letters") }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-form-row>

                <b-overlay :show="processing" no-wrap>
                    <template v-slot:overlay v-if="apiError">
                        <div
                                ref="dialog"
                                tabindex="-1"
                                role="dialog"
                                aria-modal="false"
                                aria-labelledby="form-confirm-label"
                                class="p-3"
                        >

                            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

                            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
                            <div class="d-flex">
                                <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
                            </div>
                        </div>
                    </template>
                </b-overlay>

            </b-form>

        </b-container>
    </b-modal>
</template>

<script>

import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'updateCompanyName',
  props: ['currentCompany', 'modalText'],
  data () {
    return {
      error: false,
      processing: false,
      apiError: false,
      errorMsg: null,
      changeCompanyForm: {
        Name: null
      }
    }
  },
  validations: {
    changeCompanyForm: {
      Name: {
        required,
        minLength: minLength(2)
      }
    }
  },
  methods: {
    showModal () {
      this.$refs.stepCheckCompanyNameModal.show()
    },
    resetModal () {
      this.changeCompanyForm = {
        Name: this.currentCompany.Name
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$emit('unmount')
        this.$v.$reset()
      })
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.changeCompanyForm[name]
      return $dirty ? !$error : null
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.changeCompanyForm.$touch()
      if (this.$v.changeCompanyForm.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$emit('reload')
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.data_changed'))
          this.$emit('unmount', true)
        }
      })
    },
    submitData () {
      const xhr = 'updateActivityGroup'
      const formData = new FormData()

      formData.append('ActivityGroupID', this.currentCompany.ActivityGroupID)
      formData.append('Name', this.changeCompanyForm.Name)

      return this.$http.post('/php/xhr/ajax.php?function=' + xhr, formData)
    }
  },
  mounted () {
    this.changeCompanyForm.Name = this.currentCompany.Name
    this.showModal()
  }
}
</script>

<style scoped>

</style>
