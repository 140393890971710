<template>
    <div id="vue-page-wrapper">
        <div class="container-fluid" id="dashboard">
            <div class="row">
                <div class="col-12">
                  <div class="row m-xl-5">
                    <div class="col-12">
                      <div class="mt-3 mb-5">
                        <h1>{{dashboard.Name}}</h1>
                        <div v-html="dashboard.Content"></div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h5 class="font-special">Updates</h5>
                      <div class="card mb-3" v-if="!dashboard.NewsList || Object.keys(dashboard.NewsList).length === 0">
                        <div class="card-body bg-light">
                          <p class="mb-0">{{ $t("common.no_recent_updates") }}!</p>
                        </div>
                      </div>
                      <div class="card mb-3" v-for="item in dashboard.NewsList" :key="item.NewsID" v-else>
                        <div class="card-body bg-light">
                          <div class="text-muted float-right">{{ formatDate(item.PublishDate) }}</div>
                          <h6 class="card-title mb-1">{{ item.Name }}</h6>
                          <div v-html="item.Content"></div>
                          <a :href="item.ExternalLink" target="_blank" v-if="item.ExternalLink" class="btn btn-primary text-uppercase mt-2">{{ $t("common.view") }}</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="your-company-wrapper" v-if="currentUser.UserGroup.UserGroupID === 'ST' && currentUser.ActivityGroupID != null">
                        <h5 class="font-special">{{ $t("activitygroup.your_company") }}</h5>
                        <p>{{ $t("activitygroup.your_company_introduction") }}</p>
                        <router-link to="/mijn-bedrijf" class="btn btn-primary">{{ $t("menu.my-company") }}</router-link>
                      </div>
                      <h5 class="font-special">{{ $t("common.useful_links") }}</h5>
                      <div class="row mb-5">
                        <banner v-for="banner in dashboard.BannerList" :key="banner.BannerID" :banner="banner"></banner>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
<!--        <div class="banner d-none d-xl-block">-->
<!--            <img :src="dashboard.FluidBanner.ImageResized" alt="" class="w-100">-->
<!--            <h1 class="banner-title">{{dashboard.FluidBanner.Name}}</h1>-->
<!--            <a :href="dashboard.FluidBanner.ClickOutUrl" class="stretched-link"></a>-->
<!--        </div>-->
    </div>
</template>

<script>

import Banner from '../components/banner/dashboard/banner'
import { formatDate } from '../../js/helpers'

export default {
  name: 'App',
  components: { Banner },
  data () {
    return {
      dashboard: {
        Content: null,
        MainImage: null,
        FluidBanner: {
          Image: null,
          ClickOutUrl: null
        },
        BannerList: {}
      }
    }
  },
  methods: {
    getData () {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getDashboard'
        }
      })
    },
    formatDate (date) {
      return formatDate(new Date(date), {
        short: true
      })
    }
  },
  beforeMount () {
    this.getData().then(response => {
      if (response.data.status !== 'success') {
        this.error = true
      } else {
        this.dashboard = response.data.dashboard
      }
    })
  }
}
</script>

<style lang="scss">

</style>
