<template>
  <div id="vue-page-wrapper">

    <div class="container-fluid" id="dashboard">
      <div class="action-bar d-md-flex flex-row py-3">
        <div class="action-bar--title flex-grow-1">
          <h4 class="text-uppercase">{{ $tc("menu.supervisor", 2) }}</h4>
        </div>
        <div class="action-bar--actions">
          <div class="action-bar--item pl-md-2 d-inline-block" v-if="currentUser.EmployeeInsertionAllowedYN">
            <b-button @click="showAddEmployeeModal = !showAddEmployeeModal" variant="primary"><font-awesome-icon :icon="['fas', 'plus']" /> <span class="text-uppercase">{{ $t("employee.add") }}</span></b-button>
          </div>
        </div>
      </div>
      <data-table v-bind:DataTableID="'Employee'"/>

    </div>

    <add-employee :showAddEmployeeModal="showAddEmployeeModal" v-on:close="showAddEmployeeModal = false"></add-employee>
  </div>
</template>

<script>
import DataTable from '../components/DataTable'
import AddEmployee from '../components/modals/Employee/AddEmployee'
import RouteModalMixin from '../components/mixins/RouteModalMixin'

export default {
  name: 'App',
  components: {
    DataTable,
    AddEmployee
  },
  mixins: [
    RouteModalMixin
  ],
  data () {
    return {
      DataTableID: 'Employee',
      showAddEmployeeModal: false
    }
  }
}
</script>

<style lang="scss">

</style>
