<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <b-overlay
                :show="visible"
                no-wrap
                fixed
        >
            <template v-slot:overlay>
                <div
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                        class="p-3 first-time-login-component"
                >
                    <transition name="slide-fade" mode="out-in">
                        <component :is="contentComponent" @stepper="stepper" @update="update" />
                    </transition>
                </div>
            </template>

        </b-overlay>
        <img src="/assets/dev-assets/JO_logo_ORANGE.png"
             class="first-time-login-logo" alt="" v-show="currentStep === 1">
    </div>

</template>

<script>

import FirstTimeLoginStep1 from './steps/Step1'
import FirstTimeLoginStep2 from './steps/Step2'
import FirstTimeLoginStep3 from './steps/Step3'

export default {
  name: 'FirstTimeLogin',
  components: {
    FirstTimeLoginStep1,
    FirstTimeLoginStep2,
    FirstTimeLoginStep3
  },
  data () {
    return {
      currentStep: 1
    }
  },
  methods: {
    stepper (step) {
      this.currentStep += step
    },
    close () {
      this.$store.dispatch('user/setFirstTimeLogin', false)
    },
    update () {
      this.$emit('update')
    }
  },
  computed: {
    visible: {
      get () {
        return this.$store.state.firstTimeLogin.visible
      },
      set (value) {
        if (value === false) {
          this.$store.dispatch('firstTimeLogin/hide')
        }
      }
    },
    contentComponent () {
      return 'FirstTimeLoginStep' + this.currentStep
    }
  }
}
</script>
