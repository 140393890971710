export default {
  namespaced: true,
  state: () => ({
    Options: {
      nl_NL: {
        name: 'Nederlands',
        icon: 'flag-icon-nl',
        value: 'nl_NL',
        ID: 'NL'
      },
      en_GB: {
        name: 'English',
        icon: 'flag-icon-gb',
        value: 'en_GB',
        ID: 'EN'
      }
    },
    Locale: {
      name: 'Nederlands',
      icon: 'flag-icon-nl',
      value: 'nl_NL',
      ID: 'NL'
    }
  }),
  mutations: {
    setLocale (state, Locale) {
      state.Locale = Locale
    }
  },
  actions: {
    setLocale (context, data) {
      context.commit('setLocale', this.state.language.Options[data])
    }
  },
  getters: {
    currentLocale: state => state.Locale,
    localeOptions: state => state.Options
  }
}
