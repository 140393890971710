<template>
  <div id="vue-page-wrapper">

    <div class="container-fluid" id="dashboard">
      <div class="action-bar d-md-flex flex-row py-3">
        <div class="action-bar--title flex-grow-1">
          <h4>MIJN SCHOLEN</h4>
        </div>
        <div class="action-bar--actions">
          <div class="action-bar--item d-inline-block">
            <a href="#" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'plus']" /> <span>SCHOOL TOEVOEGEN</span></a>
          </div>
          <div class="action-bar--item pl-md-2 d-inline-block">
            <a href="#" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'trash']" /> <span>SCHOOL VERWIJDEREN</span></a>
          </div>
        </div>
      </div>

      <data-table v-bind:DataTableID="'Customer'"/>

    </div>

  </div>
</template>

<script>
import DataTable from '../components/DataTable'

export default {
  name: 'App',
  components: {
    DataTable
  }
}
</script>

<style lang="scss">

</style>
