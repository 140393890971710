import { render, staticRenderFns } from "./updateCompanyName.vue?vue&type=template&id=2a6df110&scoped=true&"
import script from "./updateCompanyName.vue?vue&type=script&lang=js&"
export * from "./updateCompanyName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6df110",
  null
  
)

export default component.exports