const MenuArray = [
  {
    name: 'mainMenu',
    CO: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'Begeleiders',
        external: false,
        to: 'EmployeeOverview',
        translation: 'menu.supervisor',
        plural: true,
        icon: 'chalkboard-teacher'
      },
      {
        name: 'Companies',
        external: false,
        to: 'ActivityGroupOverview',
        translation: 'menu.company',
        plural: true,
        icon: 'user-friends'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    ADMINISTRATOR: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'Inschrijvingen',
        external: false,
        to: 'ProjectOverview',
        translation: 'menu.registration',
        plural: true,
        icon: 'pencil'
      },
      {
        name: 'Begeleiders',
        external: false,
        to: 'EmployeeOverview',
        translation: 'menu.supervisor',
        plural: true,
        icon: 'chalkboard-teacher'
      },
      {
        name: 'Studenten',
        external: false,
        to: 'PersonOverview',
        translation: 'menu.student',
        plural: true,
        icon: 'user-graduate'
      },
      {
        name: 'Companies',
        external: false,
        to: 'ActivityGroupOverview',
        translation: 'menu.company',
        plural: true,
        icon: 'user-friends'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    RA: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'Studenten',
        external: false,
        to: 'PersonOverview',
        translation: 'menu.student',
        plural: true,
        icon: 'user-graduate'
      },
      {
        name: 'Inschrijvingen',
        external: false,
        to: 'ProjectOverview',
        translation: 'menu.registration',
        plural: true,
        icon: 'pencil'
      },
      {
        name: 'Begeleiders',
        external: false,
        to: 'EmployeeOverview',
        translation: 'menu.supervisor',
        plural: true,
        icon: 'chalkboard-teacher'
      },
      {
        name: 'Companies',
        external: false,
        to: 'ActivityGroupOverview',
        translation: 'menu.company',
        plural: true,
        icon: 'user-friends'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    ST: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'My company',
        external: false,
        to: 'MyCompanyOverview',
        translation: 'menu.my-company',
        plural: false,
        icon: 'building'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    CS: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'My company',
        external: false,
        to: 'MyCompanyOverview',
        translation: 'menu.my-company',
        plural: false,
        icon: 'building'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    FS: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'My company',
        external: false,
        to: 'MyCompanyOverview',
        translation: 'menu.my-company',
        plural: false,
        icon: 'building'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    CD: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'Studenten',
        external: false,
        to: 'PersonOverview',
        translation: 'menu.student',
        plural: true,
        icon: 'user-graduate'
      },
      {
        name: 'Inschrijvingen',
        external: false,
        to: 'ProjectOverview',
        translation: 'menu.registration',
        plural: true,
        icon: 'pencil'
      },
      {
        name: 'Begeleiders',
        external: false,
        to: 'EmployeeOverview',
        translation: 'menu.supervisor',
        plural: true,
        icon: 'chalkboard-teacher'
      },
      {
        name: 'Companies',
        external: false,
        to: 'ActivityGroupOverview',
        translation: 'menu.company',
        plural: true,
        icon: 'user-friends'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    BD: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'Inschrijvingen',
        external: false,
        to: 'ProjectOverview',
        translation: 'menu.registration',
        plural: true,
        icon: 'pencil'
      },
      {
        name: 'Studenten',
        external: false,
        to: 'PersonOverview',
        translation: 'menu.student',
        plural: true,
        icon: 'user-graduate'
      },
      {
        name: 'Companies',
        external: false,
        to: 'ActivityGroupOverview',
        translation: 'menu.company',
        plural: true,
        icon: 'user-friends'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    BL: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'Companies',
        external: false,
        to: 'ActivityGroupOverview',
        translation: 'menu.company',
        plural: true,
        icon: 'user-friends'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }],
    FL: [
      {
        name: 'Dashboard',
        external: false,
        to: 'Dashboard',
        translation: 'menu.dashboard',
        plural: true,
        icon: 'th-large'
      },
      {
        name: 'Companies',
        external: false,
        to: 'ActivityGroupOverview',
        translation: 'menu.company',
        plural: true,
        icon: 'user-friends'
      },
      {
        name: 'ThreeShips',
        external: true,
        to: 'ThreeShips',
        translation: 'menu.program',
        plural: false,
        icon: 'book-reader'
      },
      {
        name: 'FAQ',
        external: false,
        to: 'FAQ',
        translation: 'menu.faq',
        plural: false,
        icon: 'question-circle'
      }]
  },
  {
    name: 'sideMenu',
    ST: [{
      name: 'ThreeShips',

      to: 'ThreeShips',
      translation: 'menu.program',
      plural: false
    }],
    RA: [
      {
        name: 'Begeleiders',
        external: false,
        to: 'EmployeeOverview',
        translation: 'menu.supervisor',
        plural: true
      }
    ]
  }
]

export default MenuArray
