<template>
  <div id="vue-page-wrapper">

    <div class="container-fluid" id="dashboard">
      <div class="action-bar d-md-flex flex-row py-3">
        <div class="action-bar--title flex-grow-1">
          <h4 class="text-uppercase">{{ $tc("menu.student", 2) }}</h4>
        </div>
        <div class="action-bar--actions">
          <div class="action-bar--item pl-md-2 d-inline-block" v-if="currentUser.PersonInsertionAllowedYN">
            <b-button :to="{name: 'Project'}" variant="dark"><font-awesome-icon :icon="['fas', 'level-up']" /> <span class="text-uppercase">{{ $t("person.link_students") }}</span></b-button>
          </div>
          <div class="action-bar--item pl-md-2 d-inline-block" v-if="currentUser.PersonInsertionAllowedYN">
            <b-button @click="showAddPersonModal = !showAddPersonModal" variant="primary"><font-awesome-icon :icon="['fas', 'plus']" /> <span class="text-uppercase">{{ $t("person.add") }}</span></b-button>
          </div>
          <div class="action-bar--item pl-md-2 d-inline-block" v-if="currentUser.PersonInsertionAllowedYN">
            <b-button @click="showImportPersonsModal = !showImportPersonsModal" variant="primary"><font-awesome-icon :icon="['fas', 'file-import']" /> <span class="text-uppercase">{{ $t("person.import") }}</span></b-button>
          </div>
        </div>
      </div>

      <data-table v-bind:DataTableID="'Person'"/>

    </div>

    <add-person :showAddPersonModal="showAddPersonModal" v-on:close="showAddPersonModal = false"></add-person>

    <import-persons :showImportPersonsModal="showImportPersonsModal" v-on:close="showImportPersonsModal = false"></import-persons>

  </div>
</template>

<script>
import DataTable from '../components/DataTable'
import AddPerson from '../components/modals/Person/AddPerson'
import ImportPersons from '../components/modals/Person/ImportPersons'
import RouteModalMixin from '../components/mixins/RouteModalMixin'

export default {
  name: 'App',
  components: {
    DataTable,
    AddPerson,
    ImportPersons
  },
  mixins: [
    RouteModalMixin
  ],
  data () {
    return {
      DataTableID: 'Person',
      showAddPersonModal: false,
      showImportPersonsModal: false
    }
  }
}
</script>

<style lang="scss">

</style>
