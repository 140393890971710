import Vue from 'vue'
import Vuelidate from 'vuelidate'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import App from './App'
import axios from 'axios'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters } from 'vuex'
import i18n from './i18n'
import VueTheMask from 'vue-the-mask'

library.add(fas)

// General Event Hub for sibling communication
Vue.prototype.$eventHub = new Vue()

Vue.component('font-awesome-icon', FontAwesomeIcon)

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
  Vue.config.productionTip = false
}

require('@/scss/main.scss')
require('flag-icon-css/sass/flag-icon.scss')

Vue.use(BootstrapVue)

axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

Vue.prototype.$http = axios.create({
  // baseURL: process.env.VUE_APP_API_URL
})

Vue.use(Vuelidate)

Vue.use(VueTheMask)

Vue.mixin({
  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('language', ['currentLocale'])
  },
  methods: {
    makeToast: function (variant = null, title, content, options = {}) {
      this.$bvToast.toast(content, {
        title: title,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        ...options
      })
    }
  }
})

if (document.getElementById('app')) {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}
