<template>

    <b-form @submit.stop.prevent="handleSubmit" v-if="show" class="firstTimeLoginStep">
      <h1>{{ $t("login.choose_a_password") }}</h1>
      <b-form-group
                id="input-group-1"
                :label="$t('login.password')"
                label-for="input-1"
        >
            <b-form-input
                    id="input-1"
                    v-model="$v.form.Password.$model"
                    :state="validateState('Password')"
                    aria-describedby="input-1-live-feedback"
                    :placeholder="$t('login.preferred_password')"
                    type="password"
            ></b-form-input>
        </b-form-group>
        <p>
            <small v-if="!(validateState('Password', 'minLengthReached'))" class="text-danger"><font-awesome-icon :icon="['fas', 'times']" /> {{ $t("modal.common.required_8_characters") }}</small>
            <small v-else class="text-success"><font-awesome-icon :icon="['fas', 'check']" /> {{ $t("modal.common.required_8_characters") }}</small><br>

            <small v-if="!(validateState('Password', 'containsUppercase'))" class="text-danger"><font-awesome-icon :icon="['fas', 'times']" /> {{ $t("modal.common.required_1_capital_letter") }}</small>
            <small v-else class="text-success"><font-awesome-icon :icon="['fas', 'check']" /> {{ $t("modal.common.required_1_capital_letter") }}</small><br>

            <small v-if="!(validateState('Password', 'containsLowercase'))" class="text-danger"><font-awesome-icon :icon="['fas', 'times']" /> {{ $t("modal.common.required_1_small_letter") }}</small>
            <small v-else class="text-success"><font-awesome-icon :icon="['fas', 'check']" /> {{ $t("modal.common.required_1_small_letter") }}</small><br>

            <small v-if="!(validateState('Password', 'containsNumber'))" class="text-danger"><font-awesome-icon :icon="['fas', 'times']" /> {{ $t("modal.common.required_1_number") }}</small>
            <small v-else class="text-success"><font-awesome-icon :icon="['fas', 'check']" /> {{ $t("modal.common.required_1_number") }}</small><br>

            <small v-if="!(validateState('Password', 'containsSpecial'))" class="text-danger"><font-awesome-icon :icon="['fas', 'times']" /> {{ $t("modal.common.required_1_special_character") }}</small>
            <small v-else class="text-success"><font-awesome-icon :icon="['fas', 'check']" /> {{ $t("modal.common.required_1_special_character") }}</small>
        </p>
        <b-form-group id="input-group-2" :label="$t('login.confirm_password')" label-for="input-2">
            <b-form-input
                    id="input-2"
                    v-model="$v.form.PasswordCheck.$model"
                    :state="validateState('PasswordCheck')"
                    aria-describedby="input-2-live-feedback"
                    :placeholder="$t('login.confirm_password')"
                    type="password"
                    @keydown.native="handleEnter"
            ></b-form-input>
            <b-form-invalid-feedback
                    id="input-2-live-feedback"
            >{{ $t("modal.common.error_passwords_mismatch") }}</b-form-invalid-feedback>
        </b-form-group>

        <b-btn @click="handleSubmit" variant="outline-primary">{{ $t("common.save") }}</b-btn>

        <b-overlay :show="processing" no-wrap>
            <template v-slot:overlay v-if="apiError">
                <div
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                        class="p-3"
                >

                    <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

                    <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
                    <div class="d-flex">
                        <b-button variant="outline-danger" @click="cancelProcessing">{{ $t("common.back") }}</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>

    </b-form>

</template>

<script>

import { required, sameAs } from 'vuelidate/lib/validators'
// const containsSpecialChar = helpers.regex('containsSpecialChar', /[!@#$%^&*(),.?":{}|<>]/)
// const containsNumber = helpers.regex('containsNumber', /[0-9]/)
// const containsLowerCase = helpers.regex('containsLowerCase', /[a-z]/)
// const containsUpperCase = helpers.regex('containsUpperCase', /[A-Z]/)
export default {
  name: 'FirstTimeLoginStep2',
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      form: {
        Password: '',
        PasswordCheck: ''
      },
      show: true
    }
  },
  validations: {
    form: {
      Password: {
        minLengthReached: function (value) {
          return /.{8,}/.test(value)
        },
        containsUppercase: function (value) {
          return /[A-Z]/.test(value)
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value)
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value)
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-]/.test(value)
        }
      },
      PasswordCheck: {
        required,
        sameAs: sameAs('Password')
      }
    }
  },
  methods: {
    validateState (name, field) {
      const { $dirty, $error } = this.$v.form[name]
      if (field) {
        return this.$v.form[name][field]
      }
      return $dirty ? !$error : null
    },
    nextStep () {
      this.$emit('stepper', 1)
    },
    handleEnter (event) {
      if (event.which === 13) {
        this.handleSubmit()
      }
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      if (bvModalEvt) {
        bvModalEvt.preventDefault()
      }
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.nextStep()
        }
      })
    },
    submitData () {
      const formData = new FormData()
      formData.append('DataTableID', this.currentUser.DataTableID)
      formData.append('ID', this.currentUser.ID)
      formData.append('Password', this.form.Password)
      return this.$http.post('/php/xhr/ajax.php?function=setPassword', formData)
    }
  }
}
</script>
