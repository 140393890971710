<template>
    <b-modal id="stepAddBusinessPlanModal" ref="stepAddBusinessPlanModal" :title="$t('steps.add_businessplan')"
             @ok="handleOk"
             @hidden="resetModal"
             size="lg"
             :ok-title="$t('common.save')"
             :ok-disabled="!this.currentCompany.BaseModificationYN"
             :cancel-title="$t('common.cancel')"
    >
      <div class="my-5"></div>
      <div v-html="modalText"></div>
      <b-form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-group id="input-group-6">
          <b-form-checkbox-group
            v-model="changeCompanyForm.BusinessPlanIsAdded"
            id="input-6"
            :state="validateState('BusinessPlanIsAdded')"
            aria-describedby="input-6-live-feedback"
          >
            <b-form-checkbox value="1">{{ $t('modal.steps.uploaded_in_threeships') }}</b-form-checkbox>
          </b-form-checkbox-group>
          <b-form-invalid-feedback
            id="input-6-live-feedback"
          >{{ $t('not_yet_uploaded') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-overlay :show="showConfirmOverlay" no-wrap @shown="onShown" @hidden="onHidden">
          <template v-slot:overlay>
            <div
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="p-3"
            >
              <p><strong id="form-confirm-label">{{ $t('modal.steps.data_cant_be_changed') }}?</strong></p>
              <div class="d-flex">
                <b-button variant="outline-danger" class="mr-3" @click="onCancel">
                  {{ $t('common.cancel') }}
                </b-button>
                <b-button variant="outline-success" @click="handleSubmit">{{ $t('common.confirm') }}</b-button>
              </div>
            </div>
          </template>
        </b-overlay>

        <b-overlay :show="processing" no-wrap>
          <template v-slot:overlay v-if="apiError">
            <div
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="p-3"
            >

              <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

              <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
              <div class="d-flex">
                <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
              </div>
            </div>
          </template>
        </b-overlay>

      </b-form>
    </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'businessPlanIsAdded',
  props: ['currentCompany', 'modalText'],

  data () {
    return {
      showConfirmOverlay: false,
      error: false,
      processing: false,
      apiError: false,
      errorMsg: null,
      changeCompanyForm: {
        BusinessPlanIsAdded: []
      }
    }
  },
  validations: {
    changeCompanyForm: {
      BusinessPlanIsAdded: {
        required
      }
    }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.changeCompanyForm[name]
      return $dirty ? !$error : null
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    onCancel () {
      this.showConfirmOverlay = false
    },
    showModal () {
      this.$refs.stepAddBusinessPlanModal.show()
    },
    resetModal () {
      this.showConfirmOverlay = false
      this.changeCompanyForm = {
        BusinessPlanIsAdded: []
      }
      this.$nextTick(() => {
        this.$emit('unmount', true)
      })
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.changeCompanyForm.$touch()
      if (this.$v.changeCompanyForm.$anyError) {
        return
      }
      this.showConfirmOverlay = true
    },
    handleSubmit (bvModalEvt) {
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$emit('reload')
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.data_changed'))
          this.$emit('unmount', true)
        }
      })
    },
    submitData () {
      const xhr = 'updateActivityGroup'
      const formData = new FormData()

      formData.append('ActivityGroupID', this.currentCompany.ActivityGroupID)
      formData.append('BusinessPlanIsAdded', true)

      return this.$http.post('/php/xhr/ajax.php?function=' + xhr, formData)
    }
  },
  mounted () {
    this.showModal()
  }
}
</script>

<style scoped>

</style>
