<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
        <b-jumbotron>
            <template v-slot:header>{{ $t('common.no_data') }}</template>

            <hr class="my-4">

            <p>
                {{ $t('error.datatable_retrieve_error_short') }}
            </p>

        </b-jumbotron>
</template>

<script>

export default {
  data () {
    return {}
  }
}
</script>
