import store from '../vue/store'
import moment from 'moment'

/**
 * Prices are submitted as integers
 *
 * @param price
 * @param config
 * @returns {*}
 */
export const formatCurrency = (price, config = {}) => {
  price /= 100

  const options = {}

  if (config.useCurrency !== false) {
    options.style = 'currency'
    options.currency = config.currency || 'EUR'
    options.currencyDisplay = 'symbol'

    if (config.useSymbol === false) {
      options.currencyDisplay = 'code'
    }
  } else {
    options.minimumFractionDigits = 2
  }

  return new Intl.NumberFormat(store.getters.locale || 'nl-NL', options).format(price)
}

export const getNumberData = () => {
  const res = {
    decimal: '.',
    thousands: ''
  }

  // convert a number formatted according to locale
  const str = parseFloat('1234.56').toLocaleString(store.getters.locale || 'nl-NL')
  if (!str.match('1')) { return res }

  // get decimal and thousand separators
  res.decimal = str.replace(/.*4(.*)5.*/, '$1')
  res.thousands = str.replace(/.*1(.*)2.*/, '$1')

  return res
}

export const currencySymbol = currency => {
  return {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫' // Vietnamese Dong
  }[currency] || currency
}

export const formatDate = (date, options = {}) => {
  const currentLocale = store.getters['language/currentLocale'].ID
  if (options.short) {
    return date.toLocaleDateString(currentLocale, {
      weekday: options.weekDay === false ? undefined : 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
  } else if (options.long) {
    return date.toLocaleDateString(currentLocale, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  } else {
    return date.toLocaleDateString(currentLocale)
  }
}
export const formatTime = date => date.toLocaleTimeString(store.getters.locale)
export const formatDateTime = date => `${formatDate(date)} ${formatTime(date)}`

export const capitalize = val => {
  if (!val) return ''
  val = val.toString()
  return val.charAt(0).toUpperCase() + val.slice(1)
}

export const amountToCents = amount => { return Math.round(amount * 100) }

export const customDateRangeSelect = (value) => moment(value, 'DD/MM/YYYY').isBefore(moment().subtract(11, 'years')) && moment(value, 'DD/MM/YYYY').isAfter(moment('01/01/1800', 'DD/MM/YYYY'))
