<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-modal
      id="impersonation-modal"
      ref="modal"
      size="lg"
      title="Account overnemen"
      v-model="showModal"
      @show="initModal"
      @hidden="resetModal"
      @ok="handleSubmit"
      @close="emitClose"
      @cancel="emitClose"
      ok-title="Overnemen"
      :cancel-title="$t('common.cancel')"
      :ok-disabled="processing || loading"
    >
      <p>Hiermee log je in met een account naar keuze.</p>

      <b-form ref="form" @submit.stop.prevent="handleSubmit">

        <b-form-group
          id="input-group-1"
          :label="$t('modal.account.choose')"
          label-for="input-1"
          :state="this.form.AccountRecord.value !== null"
        >

          <b-dropdown
            size="sm"
            :variant="this.form.AccountRecord.value !== null ? 'outline-secondary' : 'outline-danger'"
            block
            menu-class="w-100"
            ref="dropdown"
          >
            <template v-slot:button-content>
              <font-awesome-icon :icon="['fas', 'user-circle']"/>
              {{ form.AccountRecord.name }}
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                label-for="tag-search-input"
                label="Zoek accounts"
                label-cols-md="auto"
                class="mb-0"
                label-size="sm"
                :description="searchDesc"
              >
                <b-form-input
                  v-model="search"
                  id="tag-search-input"
                  type="search"
                  size="sm"
                  autocomplete="off"
                  debounce="500"
                  class="w-75 d-inline-block"
                ></b-form-input>
                <b-button @click="$refs.dropdown.hide(true)" variant="outline-secondary"
                          class="d-inline-block float-right"><i class="fas fa-check"></i></b-button>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>

            <p class="px-3 mb-0 text-info">
              <small>{{ $t('modal.common.max_result_helper') }}</small>
            </p>

            <b-list-group class="p-3 overflow-auto form-tags--data-list">
              <b-list-group-item
                v-for="(option, index) in availableOptions"
                :key="index"
                @click="onOptionClick({ option })" href="#"
              >
                {{ option.Name }} {{ option.Name2 }} <span v-if="option.Role" class="text-info">- {{ option.Role }}</span> <span
                v-if="option.HasCompanyYN == 1">| <small><font-awesome-icon
                :icon="['fas', 'link']"/> {{ option.Location.Name }}</small></span>
              </b-list-group-item>
            </b-list-group>

            <b-dropdown-text v-if="availableOptions.length === 0">
              {{ $t('customer.no_result') }}
            </b-dropdown-text>
          </b-dropdown>
        </b-form-group>

      </b-form>

      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        no-wrap
      >
      </b-overlay>

      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3"/>

            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{
                errorMsg
              }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">Terug</b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['showImpersonationModal'],
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      options: [],
      search: '',
      form: {
        AccountRecord: {
          name: this.$t('modal.account.choose'),
          DataTableID: null,
          value: null
        }
      },
      modalData: {
        StandardText: {
          Title: '',
          Content: ''
        }
      }
    }
  },
  validations: {
    form: {
      AccountRecord: {
        value: {
          required
        }
      }
    }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    initModal () {
      this.resetModal()
      this.loadAccounts()
    },
    startImpersonation () {
      console.log('switching to an other account')
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'impersonation',
          DataTableID: this.form.AccountRecord.DataTableID,
          ID: this.form.AccountRecord.value
        }
      })
    },
    resetModal () {
      this.form = {
        AccountRecord: {
          name: this.$t('modal.account.choose'),
          DataTableID: null,
          value: null
        }
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    emitClose () {
      this.$emit('close')
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.startImpersonation().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$emit('reload')
          this.emitClose()
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.impersonated'))
        }
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    getData (params) {
      return this.$http.get('/php/xhr/ajax.php', {
        params
      })
    },
    loadAccounts () {
      this.getData({
        function: 'getImpersonationList',
        filter: this.criteria
      }).then(response => {
        const dataObj = response.data
        this.options = dataObj.ShortList.items
        this.loading = false
      })
    },
    onOptionClick ({ option }) {
      this.form.AccountRecord = {
        name: option.Name + ' ' + option.Name2,
        DataTableID: option.DataTableID,
        value: option.RecordID
      }
      this.search = ''
      this.$refs.dropdown.hide(true)
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showImpersonationModal
      },
      set () {
        this.$emit('close')
      }
    },
    criteria () {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions () {
      const options = this.options.filter(opt => this.form.AccountRecord.value !== opt.CustomerID)
      // Show all options available
      return options
    },
    searchDesc () {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'Er zijn geen studenten die aan uw zoekopdracht voldoen.'
      }
      return ''
    }
  },
  watch: {
    search: 'loadAccounts'
  }
}
</script>
