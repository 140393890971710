const SocketMixin = {
  data () {
    return {
      socket: null,
      showSocketDetailsModal: false,
      errorCollection: null
    }
  },
  methods: {
    newSocket (connect, subscribeData) {
      const self = this
      const CE = this.$createElement
      let toastContent = CE(
        'p',
        [
          CE('b-spinner', { props: { small: true } }),
          ` ${this.$t('toasts.background_task_started')}`
        ]
      )
      this.makeToast('warning', this.$t('toasts.background_task_started'), toastContent, { noAutoHide: true, id: 'task-running-toast' })

      this.$nextTick(() => {
        this.$bvToast.hide('task-running-toast')
      })
      this.socket = window.io.connect(connect)
      if (Array.isArray(subscribeData)) {
        subscribeData.forEach(data =>
          this.socket.emit('subscribe', {
            ...data
          })
        )
      } else {
        this.socket.emit('subscribe', {
          ...subscribeData
        })
      }

      this.socket.on('message', function (message) {
        const resp = message.data

        switch (resp.status) {
          case 'success':
            self.makeToast('success', self.$t('common.success'), resp.message)
            self.$bvToast.hide('task-running-toast')
            self.$eventHub.$emit('reloadModalData')
            self.$eventHub.$emit('reloadData')
            delete self.socket.$events.message
            break
          case 'failed': // There where errors but the task completed.
            console.log(resp)
            self.errorCollection = {
              errorData: resp.errorData,
              errorFields: resp.fields,
              progressError: resp.progressError,
              progressRemainder: resp.progressRemainder,
              progressSuccess: resp.progressSuccess,
              progressTotal: resp.progressTotal
            }
            toastContent = CE(
              'p',
              [
                ` ${resp.message}`,
                CE('br'),
                CE('br'),
                CE('b-btn',
                  {
                    on: {
                      click: function () {
                        self.showSocketDetailsModal = true
                        self.$bvToast.hide('results-toast')
                      }
                    }
                  },
                  self.$t('toasts.see_rapport'))
              ]
            )
            var date = new Date()
            var id = 'results-toast-' + date.getMinutes() + '-' + date.getSeconds()
            self.makeToast('info', self.$t('toasts.failed'), toastContent, { noAutoHide: true, id: id })
            self.$bvToast.hide('task-running-toast')
            delete self.socket.$events.message
            break
          case 'error': // The task encountered a hard error and could not run
            toastContent = CE(
              'p',
              [
                ` ${resp.message}`
              ]
            )
            self.makeToast('danger', self.$t('toasts.error'), toastContent)
            self.$bvToast.hide('task-running-toast')
            delete self.socket.$events.message
            break
        }
      })
      return true
    }
  }
}

export default SocketMixin
