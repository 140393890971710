<template>
  <div id="vue-page-wrapper">

    <div class="container-fluid" id="dashboard">
      <div class="action-bar d-md-flex flex-row py-3">
        <div class="action-bar--title flex-grow-1">
          <h4>{{ Title }}</h4>
        </div>
        <div class="action-bar--actions">
          <div class="action-bar--item pl-md-2 d-inline-block">
            <a href="mailto:info@jongondernemen.nl" class="btn btn-primary"><font-awesome-icon :icon="['fas', 'envelope']" /> <span>{{ $t("faq.contact_us") }}</span></a>
          </div>
        </div>
      </div>
      <div role="tablist">

        <b-card no-body class="mb-1" v-for="(item, index) in FAQItems" :key="index">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'accordion-' + index" variant="light"><h5 class="mb-0 text-dark">{{ item.Name }}</h5></b-button>
          </b-card-header>
          <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text><div v-html="item.Content"></div></b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'FAQ',
  data () {
    return {
      FAQItems: [],
      Title: ''
    }
  },
  methods: {
    loadData () {
      this.getData().then(response => {
        this.FAQItems = response.data.items
        this.Title = response.data.title
      })
    },
    getData () {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getFAQ'
        }
      })
    }
  },
  mounted () {
    this.loadData()
    console.log('Loaded faq')
  }
}
</script>
