<template>
    <transition name="slide-fade" mode="out-in">
        <b-modal v-model="visible" hide-header hide-footer ref="large-side-modal"
                 no-fade id="side-modal" title="Details"
                 @hide="closeAllModals"
        >
            <div class="custom-modal-header">
                <button type="button" @click="$router.back()" aria-label="Close" class="close">×</button>
            </div>
            <div id="company-modal">
                <div class="scroll-wrapper">
                    <div class="container-fluid">
                        <component :is="contentComponent"/>
                    </div>
                </div>
            </div>
        </b-modal>
    </transition>
</template>

<script>
import NoResult from './modals/NoResult'
import CustomerDetails from './modals/ActivityGroup/ActivityGroupDetails'
import ProjectDetails from './modals/Project/ProjectDetails'
import PersonDetails from './modals/Person/PersonDetails'
import EmployeeDetails from './modals/Employee/EmployeeDetails'

export default {
  name: 'LargeSideModal',
  props: ['currentItem'],
  components: {
    NoResult,
    CustomerDetails,
    ProjectDetails,
    PersonDetails,
    EmployeeDetails
  },
  data () {
    return {}
  },
  methods: {
    closeAllModals () {
      const DataTableID = this.$route.matched.find(r => typeof r.meta.DataTableID !== 'undefined').meta.DataTableID
      this.$router.push({ name: `${DataTableID}Overview` })
    }
  },
  computed: {
    visible: {
      get () {
        return this.$store.state.modal.visible
      },
      set () {
        this.$store.dispatch('modal/hide')
      }
    },
    contentComponent () {
      switch (this.$store.state.modal.dataTableID) {
        case 'Customer':
          return 'CustomerDetails'
        case 'Project':
          return 'ProjectDetails'
        case 'Person':
          return 'PersonDetails'
        case 'Employee':
          return 'EmployeeDetails'
        case 'ActivityGroup':
          return 'CustomerDetails'
        default:
          return 'NoResult'
      }
    }
  }
}
</script>
