<template>
  <div id="vue-page-wrapper">

    <div class="container-fluid" id="dashboard">
      <div class="action-bar d-md-flex flex-row py-3">
        <div class="action-bar--title flex-grow-1">
          <h4 class="text-uppercase">{{ $tc("menu.registration", 2) }}</h4>
        </div>
        <div class="action-bar--actions">
          <div class="action-bar--item pl-md-2 d-inline-block">
            <b-button v-if="currentUser.ProjectInsertionAllowedYN" @click="showAddProjectModal = !showAddProjectModal" variant="primary"><font-awesome-icon :icon="['fas', 'plus']" /> <span class="text-uppercase">{{ $t("registration.add") }}</span></b-button>
          </div>
          <div class="action-bar--item pl-md-2 d-inline-block" v-if="currentUser.PersonInsertionAllowedYN">
            <b-button :to="{name: 'Person'}" variant="dark"><font-awesome-icon :icon="['fas', 'level-up']" /> <span class="text-uppercase">{{ $t("person.add") }}</span></b-button>
          </div>
        </div>
      </div>

      <data-tiles :DataTableID="'Project'" v-if="currentUser.UserGroup.UserGroupID != 'RA'"/>
      <data-table :DataTableID="'Project'" v-else/>

    </div>

    <add-project :showAddProjectModal="showAddProjectModal" v-on:close="showAddProjectModal = false"></add-project>
  </div>
</template>

<script>
import DataTiles from '../components/DataTiles'
import DataTable from '../components/DataTable'
import AddProject from '../components/modals/Project/AddProject'
import RouteModalMixin from '../components/mixins/RouteModalMixin'

export default {
  name: 'App',
  components: {
    DataTiles,
    DataTable,
    AddProject
  },
  mixins: [
    RouteModalMixin
  ],
  data () {
    return {
      DataTableID: 'Project',
      showAddProjectModal: false
    }
  }
}
</script>

<style lang="scss">

</style>
