const RouteModalMixin = {
  data () {
    return {
      ID: null,
      DataTableID: null
    }
  },
  computed: {
    opacity () {
      return 1
    }
  },
  methods: {
    showDetailsModal () {
      if (!this.DataTableID) {
        console.error('DataTableID not set!')
      }
      this.$store.dispatch('modal/show', { dataTableID: this.DataTableID, ID: this.$route.params.ID })
    },
    hideModal () {
      this.$store.dispatch('modal/hide')
    }
  },
  mounted () {
    if (this.$route.params.ID) {
      this.showDetailsModal()
    }
  },
  watch: {
    '$route.params.ID' (ID) {
      if (ID) {
        this.showDetailsModal()
      } else {
        this.hideModal()
      }
    }
  }
}

export default RouteModalMixin
