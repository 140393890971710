<template>
  <div>
    <b-modal
      id="credit-modal"
      ref="modal"
      size="lg"
      :title=modalData.StandardText.Title
      v-model="showModal"
      @show="initModal"
      @hidden="resetModal"
      @ok="handleOk"
      @close="emitClose"
      @cancel="emitClose"
      :ok-title="$t('common.save')"
      :cancel-title="$t('common.cancel')"
      :ok-disabled="processing || loading"
    >
      <div v-html="modalData.StandardText.Content"></div>

      <b-form ref="form" @submit.stop.prevent="handleOk" v-if="currentProject.Activity">
        <b-form-group
          id="input-group-1"
          :label="$t('project.company_quantity')"
          label-for="input-1"
          class="mt-2"
          v-if="currentProject.Activity.ExternalYN === true"
        >
          <b-form-input
            id="input-1"
            v-model="form.CompanyQuantity"
            type="number"
            :state="validateState('CompanyQuantity')"
            aria-describedby="input-1-live-feedback"
            min="0"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('common.choose_quantity') }}.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          :label="$t('project.license_amount')"
          label-for="input-2"
          class="mt-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.Quantity"
            type="number"
            :max="currentProject.ProjectShortList['Project.FreeLicenseCount']"
            min="0"
            ref="QuantityInput"
            :state="validateState('Quantity')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-2-live-feedback"
          >{{ $t('common.choose_quantity') }}.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>

      <b-overlay :show="showConfirmOverlay" no-wrap @shown="onShown" @hidden="onHidden">
        <template v-slot:overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >
            <p><strong id="form-confirm-label">{{ $t('project.confirm_credit_notice') }}?</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" class="mr-3" @click="onCancel">
                {{ $t('common.cancel') }}
              </b-button>
              <b-button variant="outline-success" @click="handleSubmit">{{ $t('common.confirm') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        no-wrap
      >
      </b-overlay>

      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3"/>

            <p><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { formatCurrency } from '../../../../../js/helpers'
import moment from 'moment'

export default {
  props: ['showCreditModal', 'currentProject'],
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth() + 12)
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      min: today,
      max: maxDate,
      showConfirmOverlay: false,
      termsAndConditionsURL: '',
      form: {
        ActivityRecord: null,
        CustomerRecord: null,
        ActivityPartRecord: 0,
        Quantity: 0,
        CompanyQuantity: 0,
        termsAgreed: [],
        date: moment().format('YYYY-MM-DD')
      },
      modalData: {
        StandardText: {
          Title: '',
          Content: '',
          UserDefinedField01: ''
        },
        ActivityRecords: [],
        ActivityPartRecords: [],
        CustomerRecords: []
      }
    }
  },
  validations () {
    if (this.currentProject.Activity.ExternalYN === true) {
      return {
        form: {
          Quantity: {
            required
          },
          CompanyQuantity: {
            required
          }
        }
      }
    } else {
      return {
        form: {
          Quantity: {
            required
          }
        }
      }
    }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    initModal () {
      this.resetModal()
      this.loadData()
    },
    getFormattedCurrency (amount) {
      return formatCurrency(amount)
    },
    resetModal () {
      this.showConfirmOverlay = false
      this.form = {
        Quantity: 0,
        CompanyQuantity: 0,
        termsAgreed: []
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onCancel () {
      this.showConfirmOverlay = false
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.showConfirmOverlay = true
    },
    emitClose () {
      this.$emit('close')
    },
    handleSubmit () {
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          this.showConfirmOverlay = false
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          const socketParams = [
            response.data.socket.connect,
            response.data.socket.subscribeData
          ]
          this.$eventHub.$emit('createSocket', socketParams)
          this.processing = false
          this.$emit('reload')
          // Hide the modal manually
          this.$nextTick(() => {
            this.$emit('close')
          })
        }
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.QuantityInput.focus()
    },
    getData () {
      this.loading = true
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getModal',
          modal: 'credit'
        }
      })
    },
    submitData () {
      const formData = new FormData()

      formData.append('ProjectID', this.currentProject.ProjectID)
      formData.append('Quantity', this.form.Quantity)

      if (this.currentProject.Activity.ExternalYN === true) {
        formData.append('CompanyQuantity', this.form.CompanyQuantity)
      }

      return this.$http.post('/php/xhr/ajax.php?function=creditProject', formData)
    },
    loadData () {
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const construct = response.data

          this.modalData.StandardText = construct.StandardText

          this.termsAndConditionsURL = construct.TermsAndConditionsUrl

          this.loading = false
        }
      })
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showCreditModal
      },
      set () {
        this.$emit('close')
      }
    },
    selectedActivityRecordParts () {
      const selected = this.modalData.ActivityPartRecords.filter(i => i.ActivityRecordID === this.form.ActivityRecord)
      return (selected.length > 0 ? selected[0].ActivityPartRecords : [])
    }
  },
  watch: {
    'form.ActivityRecord': function () {
      this.form.ActivityPartRecord = null
    }
  }
}
</script>
