<template>
    <div>
        <b-modal
                id="socket-details-modal"
                ref="modal"
                size="lg"
                title="Achtergrond taak resultaten"
                v-model="showModal"
                @ok="emitClose"
                @close="emitClose"
                @cancel="emitClose"
                @show="onShow"
                :ok-title="$t('common.save')"
                :cancel-title="$t('common.cancel')"
        >
          <p class="text-warning"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> importeren voltooid.</p>
          <p>{{ $t('modal.socketDetails.overview') }}:</p>

          <b-progress class="mt-2 mb-1" :max="max" show-value>
            <b-progress-bar :value="valueSuccess" variant="success"></b-progress-bar>
            <b-progress-bar :value="valueFail" variant="danger"></b-progress-bar>
          </b-progress>

          <b-badge pill variant="success" class="text-success">.</b-badge>
          {{ $t('modal.socketDetails.success') }}
          <b-badge pill variant="danger" class="text-danger">.</b-badge>
          {{ $t('modal.socketDetails.fail') }}
          <p class="mt-3">
            {{ $t('modal.socketDetails.errors_occurred') }}
          </p>

          <b-table striped borderless hover responsive :fields="dataFields" :items="dataItems" class="mt-3"
                   table-class="b-table-dark"></b-table>

        </b-modal>
    </div>
</template>

<script>

export default {
  props: ['showSocketDetailsModal', 'errorCollection'],
  data () {
    return {
      valueSuccess: 0,
      valueFail: 0,
      max: 0,
      dataFields: [],
      dataItems: [],
      currentItem: null,
      timer: null
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    onShow () {
      this.parseResults()
      this.delayedData()
    },
    parseResults () {
      this.dataFields = this.errorCollection.errorFields
      const errorArr = this.errorCollection.errorData
      if (errorArr.length > 0) {
        const errorItems = []
        errorArr.forEach(item => {
          errorItems.push({
            ...item.ElementData,
            ErrorMessage: item.ErrorMessage
          })
        })
        this.dataItems = errorItems
      }
    },
    delayedData () {
      this.$nextTick(() => {
        setTimeout(() => {
          this.max = this.errorCollection.progressTotal
          this.valueSuccess = this.errorCollection.progressSuccess
          this.valueFail = this.errorCollection.progressError
        }, 200)
      })
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showSocketDetailsModal
      },
      set () {
        this.$emit('close')
      }
    }
  }
}
</script>
