<template>
  <div>
    <div class="text-center my-5 py-5" v-if="loading">
      <b-spinner variant="primary" label="Large Spinner" type="grow"></b-spinner>
    </div>
    <div class="row flex-row" v-if="!loading">
      <div class="col-12 col-lg-4 col-xl-3">
        <div :style="{ backgroundImage: 'url(' + currentItem.Image + ')' }"
             class="account-profile--img mb-3"></div>
      </div>
      <div class="col-12 col-lg-8 col-xl-9">
        <h1 class="m-0">{{ currentItem.Name }} {{ currentItem.Name2 }}</h1>
        <hr>
        {{ currentItem.UserGroup.Name }}<br>
        <a :href="'mailto:' + currentItem.Email">{{ currentItem.Email }}</a><br>
        <a :href="'tel:' + currentItem.Phone">{{ currentItem.Phone }}</a><br>
        {{ currentItem.Address1 }} {{ currentItem.Address3 }}<br>
        {{ currentItem.Address2 }} {{ currentItem.Address4 }}
      </div>
    </div>
    <hr>
    <div class="action-bar d-md-flex justify-content-end flex-row py-3">
      <div class="action-bar--actions">
        <div class="action-bar--item pl-md-2 d-inline-block mx-1">
          <b-button @click="showChangeEmployeeModal = true" variant="primary"
                    v-if="currentItem.AllowedForEmployeeModificationYN">
            <font-awesome-icon :icon="['fas', 'edit']"/>
            <span>{{ $t('person.add_change_info') }}</span></b-button>
        </div>
        <div class="action-bar--item pl-md-2 d-inline-block mx-1"
             v-if="currentItem.AllowedForEmployeeModificationYN && currentItem.HasActiveAccountYN">
          <b-button v-b-modal.reset-password-modal variant="primary">
            <font-awesome-icon :icon="['fas', 'undo']"/>
            <span>{{ $t('login.password_reset') }}</span></b-button>
        </div>
        <div class="action-bar--item pl-md-2 d-inline-block mx-1"
             v-if="currentItem.AllowedForEmployeeModificationYN && !currentItem.HasActiveAccountYN">
          <b-button v-b-modal.send-password-modal variant="primary">
            <font-awesome-icon :icon="['fas', 'paper-plane']"/>
            <span>{{ $t('login.send_password') }}</span></b-button>
        </div>
      </div>
    </div>
    <hr>

    <h1 class="my-3">{{ $t('employee.supervises') }}</h1>

    <b-table striped borderless hover fixed :fields="activityGroupFields"
             :items="activityGroupItems" class="mt-md-3 table-sm-\no-border-radius"
             table-class="b-table-dark">

      <template v-slot:cell(icon)="row">
        <router-link :to="{name: 'ActivityGroup', params: {ID: row.item.ID}}">
          <font-awesome-icon :icon="['fas', 'level-up']"/>
        </router-link>
      </template>

      <template v-slot:cell(name)="row">
        <router-link :to="{name: 'ActivityGroup', params: {ID: row.item.ID}}" class="text-decoration-none">
          {{ row.item.name }}
        </router-link>
      </template>

    </b-table>

    <b-modal
      id="reset-password-modal"
      ref="reset-password-modal"
      :title="$t('login.password_reset_send')"
      @ok="requestPasswordReset"
      :ok-title="$t('common.save')"
      :cancel-title="$t('common.cancel')"
    >
      <p>
        {{ $t('login.password_reset_info') }}
      </p>
      <p>
        {{ $t('common.continue') }}
      </p>
    </b-modal>

    <b-modal
      id="send-password-modal"
      ref="send-password-modal"
      title="Verstuur uitnodiging"
      @ok="sendPassword"
      :ok-title="$t('common.continue')"
      :cancel-title="$t('common.cancel')"
    >
      <p>
        {{ $t("login.send_password_info") }}
      </p>
      <p>
        {{ $t("common.continue_check") }}
      </p>
    </b-modal>

    <change-employee :showChangeEmployeeModal="showChangeEmployeeModal" :currentEmployee="currentItem"
                     v-on:reload="loadData" v-on:close="showChangeEmployeeModal = false"></change-employee>

    <b-overlay :show="error" no-wrap>
      <template v-slot:overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="p-3"
        >

          <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x"
                             class="text-danger mb-3"/>

          <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error:
            {{ $t('datatable_retrieve_error_short') }}</strong></p>
        </div>
      </template>
    </b-overlay>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChangeEmployee from './Actions/ChangeEmployee'

export default {
  components: { ChangeEmployee },
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      currentItem: {},
      activityGroupFields: [
        { key: 'icon', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Bedrijven' },
        { key: 'StudentCount', label: 'Studenten' }
      ],
      activityGroupItems: [],
      showChangeEmployeeModal: false
    }
  },
  methods: {
    requestPasswordReset () {
      const formData = new FormData()

      formData.append('DataTableID', 'Employee')
      formData.append('KeyFieldValue', this.currentItemID)
      const request = this.$http.post('/php/xhr/ajax.php?function=resetPassword', formData)

      request.then(response => {
        response.data.status === 'success' ? this.makeToast('success', this.$t('common.success'), this.$t('toasts.password_reset_send_succes')) : this.makeToast('danger', 'Error', response.data.message)
      })
    },
    sendPassword () {
      const formData = new FormData()

      formData.append('DataTableID', 'Employee')
      formData.append('KeyFieldValue', this.currentItemID)
      const request = this.$http.post('/php/xhr/ajax.php?function=resendInvite', formData)

      request.then(response => {
        response.data.status === 'success' ? this.makeToast('success', this.$t('common.success'), this.$t('toasts.send_password_success')) : this.makeToast('danger', 'Error', response.data.message)
      })
    },
    getData () {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getEmployee',
          EmployeeID: this.currentItemID
        }
      })
    },
    loadData () {
      if (!this.currentItemID || this.$route.name !== 'Employee') {
        return
      }
      this.loading = true
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const item = response.data
          this.currentItem = item
          if (item.ActivityGroupShortList) {
            const activityGroupItems = []
            item.ActivityGroupShortList.items.forEach(item => {
              activityGroupItems.push({
                ID: item.ActivityGroupID,
                name: item.Location.Name,
                StudentCount: item.StudentCount
              })
            })
            this.activityGroupItems = activityGroupItems
          }

          this.loading = false
        }
      })
    }
  },
  computed: {
    ...mapGetters('modal', ['currentItemID'])
  },
  watch: {
    $route: 'loadData'
  },
  mounted () {
    this.loadData()
  }
}
</script>
