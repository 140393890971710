<template>
    <div>
        <b-modal
                id="add-employee-modal"
                ref="modal"
                size="lg"
                :title=modalData.StandardText.Title
                v-model="showModal"
                @show="initModal"
                @hidden="resetModal"
                @ok="handleSubmit"
                @close="emitClose"
                @cancel="emitClose"
                :ok-title="$t('common.save')"
                :cancel-title="$t('common.cancel')"
                :ok-disabled="processing || loading"
        >
            <div v-html="modalData.StandardText.Content"></div>

            <b-form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group id="input-group-1" :label="$t('common.firstname')" label-for="input-1">
                    <b-form-input
                            id="input-1"
                            name="input-1"
                            v-model="$v.form.Name.$model"
                            :state="validateState('Name')"
                            aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-1-live-feedback"
                    >{{ $t("modal.common.required_2_letters") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-2" :label="$t('common.lastname')" label-for="input-2">
                    <b-form-input
                            id="input-2"
                            name="input-2"
                            v-model="$v.form.Name2.$model"
                            :state="validateState('Name2')"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-2-live-feedback"
                    >{{ $t("modal.common.required_2_letters") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-3" :label="$t('common.email')" label-for="input-3">
                    <b-form-input
                            id="input-3"
                            name="input-3"
                            v-model="$v.form.Email.$model"
                            :state="validateState('Email')"
                            aria-describedby="input-3-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-3-live-feedback"
                    >{{ $t("modal.common.valid_email") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-4" :label="$t('common.role')" label-for="input-4">
                    <b-form-select
                            id="input-4"
                            name="input-4"
                            v-model="$v.form.UserGroup.$model"
                            :options="modalData.UserGroupRecords"
                            :state="validateState('UserGroupID', nestedIn = 'UserGroup')"
                            aria-describedby="input-4-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback
                            id="input-4-live-feedback"
                    >{{ $t("modal.common.select_role") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="input-group-5"
                  :label="$t('customer.choose')"
                  label-for="input-5"
                  :state="this.form.CustomerRecord.value !== null"
                  v-if="form.UserGroup.CustomerRequiredYN == '1'"
                >

                  <b-dropdown
                    size="sm"
                    :variant="this.form.CustomerRecord.value !== null ? 'outline-secondary' : 'outline-danger'"
                    block
                    menu-class="w-100"
                    ref="dropdown"
                  >
                    <template v-slot:button-content>
                      <font-awesome-icon :icon="['fas', 'school']"/>
                      {{ form.CustomerRecord.name }}
                    </template>
                    <b-dropdown-form @submit.stop.prevent="() => {}">
                      <b-form-group
                        label-for="tag-search-input"
                        label="Zoek scholen"
                        label-cols-md="auto"
                        class="mb-0"
                        label-size="sm"
                        :description="searchDesc"
                      >
                        <b-form-input
                          v-model="search"
                          id="tag-search-input"
                          type="search"
                          size="sm"
                          autocomplete="off"
                          debounce="500"
                          class="w-75 d-inline-block"
                        ></b-form-input>
                        <b-button @click="$refs.dropdown.hide(true)" variant="outline-secondary"
                                  class="d-inline-block float-right"><i class="fas fa-check"></i></b-button>
                      </b-form-group>
                    </b-dropdown-form>
                    <b-dropdown-divider></b-dropdown-divider>

                    <p class="px-3 mb-0 text-info">
                      <small>{{ $t("modal.common.max_result_helper") }}</small>
                    </p>

                    <b-list-group class="p-3 overflow-auto form-tags--data-list">
                      <b-list-group-item
                        v-for="(option, index) in availableOptions"
                        :key="index"
                        @click="onOptionClick({ option })" href="#"
                      >
                        {{ option.Name }} <span v-if="option.Name2Formatted" class="text-info">- {{ option.Name2Formatted }}</span>
                      </b-list-group-item>
                    </b-list-group>

                    <b-dropdown-text v-if="availableOptions.length === 0">
                      {{ $t('customer.no_result') }}
                    </b-dropdown-text>
                  </b-dropdown>
                </b-form-group>

            </b-form>

            <b-overlay
                    :show="loading"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    no-wrap
            >
            </b-overlay>

            <b-overlay :show="processing" no-wrap>
                <template v-slot:overlay v-if="apiError">
                    <div
                            ref="dialog"
                            tabindex="-1"
                            role="dialog"
                            aria-modal="false"
                            aria-labelledby="form-confirm-label"
                            class="p-3"
                    >

                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

                        <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
                        <div class="d-flex">
                            <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
                        </div>
                    </div>
                </template>
            </b-overlay>

        </b-modal>
    </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
export default {
  props: ['showAddEmployeeModal'],
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      search: '',
      options: [],
      form: {
        UserGroup: {
          UserGroupID: null,
          CustomerRequiredYN: 0
        },
        CustomerRecord: {
          name: this.$t('customer.choose'),
          value: null
        },
        Name: null,
        Name2: null,
        Email: null
      },
      modalData: {
        StandardText: {
          Title: '',
          Content: ''
        },
        CustomerRecords: [],
        UserGroupRecords: []
      }
    }
  },
  validations () {
    if (this.form.UserGroup.CustomerRequiredYN === '1') {
      return {
        form: {
          Name: {
            required,
            minLength: minLength(2)
          },
          Name2: {
            required,
            minLength: minLength(2)
          },
          Email: {
            required,
            email
          },
          UserGroup: {
            UserGroupID: {
              required
            }
          },
          CustomerRecord: {
            value: {
              required
            }
          }
        }
      }
    } else {
      return {
        form: {
          Name: {
            required,
            minLength: minLength(2)
          },
          Name2: {
            required,
            minLength: minLength(2)
          },
          Email: {
            required,
            email
          },
          UserGroup: {
            UserGroupID: {
              required
            }
          }
        }
      }
    }
  },
  methods: {
    validateState (name, nestedIn = null) {
      if (nestedIn) {
        const { $dirty, $error } = this.$v.form[nestedIn][name]
        return $dirty ? !$error : null
      } else {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? !$error : null
      }
    },
    initModal () {
      this.resetModal()
      this.loadData()
    },
    resetModal () {
      this.form = {
        UserGroup: {
          UserGroupID: null,
          CustomerRequiredYN: 0
        },
        CustomerRecord: {
          name: this.$t('customer.choose'),
          value: null
        },
        Name: null,
        Name2: null,
        Email: null
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    emitClose () {
      this.$emit('close')
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$eventHub.$emit('reloadData')
          // Hide the modal manually
          this.$nextTick(() => {
            this.emitClose()
          })
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.employee_added'))
        }
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    getData (params) {
      return this.$http.get('/php/xhr/ajax.php', {
        params
      })
    },
    loadData () {
      this.getData({
        function: 'getModal',
        modal: 'addEmployee'
      }).then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const construct = response.data
          this.modalData.StandardText = construct.StandardText
          this.modalData.CustomerRecords = construct.CustomerRecords

          const UserGroupRecordsArr = construct.UserGroupRecords.map(record => {
            const UserGroupRecordObj = {}
            UserGroupRecordObj.text = record['UserGroup.Name']
            UserGroupRecordObj.value = {
              UserGroupID: record['UserGroup.UserGroupID'],
              CustomerRequiredYN: record['UserGroup.CustomerRequiredYN']
            }
            return UserGroupRecordObj
          })

          this.modalData.UserGroupRecords = UserGroupRecordsArr

          const CustomerRecordsArr = construct.CustomerRecords.map(record => {
            const CustomerRecordObj = {}
            CustomerRecordObj.text = record['Customer.Name']
            CustomerRecordObj.value = record['Customer.CustomerID']
            return CustomerRecordObj
          })

          this.modalData.CustomerRecords = CustomerRecordsArr
          this.loadCustomers()
          this.loading = false
        }
      })
    },
    loadCustomers () {
      this.getData({
        function: 'getCustomers',
        filter: this.criteria
      }).then(response => {
        const dataObj = response.data
        this.options = dataObj.CustomerShortList.items
        this.loading = false
      })
    },
    submitData () {
      const formData = new FormData()

      formData.append('UserGroupID', this.form.UserGroup.UserGroupID)
      formData.append('Name', this.form.Name)
      formData.append('Name2', this.form.Name2)
      formData.append('Email', this.form.Email)

      if (this.form.UserGroup.CustomerRequiredYN === '1') {
        formData.append('CustomerID', this.form.CustomerRecord.value)
      }

      return this.$http.post('/php/xhr/ajax.php?function=addEmployee', formData)
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    },
    onOptionClick ({ option }) {
      this.form.CustomerRecord = {
        name: option.Name,
        value: option.CustomerID
      }
      this.search = ''
      this.$refs.dropdown.hide(true)
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showAddEmployeeModal
      },
      set () {
        this.$emit('close')
      }
    },
    criteria () {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions () {
      const options = this.options.filter(opt => this.form.CustomerRecord.value !== opt.CustomerID)
      // Show all options available
      return options
    },
    searchDesc () {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'Er zijn geen resultaten die aan uw zoekopdracht voldoen.'
      }
      return ''
    }
  },
  watch: {
    search: 'loadCustomers'
  }
}
</script>
