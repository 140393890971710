<template>
    <div>
        <b-modal
                id="add-person-modal"
                ref="modal"
                size="lg"
                :title=modalData.StandardText.Title
                v-model="showModal"
                @show="initModal"
                @hidden="resetModal"
                @ok="handleSubmit"
                @close="emitClose"
                @cancel="emitClose"
                :ok-title="$t('common.save')"
                :cancel-title="$t('common.cancel')"
                :ok-disabled="processing || loading"
        >
            <div v-html="modalData.StandardText.Content"></div>

            <b-form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group id="input-group-1" :label="$t('common.firstname')" label-for="input-1">
                    <b-form-input
                            id="input-1"
                            name="input-1"
                            v-model="$v.form.Name.$model"
                            :state="validateState('Name')"
                            aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-1-live-feedback"
                    >{{ $t("modal.common.required_2_letters") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-2" :label="$t('common.lastname')" label-for="input-2">
                    <b-form-input
                            id="input-2"
                            name="input-2"
                            v-model="$v.form.Name2.$model"
                            :state="validateState('Name2')"
                            aria-describedby="input-2-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-2-live-feedback"
                    >{{ $t("modal.common.required_2_letters") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-3" :label="$t('common.email')" label-for="input-3">
                    <b-form-input
                            id="input-3"
                            name="input-3"
                            v-model="$v.form.Email.$model"
                            :state="validateState('Email')"
                            aria-describedby="input-3-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-1-live-feedback"
                    >{{ $t("modal.common.valid_email") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-4" :label="$t('person.class')" label-for="input-3">
                    <b-form-input
                            id="input-4"
                            name="input-4"
                            v-model="$v.form.Education.$model"
                            :state="validateState('Education')"
                            aria-describedby="input-4-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback
                            id="input-4-live-feedback"
                    >{{ $t("modal.person.valid_class_name") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                        id="input-group-5"
                        label="School:"
                        label-for="input-5"
                >
                    <b-form-select
                            id="input-5"
                            v-model="form.CustomerRecord"
                            :options="modalData.CustomerRecords"
                            :state="validateState('CustomerRecord')"
                            aria-describedby="input-5-live-feedback"
                    ></b-form-select>
                    <b-form-invalid-feedback
                            id="input-5-live-feedback"
                    >{{ $t("modal.common.select_school") }}</b-form-invalid-feedback>
                </b-form-group>

            </b-form>

            <b-overlay
                    :show="loading"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    no-wrap
            >
            </b-overlay>

            <b-overlay :show="processing" no-wrap>
                <template v-slot:overlay v-if="apiError">
                    <div
                            ref="dialog"
                            tabindex="-1"
                            role="dialog"
                            aria-modal="false"
                            aria-labelledby="form-confirm-label"
                            class="p-3"
                    >

                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

                        <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
                        <div class="d-flex">
                            <b-button variant="outline-danger" @click="cancelProcessing">{{ $t("common.back") }}</b-button>
                        </div>
                    </div>
                </template>
            </b-overlay>

        </b-modal>
    </div>
</template>

<script>
import { required, minLength, email, alphaNum } from 'vuelidate/lib/validators'
export default {
  props: ['showAddPersonModal'],
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      form: {
        Name: null,
        Name2: null,
        Email: null,
        CustomerRecord: null,
        Education: null
      },
      modalData: {
        StandardText: {
          Title: '',
          Content: ''
        },
        CustomerRecords: []
      }
    }
  },
  validations: {
    form: {
      Name: {
        required,
        minLength: minLength(2)
      },
      Name2: {
        required,
        minLength: minLength(2)
      },
      Email: {
        required,
        email
      },
      Education: {
        minLength: minLength(3),
        alphaNum
      },
      CustomerRecord: {
        required
      }
    }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    initModal () {
      this.resetModal()
      this.loadData()
    },
    resetModal () {
      this.form = {
        Name: null,
        Name2: null,
        Email: null,
        CustomerRecord: null,
        Education: null
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    emitClose () {
      this.$emit('close')
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$eventHub.$emit('reloadData')
          // Hide the modal manually
          this.$nextTick(() => {
            this.emitClose()
          })
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.student_added'))
        }
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    getData () {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getModal',
          modal: 'addPerson'
        }
      })
    },
    loadData () {
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const construct = response.data
          this.modalData.StandardText = construct.StandardText
          this.modalData.CustomerRecords = construct.CustomerRecords

          const CustomerRecordsArr = construct.CustomerRecords.map(record => {
            const CustomerRecordObj = {}
            CustomerRecordObj.text = record['Customer.Name']
            CustomerRecordObj.value = record['Customer.CustomerID']
            return CustomerRecordObj
          })

          this.modalData.CustomerRecords = CustomerRecordsArr

          if (this.modalData.CustomerRecords.length === 1) {
            this.form.CustomerRecord = this.modalData.CustomerRecords[0].value
          }

          this.loading = false
        }
      })
    },
    submitData () {
      const formData = new FormData()
      formData.append('Name', this.form.Name)
      formData.append('Name2', this.form.Name2)
      formData.append('Email', this.form.Email)
      formData.append('Education', this.form.Education)
      formData.append('CustomerID', this.form.CustomerRecord)

      return this.$http.post('/php/xhr/ajax.php?function=addPerson', formData)
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showAddPersonModal
      },
      set () {
        this.$emit('close')
      }
    }
  }
}
</script>
