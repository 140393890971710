<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <div class="component-wrapper" v-if="!loading">
      <div class="row flex-row">
        <div class="d-none d-md-block col-3">
          <div class="company-logo-modal">
            <img :src="currentItem.MainImage" :alt="currentItem.Name">
          </div>
        </div>
        <div class="col-12 col-lg-4 order-sm-first order-lg-2">
          <h1 class="m-0">{{ currentItem.Name }}</h1>
          #{{ currentItem.ActivityGroupID }} <span v-if="currentItem.BankAccount"
                                                   class="text-secondary">| {{ currentItem.BankAccount }}</span>
          <hr>
          {{ currentItem.Project.Name }}<br>
          {{ currentItem.StudentCount }} {{ $tc('menu.student', currentItem.StudentCount) }}<br>
          <strong>{{ $t('common.duration') }}</strong><br>
          {{ currentItem.StartDate }} {{ $t('common.till') }} {{ currentItem.EndDate }}
        </div>
        <div class="col-12 col-lg-4 offset-lg-1 order-lg-3 mt-3 mt-lg-0">
          <div class="investment-block bg-light">
            <p>{{ $tc('person.investor', 2) }}: <span class="float-right">{{ currentItem.InvestorQuantity }}</span></p>
            <p>{{ $tc('person.share', 2) }}: <span class="float-right">{{ currentItem.SharesQuantity }}</span></p>
            <hr>
            <h1 class="text-right mt-3">{{ getFormattedCurrency(currentItem.TotalInvestment) }}</h1>
          </div>
        </div>
      </div>
      <hr>
      <div class="action-bar d-md-flex justify-content-end flex-row py-3">
        <div class="action-bar--actions">
          <b-dropdown id="dropdown-right" right :text="$t('datatable.actions')" variant="primary"
                      class="action-bar--item pl-md-2 d-inline-block mx-1">
            <b-dropdown-item @click="showChangeInfoModal = !showChangeInfoModal"
                             v-if="currentItem.BaseModificationYN = true">{{ $t('common.change_info') }}
            </b-dropdown-item>
            <b-dropdown-item @click="showAddPersonModal = !showAddPersonModal"
                             v-if="currentItem.PersonModificationYN = true">{{ $t('person.add') }}
            </b-dropdown-item>
            <b-dropdown-item @click="showAddEmployeeModal = !showAddEmployeeModal"
                             v-if="currentItem.EmployeeModificationYN = true">{{ $t('employee.add') }}
            </b-dropdown-item>
            <b-dropdown-item @click="showAddInvestorModal = !showAddInvestorModal"
                             v-if="currentItem.BaseModificationYN = true">{{ $t('investor.add') }}
            </b-dropdown-item>
            <!--                        <b-dropdown-item href="#">{{ $t("document.add") }}</b-dropdown-item>-->
            <b-dropdown-item @click="routeToStudentView()">Bekijk als student</b-dropdown-item>
            <b-dropdown-item>
              <router-link :to="{name: 'Project', params: {ID: currentItem.Project.ProjectID}}">
                Naar inschrijving
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
          <!--                    <div class="action-bar&#45;&#45;item pl-md-2 d-inline-block mx-1">-->
          <!--                        <a href="#" class="btn btn-dark"><font-awesome-icon :icon="['fas', 'external-link-alt']" /> <span>{{ $t("external.to_twinfield") }}</span></a>-->
          <!--                    </div>-->
        </div>
      </div>
      <hr>

      <div class="company-progress mt-3 text-center text-md-left">
        <h1 class="mb-0">{{ $t('common.progress') }}</h1>
        <small>{{ currentItem.Steps.Completed }}/{{ currentItem.Steps.Total }} stappen compleet</small>
        <div class="progress-btn-list mt-3">
          <b-button v-for="(step, index) in steps" :key="index" :variant="getStyle(step.progresssion).variant"
                    :disabled="getStyle(step.progresssion).disabled" class="mb-1 mr-1 step-btn"
                    @click="currentModalComponent = step">
            {{ step.name }}
            <b-badge variant="light">
              <font-awesome-icon :icon="['fas', getStyle(step.progresssion).icon]"/>
            </b-badge>
          </b-button>
        </div>
      </div>

      <b-table striped borderless hover fixed :fields="personFields" :items="personItems" class="mt-3"
               :empty-text="$t('activitygroup.no_persons')" show-empty>
        <template #empty="scope">
          <h6>{{ scope.emptyText }}</h6>
        </template>
        <template v-slot:cell(details)="row">
          <b-btn variant="link" class="p-0" @click="showDetails(row.item, 'Person')" v-if="currentUser.PersonInsertionAllowedYN">
            <font-awesome-icon :icon="['fas', 'pencil']"/>
          </b-btn>
        </template>

        <template v-slot:cell(name)="row">
          <router-link :to="{name: 'Person', params: {ID: row.item.ID}}" class="text-decoration-none" v-if="currentUser.PersonInsertionAllowedYN">
            {{ row.item.name }}
          </router-link>
          <div v-else>{{ row.item.name }}</div>
        </template>

      </b-table>

      <b-table striped borderless hover fixed :fields="employeeFields" :items="employeeItems" class="mt-3"
               table-class="b-table-tertiary" :empty-text="$t('activitygroup.no_employees')" show-empty>
        <template #empty="scope">
          <h6>{{ scope.emptyText }}</h6>
        </template>
        <template v-slot:cell(details)="row">
          <b-btn variant="link" class="p-0" @click="showDetails(row.item, 'Employee')">
            <font-awesome-icon :icon="['fas', 'pencil']"/>
          </b-btn>
        </template>

        <template v-slot:cell(name)="row">
          <router-link :to="{name: 'Employee', params: {ID: row.item.ID}}" class="text-decoration-none">
            {{ row.item.name }}
          </router-link>
        </template>

      </b-table>

      <b-table striped borderless hover fixed :fields="investorFields" :items="investorItems" class="mt-3"
               table-class="b-table-dark" :empty-text="$t('activitygroup.no_investors')" show-empty>
        <template #empty="scope">
          <h6>{{ scope.emptyText }}</h6>
        </template>
        <template v-slot:cell(edit)="row">
          <b-btn variant="link" class="p-0" @click="showChangeInvestorModal(row.item, 'Person')">
            <font-awesome-icon :icon="['fas', 'pencil']"/>
          </b-btn>
        </template>

      </b-table>

      <!--            <b-table striped borderless hover fixed :fields="documentFields" :items="documentItems" class="mt-1 mb-3"-->
      <!--                     table-class="b-table-primary">-->

      <!--                <template v-slot:cell(document)="{ item, value }">-->
      <!--                    <b-link :href="item.url" target="_blank">{{ value }}</b-link>-->
      <!--                </template>-->

      <!--            </b-table>-->

      <component :is="currentModalComponent.modalComponent" :currentCompany="currentItem"
                 :modalText="currentModalComponent.description" v-on:reload="loadData" @unmount="currentModalComponent = {
                      modalComponent: null,
                      description: null
                      }"
      />
    </div>

    <b-overlay :show="error" no-wrap>
      <template v-slot:overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label-1"
          class="p-3"
        >

          <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x"
                             class="text-danger mb-3"/>

          <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label-1" class="text-danger">
            {{ $t('error.error_retrieving_data') }}
          </strong></p>
        </div>
      </template>
    </b-overlay>

    <change-info :showChangeInfoModal="showChangeInfoModal" :currentCompany="currentItem" v-on:reload="loadData"
                 v-on:close="showChangeInfoModal = false"></change-info>
    <add-person :showAddPersonModal="showAddPersonModal" :currentCompany="currentItem" v-on:reload="loadData"
                v-on:close="showAddPersonModal = false"></add-person>
    <add-employee :showAddEmployeeModal="showAddEmployeeModal" :currentCompany="currentItem" v-on:reload="loadData"
                  v-on:close="showAddEmployeeModal = false"></add-employee>
    <add-investor :showAddInvestorModal="showAddInvestorModal" :currentCompany="currentItem" :changeItem="changeItem" v-on:reload="loadData"
                  v-on:close="resetAddInvestorModal"></add-investor>
    <add-document :showAddDocumentModal="showAddDocumentModal" v-on:reload="loadData"
                  v-on:close="showAddDocumentModal = false"></add-document>

    <b-modal id="detail-modal" title="Info" ref="detailModal" size="lg" :ok-title="$t('common.save')"
             :cancel-title="$t('common.close')" @hidden="resetDetailModal" @ok="handleSubmit">
      <div :style="{ backgroundImage: 'url(' + currentDetailItem.Image + ')' }"
           class="account-profile--img mb-3"></div>
      <h5 class="text-center">{{ currentDetailItem.name }}
        <b-badge variant="tertiary text-white">{{ currentDetailItem.Education }}</b-badge>
      </h5>
      <div v-if="currentItem.BaseModificationYN">
        <hr>
        <h6>{{ $t('activitygroup.change_role') }}</h6>
        <p>{{ $t('activitygroup.change_role_desc') }}</p>
        <b-form-row>
          <b-col cols="4">
            <b-form-select v-model="form.UserGroupID" :options="currentAvailableRoles"></b-form-select>
          </b-col>
        </b-form-row>
      </div>
      <hr>
      <h6>{{ $t('common.view') }}</h6>
      <p>{{ $t('activitygroup.navigate_to') }}</p>
      <b-btn variant="dark" :to="{name: this.currentDetailItem.DataTableID, params: {ID: currentDetailItem.ID}}">
        <font-awesome-icon :icon="['fas', 'level-up']"/>
        Naar detail pagina
      </b-btn>
      <div v-if="currentItem.BaseModificationYN">
        <hr>
        <h6>{{ $t('modal.activitygroup.unlink') }}</h6>
        <p>Door te ontkoppelen verwijdert u de connectie met dit bedrijf</p>
        <b-btn variant="danger" @click="unlink(currentDetailItem)">
          <font-awesome-icon :icon="['fas', 'trash']"/>
          {{ $t('modal.activitygroup.unlink') }}
        </b-btn>
      </div>

      <b-overlay :show="processing" no-wrap>
        <template v-slot:overlay v-if="apiError">
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label-2"
            class="p-3"
          >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3"/>

            <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label-2" class="text-danger">Error: {{
                errorMsg
              }}</strong></p>
            <div class="d-flex">
              <b-button variant="outline-danger" @click="cancelProcessing">{{ $t('common.back') }}</b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '../../../../js/helpers'
import updateCompanyName from '../Steps/updateCompanyName'
import updateBankAccount from '../Steps/updateBankAccount'
import requestBankAccount from '../Steps/requestBankAccount'
import businessPlanIsAdded from '../Steps/businessPlanIsAdded'
import closeCompany from '../Steps/closeCompany'
import ChangeInfo from './Actions/ChangeInfo'
import AddPerson from './Actions/AddPerson'
import AddEmployee from './Actions/AddEmployee'
import AddInvestor from './Actions/AddInvestor'
import AddDocument from './Actions/AddDocument'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ActivityGroupDetails',
  components: {
    AddDocument,
    AddInvestor,
    AddEmployee,
    AddPerson,
    ChangeInfo,
    updateCompanyName,
    updateBankAccount,
    requestBankAccount,
    businessPlanIsAdded,
    closeCompany
  },
  data () {
    return {
      currentModalComponent: {
        modalComponent: null,
        description: null
      },
      loading: true,
      error: false,
      processing: false,
      apiError: false,
      errorMsg: null,
      currentDetailItem: [],
      currentAvailableRoles: [],
      showChangeInfoModal: false,
      showAddPersonModal: false,
      showAddEmployeeModal: false,
      showAddInvestorModal: false,
      showAddDocumentModal: false,
      currentItem: {
        Location: {}
      },
      changeItem: null,
      form: {
        UserGroupID: null
      },
      company: {
        name: 'Urban Aesthetics',
        file: null,
        nameState: null
      },
      investor: {
        firstName: null,
        firstNameState: null,
        lastName: null,
        lastNameState: null,
        eMail: null,
        eMailState: null,
        phone: null,
        phoneState: null,
        iban: null,
        ibanState: null
      },
      personFields: [
        { key: 'details', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Personen' },
        'rol',
        { key: 'email', label: 'E-mailadres' },
        { key: 'phone', label: 'Telefoonnummer' }
      ],
      personItems: [],
      employeeFields: [
        { key: 'details', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Begeleiding' },
        'rol'
      ],
      employeeItems: [],
      documentFields: [
        { key: 'document', label: 'Documenten' }
      ],
      documentItems: [
        // { document: 'KvK Urban Aestheatics.pdf', url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf' }
      ],
      investorFields: [
        { key: 'edit', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Investeerders' },
        { key: 'quantity', label: 'Aandelen' },
        { key: 'iban', label: 'Bankrekening' }
      ],
      investorItems: [],
      steps: []
    }
  },
  validations: {
    form: {
      UserGroupID: {
        required
      }
    }
  },
  methods: {
    resetModal () {
      this.name = null
      this.company.nameState = null
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.data_changed'))
          this.loadData()
          this.$refs.detailModal.hide()
        }
      })
    },
    submitData () {
      const xhr = this.currentDetailItem.DataTableID === 'Employee' ? 'modifyEmployeeActivityGroup' : 'modifyPersonActivityGroup'
      const formData = new FormData()

      formData.append('method', 'update')
      formData.append('ActivityGroupID', this.currentItem.ActivityGroupID)
      formData.append(this.currentDetailItem.DataTableID === 'Employee' ? 'EmployeeRecords[' + this.currentDetailItem.ID + '][EmployeeID]' : 'PersonRecords[' + this.currentDetailItem.ID + '][PersonID]', this.currentDetailItem.ID)
      formData.append(this.currentDetailItem.DataTableID === 'Employee' ? 'EmployeeRecords[' + this.currentDetailItem.ID + '][UserGroupID]' : 'PersonRecords[' + this.currentDetailItem.ID + '][UserGroupID]', this.form.UserGroupID)
      return this.$http.post('/php/xhr/ajax.php?function=' + xhr, formData)
    },
    getData (xhr) {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: xhr,
          ActivityGroupID: this.currentItemID
        }
      })
    },
    getFormattedCurrency (amount) {
      return formatCurrency(amount)
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    },
    resetAddInvestorModal () {
      this.changeItem = null
      this.showAddInvestorModal = false
    },
    loadData () {
      if (!this.currentItemID || this.$route.name !== 'ActivityGroup') {
        return
      }
      this.loading = true
      this.getData('getActivityGroup').then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const item = response.data
          this.currentItem = item

          if (item.Students.items) {
            const personItems = []
            item.Students.items.forEach(item => {
              personItems.push({
                ID: item.PersonID,
                name: item.Name + ' ' + item.Name2,
                rol: item.Person_ActivityGroup.Name,
                availableRoles: item.UserGroupRecords.items,
                UserGroupID: item.Person_ActivityGroup.UserGroupID,
                Education: item.Education,
                Image: item.Image,
                email: item.Email,
                phone: item.Phone
              })
            })
            this.personItems = personItems
          }
          if (item.Teachers) {
            const employeeItems = []
            item.Teachers.items.forEach(item => {
              employeeItems.push({
                ID: item.EmployeeID,
                name: item.Name + ' ' + item.Name2,
                rol: item.Employee_ActivityGroup.Name,
                availableRoles: item.UserGroupRecords.items,
                UserGroupID: item.Employee_ActivityGroup.UserGroupID,
                Education: item.Education,
                Image: item.Image
              })
            })
            this.employeeItems = employeeItems
          }
          if (item.Investors) {
            const investorItems = []
            item.Investors.items.forEach(item => {
              investorItems.push({
                name: item.Supplier.Name + ' ' + item.Supplier.Name2,
                quantity: parseFloat(item.FreeQuantity),
                iban: item.Supplier.BankAccount,
                fullItem: item
              })
            })
            this.investorItems = investorItems
          }
          if (item.Steps) {
            const Steps = []
            item.Steps.ShortList.items.forEach(item => {
              const itemProgression = (item.Skill.FinishedYN ? 2 : (item.Skill.inProgressYN ? 1 : 0))
              Steps.push({
                name: item.Skill.Name,
                progresssion: itemProgression,
                modalComponent: item.Skill.UserDefinedField01,
                description: item.Skill.Description
              })
            })
            this.steps = Steps
          }

          this.loading = false
        }
      })
    },
    showDetails (item, DataTableID) {
      this.currentDetailItem = item
      item.availableRoles.forEach(role => {
        this.currentAvailableRoles.push({
          text: role.Name,
          value: role.UserGroupID
        })
      })
      this.form.UserGroupID = item.UserGroupID
      this.currentDetailItem.DataTableID = DataTableID
      this.$refs.detailModal.show()
    },
    unlink (item) {
      const formData = new FormData()

      const xhr = this.currentDetailItem.DataTableID === 'Employee' ? 'modifyEmployeeActivityGroup' : 'modifyPersonActivityGroup'
      formData.append('ActivityGroupID', this.currentItem.ActivityGroupID)
      formData.append(this.currentDetailItem.DataTableID === 'Employee' ? 'EmployeeRecords[' + this.currentDetailItem.ID + '][EmployeeID]' : 'PersonRecords[' + this.currentDetailItem.ID + '][PersonID]', this.currentDetailItem.ID)
      formData.append('method', 'delete')

      return this.$http.post('/php/xhr/ajax.php?function=' + xhr, formData).then(response => {
        if (response.data.status !== 'success') {
          this.makeToast('danger', 'Error', this.$t('toasts.general_error'))
        } else {
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.unlinked'))
          this.loadData()
          this.$refs.detailModal.hide()
        }
      })
    },
    showChangeInvestorModal (item) {
      this.changeItem = item
      this.showAddInvestorModal = true
    },
    resetDetailModal () {
      this.currentDetailItem = []
      this.currentAvailableRoles = []
      this.form = {
        UserGroupID: null
      }
    },
    routeToStudentView () {
      this.$store.dispatch('modal/hide')
      this.$nextTick(() => {
        this.$root._router.push({
          name: 'ActivityGroupOverviewStudentView',
          params: { ID: this.currentItem.ActivityGroupID }
        })
      })
    },
    getStyle (progression) {
      const styles = {
        0: {
          variant: 'outline-primary',
          disabled: false,
          icon: 'exclamation'
        },
        1: {
          variant: 'outline-warning',
          disabled: true,
          icon: 'hourglass'
        },
        2: {
          variant: 'outline-success',
          disabled: true,
          icon: 'check'
        }
      }
      return styles[progression]
    }
  },
  computed: {
    ...mapGetters('modal', ['currentItemID'])
  },
  watch: {
    $route: 'loadData'
  },
  created () {
    this.$nextTick(() => {
      this.$eventHub.$on('reloadModalData', this.loadData)
    })
  },
  beforeDestroy () {
    this.$eventHub.$off('reloadModalData')
  },
  mounted () {
    this.loadData()
  }
}
</script>
