<template>
  <div>
    <div class="text-center my-5 py-5" v-if="loading">
      <b-spinner variant="primary" label="Large Spinner" type="grow"></b-spinner>
    </div>
    <div class="modal-content--wrapper" v-if="!loading">
      <div class="row flex-row">
        <div class="col-2 d-none d-md-block">
          <div :style="{ backgroundImage: 'url(' + currentItem.Image + ')' }"
               class="account-profile--img mb-3"></div>
        </div>

        <div class="col-12 col-md-10">
          <h1 class="m-0">{{ currentItem.Name }} {{ currentItem.Name2 }}
            <small class="text-secondary">{{ currentItem.Education }}</small>
          </h1>
          <hr>
          <a :href="'mailto:' + currentItem.Email">{{ currentItem.Email }}</a><br>
          <a :href="'tel:' + currentItem.Phone">{{ currentItem.Phone }}</a><br>
          {{ currentItem.Address1 }} {{ currentItem.Address3 }}<br>
          {{ currentItem.Address2 }} {{ currentItem.Address4 }}
        </div>
      </div>
      <hr>
      <div class="action-bar d-md-flex justify-content-end flex-row py-3">
        <div class="action-bar--actions">
          <div class="action-bar--item pl-md-2 d-inline-block mx-1">
            <b-button @click="showChangePersonModal = true" variant="primary">
              <font-awesome-icon :icon="['fas', 'edit']"/>
              <span>{{ $t('person.add_change_info') }}</span></b-button>
          </div>
          <div class="action-bar--item pl-md-2 d-inline-block mx-1"
               v-if="currentItem.AllowedForPersonModificationYN && currentItem.HasActiveAccountYN">
            <b-button v-b-modal.reset-password-modal variant="primary">
              <font-awesome-icon :icon="['fas', 'undo']"/>
              <span>{{ $t('login.password_reset') }}</span></b-button>
          </div>
          <div class="action-bar--item pl-md-2 d-inline-block mx-1"
               v-if="currentItem.AllowedForPersonModificationYN && !currentItem.HasActiveAccountYN">
            <b-button v-b-modal.send-password-modal variant="primary">
              <font-awesome-icon :icon="['fas', 'paper-plane']"/>
              <span>{{ $t('login.send_password') }}</span></b-button>
          </div>
        </div>
      </div>
      <hr>

      <div class="connected-company" v-if="currentItem.Company">
        <h1 class="my-3">Company "{{ currentItem.Company.Name }}"</h1>
        <b-card no-body class="overflow-hidden mb-3">
          <b-row no-gutters>
            <b-col md="12">
              <b-card no-body>
                <b-tabs pills card>
                  <b-tab title="Algemeen" active>
                    <b-card-text>
                      <b-row>
                        <b-col cols="12" md="3">
                          <b-card-img :src="currentItem.Company.MainImage" :alt="currentItem.Company.Name"
                                      class="rounded-0 mb-1"></b-card-img>
                        </b-col>
                        <b-col cols="12" md="9">
                          <h2>{{ currentItem.Company.Name }}</h2>
                          <small>Licentie: {{ currentItem.SubscriptionID }}</small>
                          <b-card-text>
                            {{ currentItem.Company.Content }}
                          </b-card-text>
                          <b-button :to="{ name: 'ActivityGroup', params: {ID: currentItem.Company.ActivityGroupID}}"
                                    variant="primary">Bekijk bedrijf
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Progressie">
                    <small>{{ currentItem.Company.Steps.Completed }}/{{ currentItem.Company.Steps.Total }}
                      {{ $t('person.steps_complete') }}</small>
                    <div class="progress-btn-list mt-3">
                      <b-button v-for="(step, index) in steps" :key="index"
                                :variant="getStyle(step.progresssion).variant"
                                :disabled="getStyle(step.progresssion).disabled" class="mb-1 mr-1 step-btn">
                        {{ step.name }}
                        <b-badge variant="light">
                          <font-awesome-icon :icon="['fas', getStyle(step.progresssion).icon]"/>
                        </b-badge>
                      </b-button>
                    </div>
                  </b-tab>
                  <b-tab title="Studenten" class="p-0 p-md-3">
                    <b-card-text>
                      <b-table striped borderless hover fixed :fields="personFields"
                               :items="personItems" class="mt-md-3 table-sm-no-border-radius">

                        <template v-slot:cell(edit)="row">
                          <router-link :to="{name: 'Person', params: {ID: row.item.ID}}">
                            <font-awesome-icon :icon="['fas', 'level-up']"/>
                          </router-link>
                        </template>

                        <template v-slot:cell(name)="row">
                          <router-link :to="{name: 'Person', params: {ID: row.item.ID}}" class="text-decoration-none">
                            {{ row.item.name }}
                          </router-link>
                        </template>

                      </b-table>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Begeleiding" class="p-0 p-md-3">
                    <b-card-text>
                      <b-table striped borderless hover fixed :fields="employeeFields"
                               :items="employeeItems" class="m-0 mt-md-3 table-sm-no-border-radius"
                               table-class="b-table-tertiary">

                        <template v-slot:cell(edit)="row">
                          <router-link :to="{name: 'Employee', params: {ID: row.item.ID}}">
                            <font-awesome-icon :icon="['fas', 'level-up']"/>
                          </router-link>
                        </template>

                        <template v-slot:cell(name)="row">
                          <router-link :to="{name: 'Employee', params: {ID: row.item.ID}}" class="text-decoration-none">
                            {{ row.item.name }}
                          </router-link>
                        </template>

                      </b-table>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Investeerders">
                    <b-card-text>
                      <div class="investment-block bg-light">
                        <p>{{ $tc('person.investor', 2) }}: <span
                          class="float-right">{{ currentItem.Company.InvestorQuantity }}</span></p>
                        <p>{{ $tc('person.share', 2) }}: <span class="float-right">{{
                            currentItem.Company.SharesQuantity
                          }}</span></p>
                        <hr>
                        <h1 class="text-right mt-3">{{ getFormattedCurrency(currentItem.Company.TotalInvestment) }}</h1>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <b-modal
        id="reset-password-modal"
        ref="reset-password-modal"
        title="Verstuur wachtwoord reset"
        @ok="requestPasswordReset"
        :ok-title="$t('common.continue')"
        :cancel-title="$t('common.cancel')"
      >
        <p>
          {{ $t('login.password_reset_info') }}
        </p>
        <p>
          {{ $t('common.continue_check') }}
        </p>
      </b-modal>

      <b-modal
        id="send-password-modal"
        ref="send-password-modal"
        title="Verstuur uitnodiging"
        @ok="sendPassword"
        :ok-title="$t('common.continue')"
        :cancel-title="$t('common.cancel')"
      >
        <p>
          {{ $t('login.send_password_info') }}
        </p>
        <p>
          {{ $t('common.continue_check') }}
        </p>
      </b-modal>

      <change-person :showChangePersonModal="showChangePersonModal" :currentPerson="currentItem" v-on:reload="loadData"
                     v-on:close="showChangePersonModal = false"></change-person>

            <b-overlay :show="apiError" no-wrap>
                <template v-slot:overlay>
                    <div
                            ref="dialog"
                            tabindex="-1"
                            role="dialog"
                            aria-modal="false"
                            aria-labelledby="form-confirm-label"
                            class="p-3"
                    >

            <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x"
                               class="text-danger mb-3"/>

                        <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error:
                            {{ errorMsg }}</strong></p>
                    </div>
                </template>
            </b-overlay>

    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '../../../../js/helpers'
import ChangePerson from './Actions/ChangePerson'

export default {
  components: { ChangePerson },
  data () {
    return {
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      currentItem: {},
      company: {},
      personFields: [
        { key: 'edit', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Personen' },
        'rol'
      ],
      personItems: [],
      employeeFields: [
        { key: 'edit', label: '', class: 'table-icon-field' },
        { key: 'name', label: 'Begeleiding' },
        'rol'
      ],
      employeeItems: [],
      showChangePersonModal: false,
      steps: []
    }
  },
  methods: {
    getFormattedCurrency (amount) {
      return formatCurrency(amount)
    },
    requestPasswordReset () {
      const formData = new FormData()

      formData.append('DataTableID', 'Person')
      formData.append('KeyFieldValue', this.currentItemID)
      const request = this.$http.post('/php/xhr/ajax.php?function=resetPassword', formData)

      request.then(response => {
        response.data.status === 'success' ? this.makeToast('success', this.$t('common.success'), this.$t('toasts.password_reset_send_succes')) : this.makeToast('danger', 'Error', response.data.message)
      })
    },
    sendPassword () {
      const formData = new FormData()

      formData.append('DataTableID', 'Person')
      formData.append('KeyFieldValue', this.currentItemID)
      const request = this.$http.post('/php/xhr/ajax.php?function=resendInvite', formData)

      request.then(response => {
        response.data.status === 'success' ? this.makeToast('success', this.$t('common.success'), this.$t('toasts.send_password_success')) : this.makeToast('danger', 'Error', response.data.message)
      })
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    },
    getData () {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getPerson',
          PersonID: this.currentItemID
        }
      })
    },
    loadData () {
      if (!this.currentItemID || this.$route.name !== 'Person') {
        return
      }
      this.loading = true
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.errorMsg = response.data.message
          this.apiError = true
          this.loading = false
        } else {
          const item = response.data
          this.currentItem = item

          if (item.Company) {
            if (item.Company.PersonShortList) {
              const personItems = []
              item.Company.PersonShortList.items.forEach(item => {
                personItems.push({
                  ID: item.PersonID,
                  name: item.Name + ' ' + item.Name2,
                  rol: item.Person_ActivityGroup.Name
                })
              })
              this.personItems = personItems
            }
            if (item.Company.EmployeeShortList) {
              const employeeItems = []
              item.Company.EmployeeShortList.items.forEach(item => {
                employeeItems.push({
                  ID: item.EmployeeID,
                  name: item.Name + ' ' + item.Name2,
                  rol: item.Employee_ActivityGroup.Name
                })
              })
              this.employeeItems = employeeItems
            }
          }

          if (item.Company) {
            const Steps = []
            item.Company.Steps.ShortList.items.forEach(item => {
              const itemProgression = (item.Skill.FinishedYN === '1' ? 2 : (item.Skill.inProgressYN === '1' ? 1 : 0))
              Steps.push({
                name: item.Skill.Name,
                progresssion: itemProgression,
                modalComponent: item.Skill.UserDefinedField01,
                description: item.Skill.Description
              })
            })
            this.steps = Steps
          }

          this.loading = false
        }
      })
    },
    getStyle (progression) {
      const styles = {
        0: {
          variant: 'outline-primary',
          disabled: false,
          icon: 'exclamation'
        },
        1: {
          variant: 'outline-warning',
          disabled: true,
          icon: 'hourglass'
        },
        2: {
          variant: 'outline-success',
          disabled: true,
          icon: 'check'
        }
      }
      return styles[progression]
    }
  },
  computed: {
    ...mapGetters('modal', ['currentItemID'])
  },
  watch: {
    $route: 'loadData',
    'changePersonForm.Image': 'checkImageSize'
  },
  mounted () {
    this.loadData()
  }
}
</script>
