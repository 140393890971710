import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard'
import Customers from '../views/Customers'
import CompanyDetail from '../views/ActivityGroup/ActivityGroupDetails'
import ActivityGroups from '../views/ActivityGroups'
import Employees from '../views/Employee'
import Projects from '../views/Project'
import Persons from '../views/Person'
import Subscriptions from '../views/Subscription'
import FAQ from '../views/FAQ'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/student-company',
    name: 'ActivityGroupOverviewStudentView',
    component: CompanyDetail,
    children: [
      {
        name: 'ActivityGroup Student overzicht',
        path: ':ID'
      }
    ]
  },
  {
    path: '/mijn-scholen',
    name: 'CustomerOverview',
    meta: {
      DataTableID: 'Customer'
    },
    component: Customers,
    children: [
      {
        name: 'Customer',
        path: ':ID'
      }
    ]
  },
  {
    path: '/mijn-bedrijf',
    name: 'MyCompanyOverview',
    component: CompanyDetail
  },
  {
    path: '/bedrijven',
    name: 'ActivityGroupOverview',
    meta: {
      DataTableID: 'ActivityGroup'
    },
    component: ActivityGroups,
    children: [
      {
        name: 'ActivityGroup',
        path: ':ID'
      }
    ]
  },
  {
    path: '/begeleiders',
    name: 'EmployeeOverview',
    meta: {
      DataTableID: 'Employee'
    },
    component: Employees,
    children: [
      {
        name: 'Employee',
        path: ':ID'
      }
    ]
  },
  {
    path: '/programma',
    name: 'ThreeShips',
    beforeEnter () { location.href = process.env.VUE_APP_CUMLAUDE_URL + '/security/oidc/login.aspx?redirecturl=/' }
  },
  {
    path: '/inschrijvingen',
    name: 'ProjectOverview',
    meta: {
      DataTableID: 'Project'
    },
    component: Projects,
    children: [
      {
        name: 'Project',
        path: ':ID'
      }
    ]
  },
  {
    path: '/studenten',
    name: 'PersonOverview',
    meta: {
      DataTableID: 'Person'
    },
    component: Persons,
    children: [
      {
        name: 'Person',
        path: ':ID'
      }
    ]
  },
  {
    path: '/licenties',
    name: 'Licenties',
    component: Subscriptions
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  }
]

const router = new VueRouter({
  routes
})

export default router
