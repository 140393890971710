<template>
    <div class="sidebar-right p-2 d-none d-md-block"
         id="sidebar-right">
        <div class="card w-100">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-9">
                        <p class="card-text m-0">{{ $t("common.name") }}</p>
                        <p class="card-text"><strong>{{ currentUser.fullName }}</strong></p>
                    </div>
                    <div class="col-3">
                        <b-button variant="dark" @click="showModal = false"><font-awesome-icon fixed-width :icon="['fas', 'times']" /></b-button>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-10">
                        <p class="card-text m-0">Rol</p>
                        <p class="card-text"><strong>{{ currentUser.UserGroup.Name }}</strong>
                        </p>
                        <b-button @click="$emit('showAccountModal')" variant="primary"><font-awesome-icon :icon="['fas', 'pencil']" /> <span class="text-uppercase ml-1">{{ $t("common.change") }}</span></b-button>
                    </div>
                </div>

                <hr>

                <div class="row mb-2">
                    <div class="col-12">
                        <p class="card-text">{{ $t("sidebar.available_tools") }}</p>
                        <ul class="list-unstyled components">

                            <li v-for="(item, index) in sideMenu" :key="index">
                                <a v-if="item.external" :href="item.to" target="_blank"><h6 v-if="item.plural">{{ $tc(item.translation, 2) }}</h6><h6 v-else>{{ $t(item.translation) }}</h6></a>
                                <router-link v-else :to="{ name: item.to }">
                                    <h6 v-if="item.plural">{{ $tc(item.translation, 2) }}</h6><h6 v-else>{{ $t(item.translation) }}</h6>
                                </router-link>
                            </li>
                          <li v-if="currentUser.UserGroup.UserGroupID === 'ADMINISTRATOR'"><a href="#" @click="showImpersonationModal = !showImpersonationModal"><h6>Account overnemen</h6></a></li>
                        </ul>
                    </div>
                </div>

                <hr>

                <b-btn @click="logout()" class="btn btn-danger text-uppercase">{{ $t("sidebar.options.logout") }}</b-btn>
            </div>
        </div>
      <impersonation :showImpersonationModal="showImpersonationModal" @reload="reloadFull()"></impersonation>
    </div>
</template>

<script>

import MenuArray from '../../../components/MenuItems'
import Impersonation from './Actions/Impersonation'

export default {
  props: ['showAccountMenu'],
  components: {
    Impersonation
  },
  data () {
    return {
      showImpersonationModal: false
    }
  },
  methods: {
    logout () {
      console.log('logging out')
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'logout'
        }
      }).then(res => {
        document.location.href = '/logout'
      })
    },
    startImpersonation (DatatalbeID, ID) {
      console.log('switching to an other account')
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'impersonation',
          DataTableID: DatatalbeID,
          ID: ID
        }
      })
    },
    reloadFull () {
      document.location.href = '/'
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showAccountMenu
      },
      set () {
        this.$emit('close')
      }
    },
    sideMenu () {
      return MenuArray[1][this.currentUser.UserGroup.UserGroupID]
    }
  }
}
</script>
