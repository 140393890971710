<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <b-modal
                id="add-company-modal"
                ref="modal"
                size="lg"
                :title=modalData.StandardText.Title
                v-model="showModal"
                @show="initModal"
                @hidden="resetModal"
                @ok="handleSubmit"
                @close="emitClose"
                @cancel="emitClose"
                :ok-title="$t('common.save')"
                :cancel-title="$t('common.cancel')"
                :ok-disabled="processing || loading || this.freeLicenseCalc < 0"
        >
            <div v-html="modalData.StandardText.Content"></div>
          <b-form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group id="input-group-2" :label="$t('person.choose')" label-for="input-2">
                    <b-form-tags
                            v-model="$v.form.members.$model"
                            no-outer-focus
                            class="mb-2"
                            :state="validateState('members')"
                            aria-describedby="input-2-live-feedback"
                    >
                        <template v-slot="{ tags, disabled, addTag, removeTag }">
                            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                <li v-for="(tag, index) in tags" :key="index" class="list-inline-item">
                                    <b-form-tag
                                            @remove="removeTag(tag)"
                                            :disabled="disabled"
                                            variant="secondary"
                                    >{{ parseTag(tag).Name }} {{ parseTag(tag).Name2 }}</b-form-tag>
                                </li>
                            </ul>

                            <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100" ref="dropdown">
                                <template v-slot:button-content>
                                    <font-awesome-icon :icon="['fas', 'user-graduate']" /> {{ $t("person.choose") }}
                                </template>
                                <b-dropdown-form @submit.stop.prevent="() => {}">
                                    <b-form-group
                                            label-for="tag-search-input"
                                            :label="$t('person.search')"
                                            label-cols-md="auto"
                                            class="mb-0"
                                            label-size="sm"
                                            :description="searchDesc"
                                    >
                                        <b-form-input
                                                v-model="search"
                                                id="tag-search-input"
                                                type="search"
                                                size="sm"
                                                autocomplete="off"
                                                debounce="500"
                                                class="w-75 d-inline-block"
                                        ></b-form-input>
                                      <b-button @click="$refs.dropdown.hide(true)" variant="outline-secondary" class="d-inline-block float-right"><i class="fas fa-check"></i></b-button>
                                    </b-form-group>
                                </b-dropdown-form>
                                <b-dropdown-divider></b-dropdown-divider>

                                <p class="px-3 mb-0 text-info">
                                  <small>{{ $t("modal.common.max_result_helper") }}</small>
                                </p>

                                <b-list-group class="p-3 overflow-auto form-tags--data-list">
                                    <b-list-group-item
                                            v-for="(option, index) in availableOptions"
                                            :key="index" :disabled="option.HasSubscriptionYN == 1"
                                            @click="onOptionClick({ option, addTag })" href="#"
                                    >
                                        {{ option.Name }} {{ option.Name2 }} <span v-if="option.Education" class="text-info">- {{ option.Education }}</span> <span v-if="option.HasSubscriptionYN == 1">| <small>{{ $tc("menu.registration", 1) }} <font-awesome-icon :icon="['fas', 'link']" /> {{ option.Subscription.SubscriptionID }}</small></span>
                                    </b-list-group-item>
                                </b-list-group>
                                <b-dropdown-text v-if="availableOptions.length === 0">
                                    {{ $t("person.no_result") }}
                                </b-dropdown-text>
                            </b-dropdown>
                        </template>
                    </b-form-tags>
                    <b-form-invalid-feedback
                            id="input-2-live-feedback"
                    >{{ $t("subscribe.minimal_person_amount") }}.</b-form-invalid-feedback>
                </b-form-group>
              <p class="text-center" :class="{ 'text-danger': this.freeLicenseCalc < 0 }">
                {{ $tc('registration.free_licence', 2) }}: {{ this.freeLicenseCalc }}
              </p>
              <p v-if="this.freeLicenseCalc < 0" class="text-center text-danger">{{
                  $t('person.notice_not_enough_licenses')
                }}</p>

            </b-form>

          <hr>
          {{ $t('person.take_notice_01') }}
          <br><br>
          {{ $t('person.take_notice_02') }}
          <b-button @click="closeAndNavigate('Person')" variant="dark" class="mt-3"><font-awesome-icon :icon="['fas', 'level-up']" /> <span class="text-uppercase">{{ $t("person.add") }}</span></b-button>
          <hr>

            <b-overlay
                    :show="loading"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    no-wrap
            >
            </b-overlay>

            <b-overlay :show="processing" no-wrap>
                <template v-slot:overlay v-if="apiError">
                    <div
                            ref="dialog"
                            tabindex="-1"
                            role="dialog"
                            aria-modal="false"
                            aria-labelledby="form-confirm-label"
                            class="p-3"
                    >

                        <font-awesome-icon :icon="['fas', 'exclamation-circle']" size="3x" class="text-danger mb-3" />

                        <p style="width: 500px; max-width: 80vw"><strong id="form-confirm-label text-danger">Error: {{ errorMsg }}</strong></p>
                        <div class="d-flex">
                            <b-button variant="outline-danger" @click="cancelProcessing">{{ $t("common.back") }}</b-button>
                        </div>
                    </div>
                </template>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  props: ['showSubscribePersonsModal', 'currentProjectID', 'freeLicenseCount'],
  data () {
    return {
      error: false,
      loading: true,
      processing: false,
      apiError: false,
      errorMsg: null,
      options: [],
      search: '',
      form: {
        members: []
      },
      modalData: {
        StandardText: {
          Title: '',
          Content: ''
        }
      }
    }
  },
  validations: {
    form: {
      members: {
        required
      }
    }
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    initModal () {
      this.resetModal()
      this.loadData()
    },
    resetModal () {
      this.form = {
        name: null,
        members: []
      }

      // reset vuelidate state
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    emitClose () {
      this.$emit('close')
    },
    handleSubmit (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.processing = true
      this.cleanData()
      this.submitData().then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          this.$emit('reload')
          this.$eventHub.$emit('reloadData')
          // Hide the modal manually
          this.$nextTick(() => {
            this.emitClose()
          })
          this.makeToast('success', this.$t('common.success'), this.$t('toasts.students_linked'))
        }
      })
    },
    onShown () {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden () {
      // In this case, we return focus to the submit button
      this.$refs.submit.focus()
    },
    getData (params) {
      return this.$http.get('/php/xhr/ajax.php', {
        params
      })
    },
    loadData () {
      this.getData({
        function: 'getModal',
        modal: 'subscribePersons'
      }).then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          const construct = response.data
          this.modalData.StandardText = construct.StandardText
          this.modalData.CustomerRecords = construct.CustomerRecords

          this.loadPersons()
        }
      })
    },
    loadPersons () {
      this.getData({
        function: 'getPersonsByProject',
        filter: this.criteria,
        ProjectID: this.currentProjectID,
        type: 'no-license'
      }).then(response => {
        const dataObj = response.data
        this.options = dataObj.PersonShortList.items
        this.loading = false
      })
    },
    submitData () {
      const formData = new FormData()
      this.form.members.forEach(person => formData.append('PersonRecords[]', person.PersonID))

      formData.append('ProjectID', this.currentProjectID)

      return this.$http.post('/php/xhr/ajax.php?function=subscribePersons', formData)
    },
    onOptionClick ({ option, addTag }) {
      addTag(JSON.stringify(option))
      this.search = ''
    },
    parseTag (tag) {
      return JSON.parse(tag)
    },
    cleanData () {
      // we stringified the data for use in the form tags widget. So now we parse it all back to objects before sending to the api.
      const cleanMemberArr = []
      this.form.members.forEach(sObj => cleanMemberArr.push(this.parseTag(sObj)))
      this.form.members = cleanMemberArr
    },
    cancelProcessing () {
      this.processing = false
      this.apiError = false
      this.errorMsg = null
    },
    closeAndNavigate (to) {
      this.$store.dispatch('modal/hide')
      this.$nextTick(() => {
        this.$root._router.push({
          name: to
        })
      })
    }
  },
  computed: {
    showModal: {
      get () {
        return this.showSubscribePersonsModal
      },
      set () {
        this.$emit('close')
      }
    },
    freeLicenseCalc () {
      return this.freeLicenseCount - this.form.members.length
    },
    criteria () {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions () {
      const options = this.options.filter(opt => this.form.members.indexOf(JSON.stringify(opt)) === -1)
      // Show all options available
      return options
    },
    searchDesc () {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'Er zijn geen studenten die aan uw zoekopdracht voldoen.'
      }
      return ''
    }
  },
  watch: {
    search: 'loadPersons'
  }
}
</script>
