<template>
  <div id="app">
    <div class="w-100 bg-primary text-center text-white py-2 position-relative bring-to-top" v-if="this.currentUser.isSwitchedUser">U doet zich op het
      moment voor als <u>{{ currentUser.fullName }}</u>.
      <b-button class="ml-4" variant="dark" @click="stopImpersonation()">Stoppen</b-button>
    </div>
    <div class="wrapper d-flex align-items-stretch overflow-hidden position-relative"
         v-if="!loading && !this.currentUser.FirstTimeLogin">

      <div class="row no-gutters w-100">
        <div class="custom-menu">
          <button type="button" id="sidebarCollapse" class="btn text-white"
                  :class="{'btn-tertiary':!showSideMenu, 'btn-primary':showSideMenu}"
                  @click="showSideMenu = !showSideMenu">
            <font-awesome-icon fixed-width :icon="['fas', 'bars']"/>
            <span class="sr-only">Toggle Menu</span>
          </button>
        </div>
        <nav id="sidebar" class="shadow col" :class="{ 'menu-folded':showSideMenu }">
          <div class="p-4 sidebar--wrapper">

            <div>
              <b-nav vertical>

                <b-nav-item v-for="(item, index) in mainMenu()" :key="index" :to="{ name: item.to }"
                            :target="item.external ? '_blank' : '_self'" exact-active-class="active">
                  <font-awesome-icon fixed-width :icon="['fas', item.icon]" class="mr-1"/>
                  <span v-if="item.plural">{{ $tc(item.translation, 2) }}</span><span v-else>{{
                    $t(item.translation)
                  }}</span>
                </b-nav-item>

                <!--                            <b-nav-item to="/" exact-active-class="active">-->
                <!--                                <font-awesome-icon fixed-width :icon="['fas', 'th-large']" class="mr-1"/>-->
                <!--                                {{ $t("menu.dashboard") }}-->
                <!--                            </b-nav-item>-->

                <!--                            <b-nav-item :to="{ name: 'ProjectOverview' }" active-class="active">-->
                <!--                                <font-awesome-icon fixed-width :icon="['fas', 'pencil']" class="mr-1"/>-->
                <!--                                {{ $tc("menu.registration", 2) }}-->
                <!--                            </b-nav-item>-->

                <!--                            <b-nav-item :to="{ name: 'EmployeeOverview' }" active-class="active">-->
                <!--                                <font-awesome-icon fixed-width :icon="['fas', 'chalkboard-teacher']" class="mr-1"/>-->
                <!--                                {{ $tc("menu.supervisor", 2) }}-->
                <!--                            </b-nav-item>-->

                <!--                            <b-nav-item :to="{ name: 'PersonOverview' }" active-class="active">-->
                <!--                                <font-awesome-icon fixed-width :icon="['fas', 'user-graduate']" class="mr-1"/>-->
                <!--                                {{ $tc("menu.student", 2) }}-->
                <!--                            </b-nav-item>-->

                <!--                            <b-nav-item :to="{ name: 'ActivityGroupOverview' }" active-class="active">-->
                <!--                                <font-awesome-icon fixed-width :icon="['fas', 'user-friends']" class="mr-1"/>-->
                <!--                                {{ $tc("menu.company", 2) }}-->
                <!--                            </b-nav-item>-->

                <!--                            <b-nav-item href="https://mijn.jongondernemen.nl/nl/co%C3%B6rdinator/programma"-->
                <!--                                        target="_blank">-->
                <!--                                <font-awesome-icon fixed-width :icon="['fas', 'book-reader']" class="mr-1"/>-->
                <!--                                {{ $t("menu.program") }}-->
                <!--                            </b-nav-item>-->

                <!--                            <b-nav-item href="https://mijn.jongondernemen.nl/nl/co%C3%B6rdinator/extra-theorie"-->
                <!--                                        target="_blank">-->
                <!--                                <font-awesome-icon fixed-width :icon="['fas', 'book']" class="mr-1"/>-->
                <!--                                {{ $t("menu.my-company") }}-->
                <!--                            </b-nav-item>-->

                <!--                            <b-nav-item to="/faq" active-class="active">-->
                <!--                                <font-awesome-icon fixed-width :icon="['fas', 'question-circle']" class="mr-1"/>-->
                <!--                                {{ $t("menu.faq") }}-->
                <!--                            </b-nav-item>-->

              </b-nav>
            </div>

            <div class="row d-md-none p-4 mt-2 card card-body bg-tertiary text-white">
              <div class="col-12">
                <div :style="{ backgroundImage: 'url(' + currentUser.Image + ')' }"
                     class="profile-img mb-1"></div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <span class="user-name d-block mb-1"><strong>{{ currentUser.fullName }}</strong></span>
                    <span class="user-name d-block mb-1">{{ currentUser.UserGroup.Name }}</span>
                  </div>
                  <div class="col-7">
                    <div class="dropdown language-selector text-dark">
                      <b-dropdown id="language-dropdown">
                        <template v-slot:button-content>
                          <span class="flag-icon" :class="currentLocale.icon"></span>
                        </template>
                        <b-dropdown-item v-for="(option, index) in localeOptions" :key="index"
                                         @click="setLocale(option)">
                          <span class="flag-icon" :class="option.icon"></span>
                          <span> {{ option.name }}</span>
                        </b-dropdown-item>
                      </b-dropdown>

                    </div>
                  </div>
                  <div class="col-5">
                    <b-button variant="primary" @click="showAccountModal = true" size="sm">
                      <font-awesome-icon fixed-width :icon="['fas', 'pencil']"/>
                    </b-button>
                  </div>
                </div>

                <!--                            <b-dropdown id="roleDropdown" text="Begeleidend docent">-->

                <!--                                <b-dropdown-group id="roleGroup" header="Kies uw rol">-->
                <!--                                    <b-dropdown-item>Docent</b-dropdown-item>-->
                <!--                                </b-dropdown-group>-->
                <!--                            </b-dropdown>-->
              </div>
            </div>

          </div>
        </nav>
        <div class="content-wrapper col">
          <div id="utility-bar" class="utility-bar w-100 bg-tertiary pl-md-5"
               :class="{ 'menu-folded':showSideMenu }">
            <div class="container-fluid">
              <div class="row">
                <div class="offset-2 offset-md-0 col-10 col-xl-3 py-1">
                  <a href="index.html" class="logo"><img src="/assets/dev-assets/logo-ow.png"
                                                         class="utility-bar--logo" alt=""></a>
                </div>
                <div class="bg-dark position-absolute utility--user-block text-white d-none d-md-block"
                     id="utility--user-block">

                  <div class="row no-gutters">
                    <div class="col-9">
                      <span class="user-name"><strong>{{ currentUser.fullName }}</strong></span>
                      <div class="dropdown language-selector d-inline-block">
                        <b-dropdown id="language-dropdown">
                          <template v-slot:button-content>
                            <span class="flag-icon" :class="currentLocale.icon"></span>
                          </template>
                          <b-dropdown-item v-for="(option, index) in localeOptions" :key="index"
                                           @click="setLocale(option)">
                            <span class="flag-icon" :class="option.icon"></span>
                            <span> {{ option.name }}</span>
                          </b-dropdown-item>
                        </b-dropdown>

                      </div>

                      <span class="ml-2 user-role">{{ currentUser.UserGroup.Name }}</span>

                      <!--                                        <b-dropdown id="roleDropdown" text="Begeleidend docent" class="m-md-2">-->

                      <!--                                            <b-dropdown-group id="roleGroup" header="Kies uw rol">-->
                      <!--                                                <b-dropdown-item>Docent</b-dropdown-item>-->
                      <!--                                            </b-dropdown-group>-->
                      <!--                                        </b-dropdown>-->
                    </div>
                    <div :style="{ backgroundImage: 'url(' + currentUser.Image + ')' }" class="profile-img"
                         @click="showAccountMenu = !showAccountMenu">
                      <div id="profile-img__open" class="profile-img__overlay"
                           :class="{ active: !showAccountMenu }">
                        <font-awesome-icon :icon="['fas', 'search']"/>
                      </div>
                      <div id="profile-img__close" class="profile-img__overlay"
                           :class="{ active: showAccountMenu }">
                        <font-awesome-icon :icon="['fas', 'times']"/>
                      </div>
                    </div>
                  </div>

                </div>

                <transition name="slide-fade" mode="out-in">
                  <account-side-menu v-if="showAccountMenu"
                                     v-on:showAccountModal="showAccountModal = true"
                                     v-on:close="showAccountMenu = false"></account-side-menu>
                </transition>

              </div>
            </div>

          </div>

          <!-- Page Content  -->
          <div id="content" :class="{ 'menu-folded':showSideMenu }">

            <router-view/>
          </div>
        </div>
      </div>

    </div>
    <account-details-modal :showAccountModal="showAccountModal" v-on:close="showAccountModal = false"
                           v-on:update="loadUser"/>
    <socket-details :showSocketDetailsModal="showSocketDetailsModal" :errorCollection="errorCollection"
                    v-on:close="showSocketDetailsModal = false"/>
    <large-side-modal/>
    <first-time-login v-if="!loading && this.currentUser.FirstTimeLogin" v-on:update="loadUser"/>
  </div>
</template>

<style lang="scss">

</style>
<script>
import AccountDetailsModal from './components/modals/Account/AccountDetails'
import AccountSideMenu from './components/modals/Account/AccountSideMenu'
import LargeSideModal from './components/LargeSideModal'
import FirstTimeLogin from './components/modals/FirstTimeLogin/FirstTimeLogin'
import SocketDetails from './components/modals/Socket/SocketDetails'
import MenuArray from './components/MenuItems'
import SocketMixin from './components/mixins/SocketMixin'

import { mapGetters } from 'vuex'

export default {
  mixins: [
    SocketMixin
  ],
  data () {
    return {
      showAccountModal: false,
      loading: true,
      showSideMenu: false,
      showAccountMenu: false
    }
  },
  methods: {
    loadUser () {
      this.loading = true
      this.getData().then(response => {
        if (response.data.status !== 'success') {
          this.error = true
        } else {
          this.$store.dispatch('user/setUser', response.data)
          this.$store.dispatch('language/setLocale', response.data.Locale)
          this.$i18n.locale = response.data.Locale
          this.loading = false
        }
      })
    },
    stopImpersonation () {
      console.log('switching back to original user')
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'impersonation'
        }
      }).then(response => {
        if (response.data.status !== 'success') {
          // Inform the user of the error and do not close the modal.
          this.errorMsg = response.data.message
          this.apiError = true
        } else {
          this.processing = false
          document.location.href = '/'
        }
      })
    },
    hideAccountMenu () {
      this.showAccountMenu = false
    },
    getData () {
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'getCurrentUser'
        }
      })
    },
    logout () {
      console.log('logging out')
      return this.$http.get('/php/xhr/ajax.php', {
        params: {
          function: 'logout'
        }
      })
    },
    setLocale (locale) {
      this.$store.dispatch('language/setLocale', locale.value)
      this.$i18n.locale = locale.value
      this.saveLocale(locale.ID).then(response => {
        if (response.data.status !== 'success') {
          this.makeToast('danger', 'Error', this.$t('toasts.language_preference_error'))
        } else {
          this.$eventHub.$emit('reloadFull')
        }
      })
    },
    saveLocale (languageID) {
      const formData = new FormData()
      formData.append('LanguageID', languageID)
      return this.$http.post('/php/xhr/ajax.php?function=setLanguageID', formData)
    },
    mainMenu () {
      return MenuArray[0][this.currentUser.UserGroup.UserGroupID]
    }
  },
  computed: {
    ...mapGetters({
      localeOptions: 'language/localeOptions',
      currentLocale: 'language/currentLocale'
    })
  },
  watch: {
    $route: 'hideAccountMenu'
  },
  components: { SocketDetails, LargeSideModal, AccountDetailsModal, FirstTimeLogin, AccountSideMenu },
  beforeMount () {
    this.loadUser()
  },
  created () {
    this.$nextTick(() => {
      this.$eventHub.$on('createSocket', args => {
        this.newSocket(...args)
      })
    })
  },
  beforeDestroy () {
    this.$eventHub.$off('createSocket')
  }
}
</script>
