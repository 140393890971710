import Vue from 'vue'
import Vuex from 'vuex'
import modal from './modules/modal'
import firstTimeLogin from './modules/firstTimeLogin'
import user from './modules/user'
import language from './modules/language'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    modal,
    firstTimeLogin,
    user,
    language
  }
})
